import { BusinessCategory } from "../types";
import BaseApi from "./BaseApi";

class BusinessCategoryApi extends BaseApi {
  list(): Promise<BusinessCategory[]> {
    return this.http.get("/signup/api/v1/business_categories/");
  }
}

export default BusinessCategoryApi;
