import CardMain from "@/shared/components/CardMain";
import Field from "@/shared/components/form/Field";
import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import Input from "@/shared/components/form/Input";
import Radio from "@/shared/components/form/Radio";
import RadioGroup from "@/shared/components/form/RadioGroup";
import Header from "@/shared/components/Header";
import Segment from "@/shared/services/Segment";
import { makeTestID } from "@/shared/utils/development";
import Layout from "@/signup/components/Layout";
import StepProgressBar from "@/signup/components/StepProgressBar";
import SubmitButton from "@/signup/components/SubmitButton";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import Validator from "@/signup/services/Validator";
import { useSignupStore, useStepStore } from "@/signup/store";
import { SignupStep } from "@yoco/sawubona-sdk";
import { FormikValues } from "formik";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";

const useStore = () => ({
  setActiveStep: useStepStore(state => state.setActiveStep),
  signup: useSignupStore(state => state.signup),
  updateSignup: useSignupStore(state => state.updateSignup),
  getNextStepLink: useStepStore(state => state.getNextStepLink),
});

const BusinessNamePage: React.FC<any> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { setActiveStep, signup, updateSignup, getNextStepLink } = useStore();

  useEffect(() => {
    setActiveStep(SignupStep.BusinessNamePage);
  }, [setActiveStep]);

  useEffect(() => {
    Segment.track("web_signup_business_name_start");
  }, [location]);

  const initialValues = {
    stage: SignupStep.BusinessNamePage,
    business_name: signup?.business_name || "",
    have_previously_accepted_card_payments:
      signup?.have_previously_accepted_card_payments,
  };

  yup.addMethod(
    yup.string,
    "validBusinessName",
    Validator.validateBusinessName
  );

  const schema = yup.object({
    business_name: (yup as any)
      .string()
      .required()
      .label("business name")
      .validBusinessName(),
    have_previously_accepted_card_payments: yup
      .mixed()
      .required("You must select an option."),
  });

  const onSubmit = async (values: FormikValues) => {
    const isSignupUpdated = await updateSignup(values);

    if (isSignupUpdated) {
      Segment.trackWithSignup("web_signup_business_name_next_button_clicked");

      navigate(getNextStepLink());
    }
  };

  const handleChangeHavePreviouslyAcceptedCardPayments = (
    e: React.ChangeEvent<HTMLInputElement>,
    formik: any
  ) => {
    const { name, value } = e.target;

    // Formik doesn't currently support parsing or casting a value - see https://github.com/formium/formik/pull/2255
    formik.setFieldValue(name, String(value) === "true");
  };

  return (
    <Layout>
      <CardMain>
        <StepProgressBar />
        <Header
          title="What's your business called?"
          description={
            <>
              <p className="py-2 text-base">
                Not sure yet? That's okay, you can update this anytime in your
                Yoco profile.
              </p>
              <p>
                It can be your name, your trading name, or whatever your
                customers best know you by.
              </p>
            </>
          }
        />
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {(formik: any) => {
            return (
              <Form>
                <div className="mb-8">
                  <Field
                    name="business_name"
                    placeholder="Business trading name *"
                    component={Input}
                    data-testid={makeTestID("businessName", "businessName")}
                  />
                </div>

                <div className="text-center">
                  <p className="text-center">
                    This will be displayed on your <b>(free)</b> Yoco receipts
                    when you transact.
                  </p>
                  <br />
                </div>

                <RadioGroup name="have_previously_accepted_card_payments">
                  <p className="mb-2 text-center">
                    Have you accepted card payments before? *
                  </p>
                  <div className="flex flex-row justify-center pb-6">
                    <Field
                      name="have_previously_accepted_card_payments"
                      type="radio"
                      // eslint-disable-next-line react/jsx-boolean-value
                      value={true}
                      label="Yes"
                      component={Radio}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleChangeHavePreviouslyAcceptedCardPayments(
                          e,
                          formik
                        );
                      }}
                      data-testid={makeTestID(
                        "homePage",
                        "acceptedPayments",
                        "yes"
                      )}
                    />

                    <Field
                      name="have_previously_accepted_card_payments"
                      type="radio"
                      value={false}
                      label="No"
                      component={Radio}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeHavePreviouslyAcceptedCardPayments(
                          e,
                          formik
                        )
                      }
                      data-testid={makeTestID(
                        "homePage",
                        "acceptedPayments",
                        "no"
                      )}
                    />
                  </div>
                </RadioGroup>

                <SubmitButton testID={makeTestID("businessName", "next")}>
                  Next
                </SubmitButton>
              </Form>
            );
          }}
        </Formik>
      </CardMain>
    </Layout>
  );
};

export default withFetchSignup(BusinessNamePage);
