import Text from "@/shared/components/Text";
import React from "react";

const TermsAndConditionsLink: React.FC<any> = () => {
  return (
    <Text className="text-center text-base text-black">
      By continuing, I agree to Yoco’s{" "}
      <a
        href="https://www.yoco.com/za/terms/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms
      </a>
    </Text>
  );
};

export default TermsAndConditionsLink;
