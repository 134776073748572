import Button from "@/shared/components/Button";
import CardMain from "@/shared/components/CardMain";
import Field from "@/shared/components/form/Field";
import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import Radio from "@/shared/components/form/Radio";
import RadioGroup from "@/shared/components/form/RadioGroup";
import Select from "@/shared/components/form/Select";
import BaseSubmitButton from "@/shared/components/form/SubmitButton";
import Header from "@/shared/components/Header";
import Segment from "@/shared/services/Segment";
import { makeTestID } from "@/shared/utils/development";
import Layout from "@/signup/components/Layout";
import StepProgressBar from "@/signup/components/StepProgressBar";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import { useSignupStore, useStepStore } from "@/signup/store";
import { UserJourney } from "@/signup/types";
import { SignupStep } from "@yoco/sawubona-sdk";
import { FormikValues } from "formik";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useStore = () => ({
  setActiveStep: useStepStore(state => state.setActiveStep),
  signup: useSignupStore(state => state.signup),
  updateSignup: useSignupStore(state => state.updateSignup),
  getNextStepLink: useStepStore(state => state.getNextStepLink),
});

const BusinessPreQualificationPage: React.FC<any> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { setActiveStep, signup, updateSignup, getNextStepLink } = useStore();

  useEffect(() => {
    setActiveStep(SignupStep.BusinessPreQualificationPage);
  }, [setActiveStep]);

  useEffect(() => {
    Segment.track("web_signup_business_pre_qualification_start");
  }, [location]);

  const initialValues = {
    stage: SignupStep.BusinessPreQualificationPage,
    card_machine_use: signup?.card_machine_use || "",
    approximate_volume_of_card_payments:
      signup?.approximate_volume_of_card_payments ||
      cardPaymentVolumeChoices[0].value,
    trade_habit: signup?.trade_habit || "",
    trading_for: signup?.trading_for || "",
    trading_frequency:
      signup?.trading_frequency || tradingFrequencyChoices[0].value,
  };

  const onSubmit = async (values: FormikValues) => {
    const isSignupUpdated = await updateSignup(values);

    if (isSignupUpdated) {
      Segment.trackWithSignup(
        "web_signup_business_pre_qualification_next_button_clicked"
      );

      navigate(getNextStepLink());
    }
  };

  const onSkip = async () => {
    Segment.track("web_signup_business_pre_qualification_skip_button_clicked");
    navigate(getNextStepLink());
  };

  const handleDoesCurrentlyAcceptCardPaymentsSelected = (
    e: React.ChangeEvent<HTMLInputElement>,
    formik: any
  ) => {
    const { name, value } = e.target;

    // Formik doesn't currently support parsing or casting a value - see https://github.com/formium/formik/pull/2255
    formik.setFieldValue(name, String(value) === "true");
  };

  return (
    <Layout>
      <CardMain>
        <StepProgressBar />
        <Header
          title="We'd like to know a bit more about you"
          description={
            <p className="py-2 text-base">
              These questions help us better serve you, but they are not
              compulsory.
            </p>
          }
        />
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {(formik: any) => {
            return (
              <Form>
                <RadioGroup name="card_machine_use">
                  <p className="mb-2 font-bold">
                    How do you plan on using Yoco?
                  </p>
                  <div className="pb-6">
                    <Field
                      name="card_machine_use"
                      type="radio"
                      value="switching_provider"
                      label="I am switching from an existing provider to Yoco"
                      component={Radio}
                    />

                    <Field
                      name="card_machine_use"
                      type="radio"
                      value="backup_or_events"
                      label="I plan on using Yoco as a backup or for events"
                      component={Radio}
                    />

                    <Field
                      name="card_machine_use"
                      type="radio"
                      value="new_to_card_payments"
                      label="I am new to card payments"
                      component={Radio}
                    />
                  </div>
                </RadioGroup>
                <div className="pb-6">
                  <p className="mb-2 font-bold">
                    What is your current volume of card payments per month?
                  </p>
                  <Field
                    name="approximate_volume_of_card_payments"
                    component={Select}
                    options={cardPaymentVolumeChoices}
                  />
                </div>

                <RadioGroup name="trade_habit">
                  <p className="mb-2 font-bold">
                    How does your business trade?
                  </p>
                  <div className="pb-6">
                    <Field
                      name="trade_habit"
                      type="radio"
                      value="fixed_location"
                      label="I trade from a fixed, permanent location"
                      component={Radio}
                      data-testid={makeTestID(
                        "businessPreQualification",
                        "tradeHabit",
                        "fixed_location"
                      )}
                    />

                    <Field
                      name="trade_habit"
                      type="radio"
                      value="mobile_location"
                      label="My business is entirely mobile (e.g. a food truck or delivery)"
                      component={Radio}
                      data-testid={makeTestID(
                        "businessPreQualification",
                        "tradeHabit",
                        "mobile_location"
                      )}
                    />

                    <Field
                      name="trade_habit"
                      type="radio"
                      value="multiple_locations"
                      label="I have multiple trading locations (fixed, mobile, online)"
                      component={Radio}
                      data-testid={makeTestID(
                        "businessPreQualification",
                        "tradeHabit",
                        "multiple_locations"
                      )}
                    />

                    <Field
                      name="trade_habit"
                      type="radio"
                      value="online_only"
                      label="My business in only online"
                      component={Radio}
                      data-testid={makeTestID(
                        "businessPreQualification",
                        "tradeHabit",
                        "online_only"
                      )}
                    />
                  </div>
                </RadioGroup>

                <RadioGroup name="trading_for">
                  <p className="mb-2 font-bold">
                    How long has your business been trading?
                  </p>
                  <div className="pb-6">
                    <Field
                      name="trading_for"
                      type="radio"
                      value="this_year"
                      label="My business started this year"
                      component={Radio}
                      data-testid={makeTestID(
                        "businessPreQualification",
                        "tradeDuration",
                        "this_year"
                      )}
                    />

                    <Field
                      name="trading_for"
                      type="radio"
                      value="more_than_a_year"
                      label="My business has been trading for more than a year"
                      component={Radio}
                      data-testid={makeTestID(
                        "businessPreQualification",
                        "tradeDuration",
                        "more_than_a_year"
                      )}
                    />

                    <Field
                      name="trading_for"
                      type="radio"
                      value="soon"
                      label="I want to start trading soon"
                      component={Radio}
                      data-testid={makeTestID(
                        "businessPreQualification",
                        "tradeDuration",
                        "soon"
                      )}
                    />
                  </div>
                </RadioGroup>

                <div className="pb-6">
                  <p className="mb-2 font-bold">
                    How frequently does/will your business trade?
                  </p>
                  <Field
                    name="trading_frequency"
                    component={Select}
                    options={tradingFrequencyChoices}
                  />
                </div>

                <SubmitAndSkipButtonGroup onSkip={onSkip} />
              </Form>
            );
          }}
        </Formik>
      </CardMain>
    </Layout>
  );
};

type SubmitAndSkipButtonGroupProps = {
  onSkip?: () => void;
};
// This component re-implements the responsive logic of the <SubmitButton /> component, but incl
const SubmitAndSkipButtonGroup: React.FC<SubmitAndSkipButtonGroupProps> = ({
  onSkip,
}: SubmitAndSkipButtonGroupProps) => {
  const { signup } = useStore();

  const buttons = (
    <div className="flex flex-row space-x-4 items-stretch">
      <Button
        type="button"
        onClick={onSkip}
        testID={makeTestID("businessPreQualification", "skip")}
      >
        Skip
      </Button>
      <BaseSubmitButton testID={makeTestID("businessPreQualification", "next")}>
        Next
      </BaseSubmitButton>
    </div>
  );

  const isFixed = signup?.user_journey === UserJourney.BANANA;
  const submitButtonClasses = `md:hidden left-0 w-full p-4 bg-white z-10 ${
    isFixed ? "fixed bottom-0" : ""
  }`;

  return signup?.user_journey === UserJourney.CINNAMON ||
    signup?.user_journey === UserJourney.BANANA ? (
    <>
      <div className={submitButtonClasses}>{buttons}</div>
      <div className="pt-10 md:pt-0" />
      <div className="hidden md:block">{buttons}</div>
    </>
  ) : (
    buttons
  );
};

const cardPaymentVolumeChoices = [
  {
    value: "R0 - R10 000",
    label: "R0 - R10 000",
  },
  {
    value: "R10 000 - R100 000",
    label: "R10 000 - R100 000",
  },
  {
    value: "R100 000 - R200 000",
    label: "R100 000 - R200 000",
  },
  {
    value: "R200 000 - R500 000",
    label: "R200 000 - R500 000",
  },
  {
    value: "R500 000+",
    label: "R500 000+",
  },
];

const tradingFrequencyChoices = [
  {
    value: "daily",
    label: "Daily",
  },
  {
    value: "weekends",
    label: "Weekends",
  },
  {
    value: "monthly",
    label: "Monthly",
  },
  {
    value: "ad_hoc_trading",
    label: "Ad-hoc trading",
  },
];

export default withFetchSignup(BusinessPreQualificationPage);
