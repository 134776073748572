import { LabelValuePair } from "@/libs/sawubona-sdk/types";
import BaseApi from "./BaseApi";

class CityApi extends BaseApi {
  list(): Promise<LabelValuePair[]> {
    return this.http.get("/signup/api/v1/signups/cities/");
  }
}

export default CityApi;
