import Page from "@/shop/components/Page";
import {
  DeliveryPage,
  ExistingBusinessDeliveryPage,
  ExistingBusinessHomePage,
  HomePage,
  NotFoundPage,
  PaymentPage,
  StitchInstantEFTResultPage,
} from "@/shop/pages";
import CartPage from "@/shop/pages/CartPage";
import CompletePage from "@/shop/pages/CompletePage";
import { useAppConfigStore } from "@/shop/store";
import React from "react";
import { Route, Routes } from "react-router-dom";

const App: React.FC<any> = () => {
  window.yc = window.yc || {};
  window.yc.config = window.yc.config || {};
  // @ts-ignore
  window.yc.appConfigStore = useAppConfigStore;

  return (
    <Page>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route
          path="business/:businessUUID"
          element={<ExistingBusinessHomePage />}
        />
        <Route
          path="business/:businessUUID/:id"
          element={<ExistingBusinessDeliveryPage />}
        />
        <Route path=":id/cart" element={<CartPage />} />
        <Route path=":id/delivery" element={<DeliveryPage />} />
        <Route path=":id/payment" element={<PaymentPage />} />
        <Route path=":id/complete" element={<CompletePage />} />
        <Route
          path="stitch_instant_eft_result"
          element={<StitchInstantEFTResultPage />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Page>
  );
};

export default App;
