import { Config } from "@/config";
import { makeFeatureEnvConfig } from "@/config/environments/feature/utils";
import TARDIGRADE_CONFIG from "./tardigrade";
import ZEBRA_CONFIG from "./zebra";

// As a default, all feature environments use the same config as per the `DEFAULT_CONFIG` in `utils.ts`.
// `makeFeatureEnvConfig` will return an instance of a config with these default values and the name of the feature
// environment injected into certain known config values, such as a bespoke portal and API url. To have a more specific
// config set for a feature environment, you can create a stand-alone config and import it to a mapped feature env
// name below. eg: `tardigrade.ts`.

export const FEATURE_ENV_CONFIGS: { [key: string]: Config } = {
  camel: makeFeatureEnvConfig("camel"),
  coyote: makeFeatureEnvConfig("coyote"),
  dingo: makeFeatureEnvConfig("dingo"),
  fish: makeFeatureEnvConfig("fish"),
  hippo: makeFeatureEnvConfig("hippo"),
  manatee: makeFeatureEnvConfig("manatee"),
  narwhal: makeFeatureEnvConfig("narwhal"),
  otter: makeFeatureEnvConfig("otter"),
  panda: makeFeatureEnvConfig("panda"),
  pug: makeFeatureEnvConfig("pug"),
  raven: makeFeatureEnvConfig("raven"),
  tardigrade: TARDIGRADE_CONFIG,
  toucan: makeFeatureEnvConfig("toucan"),
  turtle: makeFeatureEnvConfig("turtle"),
  wolf: makeFeatureEnvConfig("wolf"),
  zebra: ZEBRA_CONFIG,
};
