import { LabelValuePair } from "@/libs/sawubona-sdk/types";
import BaseApi from "./BaseApi";

class AreaApi extends BaseApi {
  list(city: string): Promise<LabelValuePair[]> {
    return this.http.get("/signup/api/v1/signups/areas/", {
      timeout: 8000,
      params: {
        city,
      },
    });
  }
}

export default AreaApi;
