import React from "react";
import { PaymentMethod } from "@/shared/shop/api";
import EFTPaymentMethod from "@/shop/pages/PaymentPage/tabs/EFTPaymentMethod";
import InstantEftBlackbirdPaymentMethod from "@/shop/pages/PaymentPage/tabs/InstantEftBlackbirdPaymentMethod";
import InstantEFTPaymentMethod from "@/shop/pages/PaymentPage/tabs/InstantEFTPaymentMethod";
import CreditCardPaymentMethod from "@/shop/pages/PaymentPage/tabs/CreditCardPaymentMethod";

type Props = {
  name: PaymentMethod;
  onSuccess?: (paymentMethod: PaymentMethod, isPaid?: boolean) => void;
};

const DynamicPaymentMethod: React.FC<Props> = ({ name, onSuccess }: Props) => {
  switch (name) {
    case PaymentMethod.CREDIT_CARD_BLACKBIRD:
    case PaymentMethod.PAYMENT_TERMS:
    case PaymentMethod.CARD_ON_DELIVERY:
    case PaymentMethod.CREDIT_CARD_CHECKOUT:
      return <CreditCardPaymentMethod onSuccess={onSuccess} />;
    case PaymentMethod.EFT:
      return <EFTPaymentMethod onSuccess={onSuccess} />;
    case PaymentMethod.INSTANT_EFT_STITCH:
      return <InstantEFTPaymentMethod onSuccess={onSuccess} />;
    case PaymentMethod.INSTANT_EFT_BLACKBIRD:
      return <InstantEftBlackbirdPaymentMethod onSuccess={onSuccess} />;
    default:
      return null;
  }
};

export default DynamicPaymentMethod;
