export enum CartType {
  BUSINESS = "business",
  SIGNUP = "signup",
  STANDALONE = "standalone",
}

export enum CartSource {
  PORTAL = "portal",
  SIGNUP = "signup",
  APP = "app",
  SHOP = "shop",
}

export enum StoreFront {
  PORTAL = "portal",
  SIGNUP = "signup",
}

export enum PaymentMethod {
  CREDIT_CARD_CHECKOUT = "Credit Card (Checkout)",
  CREDIT_CARD_BLACKBIRD = "Credit Card (Blackbird)",
  EFT = "EFT",
  INSTANT_EFT_STITCH = "Instant EFT (Stitch)",
  INSTANT_EFT_BLACKBIRD = "Instant EFT (Blackbird)",
  PAYMENT_TERMS = "Payment terms",
  CARD_ON_DELIVERY = "Card on delivery",
}

export enum ReasonNotAvailable {
  OUT_OF_STOCK = "out_of_stock",
}

export interface Business {
  uuid: string;
  name: string;
  telephone_number: string;
  owner: BusinessUser;
}

export interface BusinessUser {
  email: string;
  first_name: string;
  last_name: string;
}

export interface Cart {
  id: string;
  base_currency: string;
  billing_country: string;
  shipping_method: string;
  shipping_first_name: string;
  shipping_last_name: string;
  shipping_email: string;
  shipping_mobile_number: string;
  shipping_address_line1: string;
  shipping_address_line2: string;
  shipping_suburb: string;
  shipping_city: string;
  shipping_province: string;
  shipping_postal_code: string;
  shipping_country: string;
  shipping_latitude: number;
  shipping_longitude: number;
  promo_code: PromoCode;
  gross_amount: number;
  gross_amount_currency: string;
  gross_amount_formatted: string;
  discount: number;
  discount_currency: string;
  discount_formatted: string;
  net_amount: number;
  net_amount_currency: string;
  net_amount_formatted: string;
  tax: number;
  tax_currency: string;
  tax_formatted: string;
  is_order: boolean;
  payment_method: PaymentMethod;
  eft_payment_reference: string;
  amount_paid: number;
  amount_paid_currency: string;
  amount_paid_formatted: string;
  balance_due: number;
  balance_due_currency: string;
  balance_due_formatted: string;
  is_paid: boolean;
  available_payment_methods: PaymentMethod[];
  default_payment_method: PaymentMethod;
  type: CartType;
  instalment_plan_proposal: InstalmentPlanProposal;
  items: CartItem[];
}

export interface CartItem {
  id: number;
  product: Product;
  quantity: number;
  add_to_cart_quantity_remaining: number;
  unit_price: number;
  unit_price_currency: string;
  unit_price_formatted: string;
  line_total: number;
  line_total_currency: string;
  line_total_formatted: string;
  discount: number;
  discount_currency: string;
  discount_formatted: string;
  tax: number;
  tax_currency: string;
  tax_formatted: string;
}

export interface InitiateBlackbirdPaymentResponse {
  id: string;
  blackbird_customer_id: string;
}

export interface Instalment {
  number: number;
  payment_date: Date;
  amount: string;
  amount_formatted: string;
}

export interface InstalmentPlanProposal {
  original_amount: string;
  original_amount_formatted: string;
  service_fee: string;
  service_fee_formatted: string;
  amount_with_service_fee: string;
  amount_with_service_fee_formatted: string;
  instalments: Instalment[];
  balance_due_after_first_instalment: string;
  balance_due_after_first_instalment_formatted: string;
  terms_in_months: number;
}

export interface ListProductFilters {
  is_listed_in_catalogue?: boolean;
  can_be_purchased?: boolean;
  is_card_machine?: boolean;
  storefront?: string;
}

export interface Product {
  id: number;
  name: string;
  short_name: string;
  sku: string;
  description: string;
  features: string;
  ribbon_text: string;
  ribbon_class: string;
  image: string;
  unit_price: number;
  unit_price_currency: string;
  unit_price_formatted: string;
  original_unit_price: number;
  original_unit_price_currency: string;
  original_unit_price_formatted: string;
  tax_policy: string;
  tax_rate: number;
  is_listed_in_catalogue: boolean;
  can_be_purchased: boolean;
  reason_not_available: ReasonNotAvailable;
  max_quantity_per_cart: number;
  is_card_machine: boolean;
  requires_shipping: boolean;
  is_discounted?: boolean;
  is_available_on_payment_terms: boolean;
}

export interface PromoCode {
  code: string;
}

export interface ProvinceOption {
  label: string;
  value: string;
}

export interface Waitlist {
  id: string;
  email: string;
  mobile_number: string;
  product: number;
}
