import Label from "@/shared/components/form/Label";
import React from "react";

type Props = {
  children: React.ReactNode;
  label?: string;
  [x: string]: any;
};

const Group: React.FC<Props> = ({ children, label, ...props }: Props) => {
  return (
    <div {...props}>
      {label && <Label>{label}</Label>}
      {children}
    </div>
  );
};

export default Group;
