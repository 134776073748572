import ErrorMessage from "@/shared/components/form/ErrorMessage";
import Label from "@/shared/components/form/Label";
import { executeScroll } from "@/shared/utils/helpers/general";
import { connect, FieldInputProps, FormikProps } from "formik";
import React, { useRef } from "react";
import MaskedInput from "react-text-mask";

type Props = {
  form: FormikProps<any>;
  field: FieldInputProps<any>;
  label?: string;
  type?: string;
  placeholder?: string;
  mask?: any;
  [x: string]: any;
};

const Input: React.FC<Props> = ({
  form: { touched, errors },
  field,
  label,
  type,
  placeholder,
  mask,
  ...props
}: Props) => {
  const inputRef = useRef<HTMLDivElement>(null);

  return (
    <>
      {label && <Label>{label}</Label>}
      <div style={{ position: "relative" }}>
        <div
          style={{ position: "absolute", top: "-30px", left: "0" }}
          ref={inputRef}
        />
        <span
          style={{
            position: "absolute",
            left: 17,
            top: 7,
            fontSize: "10px",
            fontWeight: 500,
            color: "#b9bcc2",
          }}
        >
          {field.value !== "" && placeholder}
        </span>
        {mask ? (
          <MaskedInput
            {...field}
            type={type || "text"}
            mask={mask}
            placeholder={placeholder}
            onChange={field.onChange}
            onBlur={field.onBlur}
            className={`block placeholder-gray-500 border rounded w-full mb-0 px-4 border-gray-400 text-gray-900  leading-tight focus:outline-none focus:shadow-outline focus-within:border-teal-500
          ${field.value !== "" && placeholder ? "pt-6 pb-2" : "py-4"}
          ${errors[field.name] && touched[field.name] && " border-red-500"}
          `}
            onFocus={() => executeScroll(inputRef.current as HTMLDivElement)}
            autoComplete="new-password"
            data-lpignore="true"
          />
        ) : (
          <input
            type={type || "text"}
            className={`block placeholder-gray-500 border rounded w-full mb-0 px-4 border-gray-400 text-gray-900  leading-tight focus:outline-none focus:shadow-outline focus-within:border-teal-500
          ${field.value !== "" && placeholder ? "pt-6 pb-2" : "py-4"}
          ${errors[field.name] && touched[field.name] && " border-red-500"}
          `}
            placeholder={placeholder}
            autoComplete="new-password"
            data-lpignore="true"
            onFocus={() => executeScroll(inputRef.current as HTMLDivElement)}
            {...field}
            {...props}
          />
        )}
        {errors[field.name] && touched[field.name] && (
          <ErrorMessage>{errors[field.name] as string}</ErrorMessage>
        )}
      </div>
    </>
  );
};

export default connect(Input);
