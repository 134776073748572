import { Feature } from "@yoco/feature-flags";
import { Experiment, Signup, ValidationReferralCodeResult } from "../types";
import BaseApi from "./BaseApi";

class SignupApi extends BaseApi {
  create(data: Record<string, unknown>): Promise<Signup> {
    return this.http.post("/signup/api/v1/signups/", data);
  }

  update(id: string, data: Record<string, unknown>): Promise<Signup> {
    return this.http.patch(`/signup/api/v1/signups/${id}/`, data);
  }

  get(id: string): Promise<Signup> {
    return this.http.get(`/signup/api/v1/signups/${id}/`);
  }

  addExperiments(id: string, experiments: Experiment[]): Promise<Signup> {
    return this.http.post(
      `/signup/api/v1/signups/${id}/experiments/`,
      experiments
    );
  }

  enableFeatureFlag(id: string, feature: Feature): Promise<Signup> {
    return this.http.post(`/signup/api/v1/signups/${id}/enable_feature_flag/`, {
      feature_flag: feature.name,
    });
  }

  disableFeatureFlag(id: string, feature: Feature): Promise<Signup> {
    return this.http.post(
      `/signup/api/v1/signups/${id}/disable_feature_flag/`,
      {
        feature_flag: feature.name,
      }
    );
  }

  finalise(id: string): Promise<Signup> {
    return this.http.post(`/signup/api/v1/signups/${id}/finalise/`);
  }

  getValidReferralCode(code: string): Promise<ValidationReferralCodeResult> {
    return this.http.get("/signup/api/v1/signups/validate_referral_code/", {
      timeout: 8000,
      params: {
        code,
      },
    });
  }

  async generateYocoAuthToken(id: string): Promise<string> {
    const data = (await this.http.get(
      `/signup/api/v1/signups/${id}/auth/`
    )) as Record<string, unknown>;
    return data.token as string;
  }
}

export default SignupApi;
