import withHandleNetworkRequestErrors from "@/shared/middleware/withHandleNetworkRequestErrors";
import { Form as BaseForm } from "formik";
import React from "react";

type Props = {
  children: React.ReactNode;
  [x: string]: any;
};

const Form: React.FC<Props> = ({ children, ...props }: Props) => {
  return <BaseForm {...props}>{children}</BaseForm>;
};

export default withHandleNetworkRequestErrors(Form);
