import Logo from "@/shared/components/Logo";
import { useAppConfigStore } from "@/shop/store";
import React from "react";

type Props = {
  children: React.ReactNode;
  layout?: string;
};

const useStore = () => ({
  isEmbed: useAppConfigStore(state => state.isEmbed),
});

const Layout: React.FC<Props> = ({ layout, children }: Props) => {
  const { isEmbed } = useStore();

  if (layout === "full") {
    return <div className="h-screen h-auto">{children}</div>;
  }

  return (
    <>
      {!isEmbed && (
        <div className="nav bg-white h-16 hidden md:flex items-center justify-center">
          <Logo />
        </div>
      )}
      <div className="flex sm:flex-row flex-col justify-center sm:space-x-4 md:py-12">
        {children}
      </div>
    </>
  );
};

export default Layout;
