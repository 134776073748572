import ErrorMessage from "@/shared/components/form/ErrorMessage";
import Label from "@/shared/components/form/Label";
import { connect, useField } from "formik";
import React from "react";

type Props = {
  name: string;
  label?: string;
  children: React.ReactNode;
  [x: string]: any;
};

const RadioGroupFlexWrap: React.FC<Props> = ({
  name,
  label,
  children,
  ...props
}: Props) => {
  // noinspection JSUnusedLocalSymbols
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(name);
  return (
    <div className="block m-1 ml-0" {...props}>
      {label && <Label>{label}</Label>}
      <div className="flex flex-wrap p-0">{children}</div>
      {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
    </div>
  );
};

export default connect(RadioGroupFlexWrap);
