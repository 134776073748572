import React from "react";

type Props = {
  children: React.ReactNode;
};

const ErrorMessage: React.FC<Props> = ({ children }: Props) => {
  return (
    <p className="block text-yoco-red p-0 m-0 ml-0 py-1 mx-1 text-xs">
      {children}
    </p>
  );
};

export default ErrorMessage;
