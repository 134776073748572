import ErrorMessage from "@/shared/components/form/ErrorMessage";
import Label from "@/shared/components/form/Label";
import Text from "@/shared/components/Text";
import { connect, useField } from "formik";
import React from "react";

type Props = {
  name: string;
  label?: string;
  subLabel?: string;
  children: React.ReactNode;
  [x: string]: any;
};

const RadioGroup: React.FC<Props> = ({
  name,
  label,
  subLabel,
  children,
  ...props
}: Props) => {
  // noinspection JSUnusedLocalSymbols
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(name);
  return (
    <div className="block m-1 ml-0" {...props}>
      <div className="flex flex-row justify-between items-center justify-center">
        {label && <Label>{label}</Label>}
        {subLabel && (
          <Text size="subLabel" className="pb-3">
            {subLabel}
          </Text>
        )}
      </div>
      {children}
      {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
    </div>
  );
};

export default connect(RadioGroup);
