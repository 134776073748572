import ErrorMessage from "@/shared/components/form/ErrorMessage";
import { connect, FieldInputProps, FormikProps } from "formik";
import React from "react";

type Props = {
  form: FormikProps<any>;
  field: FieldInputProps<any>;
  label?: string;
  className?: string;
  [x: string]: any;
};

const Checkbox: React.FC<Props> = ({
  form: { touched, errors },
  field,
  label,
  className,
  ...props
}: Props) => {
  return (
    <div className="form-check mb-1 w-100">
      <label className="form-check-label w-100 block py-1">
        <input
          type="checkbox"
          className={`w-5 h-5 pl-2 align-middle -mt-1 form-checkbox text-yoco-green ${className}`}
          {...field}
          {...props}
        />
        {label && <span className="ml-3">{label}</span>}
      </label>
      {errors[field.name] && touched[field.name] && (
        <ErrorMessage>{errors[field.name] as string}</ErrorMessage>
      )}
    </div>
  );
};

export default connect(Checkbox);
