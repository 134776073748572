import Loader from "@/shared/components/Loader";
import { useSignupStore } from "@/signup/store";
import { useWaitUntilMerchantAccountIsCreated } from "@/signup/utils/hooks";
import React, { useEffect } from "react";

const useStore = () => ({
  signup: useSignupStore(state => state.signup),
  yocoAuthToken: useSignupStore(state => state.yocoAuthToken),
});

const InKhumoAppContent: React.FC<any> = () => {
  const { yocoAuthToken, signup } = useStore();
  const { isMerchantAccountCreated } = useWaitUntilMerchantAccountIsCreated();

  useEffect(() => {
    // As soon as we emit the "SIGNUP_COMPLETE" event to the app, the app will attempt to auto-login the user
    // using the yat.  Whilst the signup is complete, it's possible that the account hasn't yet been created,
    // since this is done asynchronously on a background worker.  We therefore poll the signup until we know
    // the account has been created, and only then emit the "SIGNUP_COMPLETE" event.
    if (
      signup &&
      yocoAuthToken &&
      isMerchantAccountCreated &&
      window.ReactNativeWebView
    ) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          event: "SIGNUP_COMPLETE",
          yat: yocoAuthToken,
          uuid: signup.id,
          businessUUID: signup.business_uuid,
          userUUID: signup.user_uuid,
        })
      );
    }
  }, [signup, yocoAuthToken, isMerchantAccountCreated]);

  return (
    <div className="mt-12">
      <Loader text="Creating your account..." size="lg" />
    </div>
  );
};

export default InKhumoAppContent;
