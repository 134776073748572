import React from "react";

type Props = {
  children: React.ReactNode;
  className?: string;
  style?: any;
};

const Card: React.FC<Props> = ({ children, className, style }: Props) => {
  return (
    <div
      className={`rounded-lg bg-white p-4 ${className}`}
      style={{ ...style }}
    >
      {children}
    </div>
  );
};

export default Card;
