import { Cart } from "@/shared/shop/api";
import { Address } from "@/shared/types";
import CONFIG from "@/config";

export const getShippingAddressFromCart = (cart?: Cart | null): Address => {
  return {
    line1: cart?.shipping_address_line1 || "",
    line2: cart?.shipping_address_line2 || "",
    suburb: cart?.shipping_suburb || "",
    city: cart?.shipping_city || "",
    province: cart?.shipping_province || "",
    postal_code: cart?.shipping_postal_code || "",
    country: cart?.shipping_country || CONFIG.countryCode,
    latitude: cart?.shipping_latitude || null,
    longitude: cart?.shipping_longitude || null,
  } as Address;
};
