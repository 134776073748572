import CardMain from "@/shared/components/CardMain";
import Field from "@/shared/components/form/Field";
import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import RadioGroupFlexWrap from "@/shared/components/form/RadioGroupFlexWrap";
import Header from "@/shared/components/Header";
import Segment from "@/shared/services/Segment";
import { makeTestID } from "@/shared/utils/development";
import { getParentIndex } from "@/shared/utils/helpers/general";
import Layout from "@/signup/components/Layout";
import StepProgressBar from "@/signup/components/StepProgressBar";
import arrowIcon from "@/signup/components/StepProgressBar/arrow-left.svg";
import SubmitButton from "@/signup/components/SubmitButton";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import CategorySelectorRow from "@/signup/pages/BusinessCategoryPage/CategorySelectorRow";
import {
  useFormHelperStore,
  useSignupStore,
  useStepStore,
} from "@/signup/store";
import { SignupRoute } from "@/signup/types";
import { BusinessSubcategory, SignupStep } from "@yoco/sawubona-sdk";
import { FormikValues } from "formik";
import { findIndex, get } from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import * as yup from "yup";
import CategorySearch from "./CategorySearch";

const useStore = () => ({
  setActiveStep: useStepStore(state => state.setActiveStep),
  signup: useSignupStore(state => state.signup),
  getSignup: useSignupStore(state => state.getSignup),
  updateSignup: useSignupStore(state => state.updateSignup),
  businessCategories: useFormHelperStore(state => state.businessCategories),
  fetchBusinessCategories: useFormHelperStore(
    state => state.fetchBusinessCategories
  ),
  isOnFinalStep: useStepStore(state => state.isOnFinalStep),
  finaliseSignup: useSignupStore(state => state.finaliseSignup),
  getNextStepLink: useStepStore(state => state.getNextStepLink),
});

type Props = {
  maxVisibleSubcategories?: number;
};

const BusinessCategoryPage: React.FC<Props> = ({
  maxVisibleSubcategories = 10,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showMore, setShowMore] = useState<boolean>(false);

  const {
    setActiveStep,
    signup,
    getSignup,
    updateSignup,
    businessCategories,
    fetchBusinessCategories,
    isOnFinalStep,
    finaliseSignup,
    getNextStepLink,
  } = useStore();

  useEffect(() => {
    setActiveStep(SignupStep.BusinessCategoryPage);
  }, [setActiveStep]);

  useEffect(() => {
    Segment.track("web_signup_business_details_mcc_group_start");
  }, [location]);

  useEffect(() => {
    fetchBusinessCategories();
  }, [fetchBusinessCategories]);

  // TODO: Change API to return parent category id
  const parentIndex: any = String(
    getParentIndex(businessCategories, `"id":${signup?.business_category_id}`)
  );

  const initialValues = {
    stage: SignupStep.BusinessCategoryPage,
    business_category_parent_id:
      (signup?.business_category_id &&
        String(businessCategories[parentIndex]?.id)) ||
      "",
    business_category_id:
      (signup?.business_category_id && String(signup?.business_category_id)) ||
      "",
    search: "",
  };

  const schema = yup.object({
    business_category_parent_id: yup
      .number()
      .required("You must select a business category.")
      .label("business category"),
    business_category_id: yup
      .number()
      .required("You must select a business subcategory.")
      .label("business subcategory"),
    search: yup.string().notRequired(),
  });

  const onSubmit = async (values: FormikValues) => {
    const isSignupUpdated = await updateSignup({
      business_category_id: Number(values.business_category_id),
    });

    if (isSignupUpdated) {
      Segment.trackWithSignup(
        "web_signup_business_details_mcc_group_next_button_clicked"
      );
      Segment.trackWithSignup("Opportunity Created", "milestones");

      if (isOnFinalStep()) {
        const isSignupFinalised = await finaliseSignup();

        if (isSignupFinalised) {
          const finalisedSignup = getSignup();

          navigate(
            formatRoute(SignupRoute.CompletePage, {
              id: finalisedSignup.id,
            })
          );
        }
      } else {
        navigate(getNextStepLink());
      }
    }
  };

  const isSubcategoryVisibleOnPage = (
    subcategory: BusinessSubcategory
  ): boolean => {
    // First, we'll find the parent category within our list of business categories
    const parentCategoryIndex = findIndex(businessCategories, [
      "id",
      subcategory.parent_category_id,
    ]);

    if (parentCategoryIndex >= 0) {
      const parentCategory = businessCategories[parentCategoryIndex];

      // Next, we'll find what position this subcategory is at, within the parent's list of subcategories
      // If it's < maxVisibleSubcategories, then we know it's visible.
      // If it's >= maxVisibleSubcategories, it would only be visible if the "show more" is toggled.
      const subcategoryIDs = parentCategory.subcategories.map(s => s.id);
      const subcategoryIndex = subcategoryIDs.indexOf(subcategory.id);

      return (
        subcategoryIndex >= 0 && subcategoryIndex < maxVisibleSubcategories
      );
    }
    return false;
  };

  const setSubcategory = (subcategory: BusinessSubcategory, formik: any) => {
    formik.setFieldValue(
      "business_category_parent_id",
      String(subcategory.parent_category_id)
    );
    formik.setFieldValue("business_category_id", String(subcategory.id));

    if (isSubcategoryVisibleOnPage(subcategory)) {
      setShowMore(false);
    } else {
      setShowMore(true);
    }
  };

  return (
    <Layout>
      <CardMain>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {(formik: any) => {
            const { values } = formik;
            const selectedId = get(values, "business_category_parent_id");

            const defaultBusinessCategories = findIndex(businessCategories, [
              "id",
              Number(selectedId),
            ]);

            const subCategories =
              businessCategories[defaultBusinessCategories]?.subcategories;
            return (
              <Form className="grid gap-3">
                {!subCategories && (
                  <>
                    <StepProgressBar />
                    <Header
                      title="What do people pay you for?"
                      description="Select the category that best describes what your business does."
                    />

                    {businessCategories && (
                      <Field
                        name="search"
                        placeholder="Search"
                        component={CategorySearch}
                        categories={businessCategories}
                        onSelectResult={result => {
                          setSubcategory(result, formik);

                          formik.setFieldValue("search", "");
                        }}
                        data-testid={makeTestID("businessCategory", "search")}
                      />
                    )}
                    <RadioGroupFlexWrap
                      name="business_category_parent_id"
                      label="Business category *"
                    >
                      {businessCategories &&
                        businessCategories.map(category => (
                          <Field
                            key={category.id}
                            name="business_category_parent_id"
                            type="radio"
                            value={String(category.id)}
                            label={category.name}
                            imageClass={category.image_class}
                            component={CategorySelectorRow}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              formik.handleChange(e);
                              formik.setFieldValue("business_category_id", "");
                              window.scrollTo(0, 0);
                              setShowMore(false);
                            }}
                            data-testid={makeTestID(
                              "businessCategory",
                              category.name.replace(/[^a-zA-Z ]/g, "")
                            )}
                          />
                        ))}
                    </RadioGroupFlexWrap>
                  </>
                )}

                {subCategories && (
                  <>
                    <div className="flex flex-row pt-4">
                      <button
                        type="button"
                        className="pr-4"
                        onClick={() => {
                          formik.setFieldValue(
                            "business_category_parent_id",
                            ""
                          );
                        }}
                      >
                        <img src={arrowIcon} alt="Arrow" />
                      </button>
                      <span>
                        {businessCategories[defaultBusinessCategories].name}
                      </span>
                    </div>

                    <RadioGroupFlexWrap
                      name="business_category_id"
                      label="Business subcategory *"
                    >
                      {subCategories
                        ?.slice(
                          0,
                          showMore
                            ? subCategories?.length
                            : maxVisibleSubcategories
                        )
                        .map(subcategory => (
                          <Field
                            key={subcategory.id}
                            name="business_category_id"
                            type="radio"
                            value={String(subcategory.id)}
                            label={subcategory.name}
                            imageClass={subcategory.image_class}
                            component={CategorySelectorRow}
                            data-testid={makeTestID(
                              "businessSubcategory",
                              subcategory.name.replace(/[^a-zA-Z ]/g, "")
                            )}
                          />
                        ))}
                    </RadioGroupFlexWrap>
                    {subCategories?.length > maxVisibleSubcategories && (
                      <div className="-mt-3">
                        <button
                          type="button"
                          className="text-yoco-blue cursor-pointer"
                          onClick={() => setShowMore(!showMore)}
                          data-testid={makeTestID(
                            "businessSubcategory",
                            "show"
                          )}
                        >
                          {showMore ? "Show less" : "Show more"}
                        </button>
                      </div>
                    )}
                  </>
                )}
                {subCategories && (
                  <SubmitButton testID={makeTestID("businessCategory", "next")}>
                    Next
                  </SubmitButton>
                )}
              </Form>
            );
          }}
        </Formik>
      </CardMain>
    </Layout>
  );
};

export default withFetchSignup(BusinessCategoryPage);
