import { connect, FieldInputProps } from "formik";
import React from "react";
import "./CategorySelector.scss";

type Props = {
  field: FieldInputProps<any>;
  label: string;
  imageClass: string;
  [x: string]: any;
};

const CategorySelectorRow: React.FC<Props> = ({
  field,
  label,
  imageClass,
  ...props
}: Props) => {
  return (
    <div className="form-check block mb-3 bg-white border border-white rounded-md w-full pr-2">
      <label>
        <input
          type="radio"
          className="form-radio text-yoco-green-700 hidden"
          style={{
            position: "absolute",
            marginTop: 8,
            marginLeft: 8,
            width: 18,
            height: 18,
            zIndex: 5,
          }}
          {...field}
          {...props}
        />
        <div
          className={`cursor-pointer border-2 border-transparent rounded-md h-12 flex flex-row items-center py-4
          ${field.checked ? "option-selected " : "shadow"}`}
        >
          <div className={`icon-bg bg-${imageClass}`} />
          <div className="block leading-4 tracking-tight ml-2 text-md">
            {label}
          </div>
        </div>
      </label>
    </div>
  );
};

export default connect(CategorySelectorRow);
