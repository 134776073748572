import CardMain from "@/shared/components/CardMain";
import Field from "@/shared/components/form/Field";
import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import FormOption from "@/shared/components/form/FormOption";
import RadioGroup from "@/shared/components/form/RadioGroup";
import Header from "@/shared/components/Header";
import Segment from "@/shared/services/Segment";
import { makeTestID } from "@/shared/utils/development";
import Layout from "@/signup/components/Layout";
import StepProgressBar from "@/signup/components/StepProgressBar";
import SubmitButton from "@/signup/components/SubmitButton";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import {
  useFormHelperStore,
  useSignupStore,
  useStepStore,
} from "@/signup/store";
import { SignupStep } from "@yoco/sawubona-sdk";
import { FormikValues } from "formik";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";

const useStore = () => ({
  setActiveStep: useStepStore(state => state.setActiveStep),
  signup: useSignupStore(state => state.signup),
  updateSignup: useSignupStore(state => state.updateSignup),
  businessTypes: useFormHelperStore(state => state.businessTypes),
  fetchBusinessTypes: useFormHelperStore(state => state.fetchBusinessTypes),
  getNextStepLink: useStepStore(state => state.getNextStepLink),
});

const BusinessTypePage: React.FC<any> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    setActiveStep,
    signup,
    updateSignup,
    businessTypes,
    fetchBusinessTypes,
    getNextStepLink,
  } = useStore();

  useEffect(() => {
    setActiveStep(SignupStep.BusinessTypePage);
  }, [setActiveStep]);

  useEffect(() => {
    fetchBusinessTypes();
  }, [fetchBusinessTypes]);

  useEffect(() => {
    Segment.track("web_signup_business_type_start");
  }, [location]);

  const initialValues = {
    stage: SignupStep.BusinessTypePage,
    business_type: signup?.business_type || "",
  };

  const schema = yup.object({
    business_type: yup
      .string()
      .required("You must select a business type.")
      .label("business type"),
  });

  const onSubmit = async (values: FormikValues) => {
    const isSignupUpdated = await updateSignup(values);

    if (isSignupUpdated) {
      Segment.trackWithSignup("web_signup_business_type_next_button_clicked");

      navigate(getNextStepLink());
    }
  };

  return (
    <Layout>
      <CardMain>
        <StepProgressBar />
        <Header
          title="How is your business structured?"
          description={
            <>
              <p className="pt-6">
                Pick a category below that best describes how your business is
                owned and operated:
              </p>
            </>
          }
        />

        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {() => {
            return (
              <Form>
                <RadioGroup name="business_type" className="mb-8 grid gap-3">
                  {businessTypes.map(businessType => (
                    <Field
                      key={businessType.value}
                      name="business_type"
                      type="radio"
                      value={businessType.value}
                      option={businessType}
                      component={FormOption}
                      data-testid={makeTestID(
                        "businessType",
                        "businessType",
                        businessType.value
                      )}
                    />
                  ))}
                </RadioGroup>

                <SubmitButton testID={makeTestID("businessType", "next")}>
                  Next
                </SubmitButton>
              </Form>
            );
          }}
        </Formik>
      </CardMain>
    </Layout>
  );
};

export default withFetchSignup(BusinessTypePage);
