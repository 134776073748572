import React from "react";
import { PaymentMethod } from "@/shared/shop/api";
import CreditCardForm from "@/shop/pages/PaymentPage/tabs/CreditCardPaymentMethod/CreditCardForm";
import { PaymentMethodProps } from "@/shop/types";

const StraightCreditCardPaymentMethod: React.FC<PaymentMethodProps> = ({
  onSuccess,
}: PaymentMethodProps) => {
  const onTransactionComplete = async result => {
    if (result.status === "succeeded") {
      if (onSuccess) {
        onSuccess(PaymentMethod.CREDIT_CARD_BLACKBIRD, true);
      }
    }
  };

  return <CreditCardForm onTransactionComplete={onTransactionComplete} />;
};

export default StraightCreditCardPaymentMethod;
