import { useSignupStore } from "@/signup/store";

interface Identity {
  email: string;
  signupUUID: string;
  businessUUID: string;
  userUUID: string;
}

const getSegmentTracker = (): Promise<SegmentAnalytics.AnalyticsJS> => {
  /*
    There's a slight delay between the script tag loading in helmet, and a component possibly
    triggering a tracking event in componentDidMount.  We therefore resolve the tracker using
    a promise.  If it's not ready upfront, we add a tiny delay, and try again.  This is really only
    here to solve the first call on a page load, if that first call happens on
    impression.
     */

  return new Promise((resolve, reject) => {
    if (window.analytics) {
      resolve(window.analytics);
    } else {
      setTimeout(() => {
        if (window.analytics) {
          resolve(window.analytics);
        } else {
          reject();
        }
      }, 150);
    }
  });
};

const trackPageView = (): void => {
  getSegmentTracker().then(tracker => {
    tracker.page();
  });
};

const track = (
  event: string,
  params: any = {},
  eventCategory = "tracking_events"
): void => {
  getSegmentTracker().then(tracker => {
    const newParams = { ...params } as Record<string, unknown>;
    newParams.event_category = eventCategory;

    tracker.track(event, newParams);
  });
};

const trackWithSignup = (
  event: string,
  eventCategory = "tracking_events"
): void => {
  const signup = useSignupStore.getState().getSignup();

  const params = { ...signup } as any;

  if (params.experiments) {
    params.experiments = Object.values(params.experiments);
  }

  track(event, params, eventCategory);
};

const alias = (userUUID: string): void => {
  getSegmentTracker().then(tracker => {
    tracker.alias(userUUID);
  });
};

const identify = (identity: Identity): void => {
  getSegmentTracker().then(tracker => {
    tracker.identify(identity.userUUID, {
      email: identity.email,
      businessUUID: identity.businessUUID,
      signupUUID: identity.signupUUID,
      userUUID: identity.userUUID,
    });
  });
};

export default {
  getSegmentTracker,
  trackPageView,
  track,
  trackWithSignup,
  alias,
  identify,
};
