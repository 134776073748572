import CardMain from "@/shared/components/CardMain";
import Field from "@/shared/components/form/Field";
import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import Input from "@/shared/components/form/Input";
import Header from "@/shared/components/Header";
import Segment from "@/shared/services/Segment";
import { makeTestID } from "@/shared/utils/development";
import Layout from "@/signup/components/Layout";
import StepProgressBar from "@/signup/components/StepProgressBar";
import SubmitButton from "@/signup/components/SubmitButton";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import Validator from "@/signup/services/Validator";
import { useSignupStore, useStepStore } from "@/signup/store";
import { SignupRoute } from "@/signup/types";
import { BusinessType, Service, SignupStep } from "@yoco/sawubona-sdk";
import { FormikValues } from "formik";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import * as yup from "yup";

const useStore = () => ({
  setActiveStep: useStepStore(state => state.setActiveStep),
  signup: useSignupStore(state => state.signup),
  finaliseSignup: useSignupStore(state => state.finaliseSignup),
});

const DeveloperDetailsPage: React.FC<any> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { setActiveStep, signup, finaliseSignup } = useStore();

  useEffect(() => {
    setActiveStep(SignupStep.DeveloperDetailsPage);
  }, [setActiveStep]);

  useEffect(() => {
    Segment.track("web_signup_developer_details_start");
  }, [location]);

  const initialValues = {
    stage: SignupStep.DeveloperDetailsPage,
    business_name: signup?.business_name || "",
    owner_first_name: signup?.owner_first_name || "",
    owner_last_name: signup?.owner_last_name || "",
  };

  yup.addMethod(
    yup.string,
    "validBusinessName",
    Validator.validateBusinessName
  );

  const schema = yup.object({
    business_name: (yup as any)
      .string()
      .required()
      .label("business name")
      .validBusinessName(),
    owner_first_name: yup.string().required().label("first name"),
    owner_last_name: yup.string().required().label("last name"),
  });

  const onSubmit = async (values: FormikValues) => {
    const newValues = { ...values };
    newValues.business_type = BusinessType.SOLE_TRADER;
    newValues.business_category_id = 74; // Services -> Computer Services
    newValues.services = [Service.WEBSITE];

    const isSignupFinalised = await finaliseSignup(newValues);

    if (isSignupFinalised) {
      Segment.trackWithSignup("web_signup_click_signup_for_developer_account");
      Segment.trackWithSignup("Opportunity Created", "milestones");

      navigate(
        formatRoute(SignupRoute.CompletePage, {
          id: signup?.id,
        })
      );
    }
  };

  return (
    <Layout>
      <CardMain>
        <StepProgressBar />
        <Header title="Developer account" />
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {() => {
            return (
              <Form>
                <div className="grid gap-3 mb-8">
                  <Field
                    name="business_name"
                    placeholder="Business name *"
                    component={Input}
                    data-testid={makeTestID("developerDetails", "businessName")}
                  />
                  <div className="flex flex-wrap">
                    <div className="w-full sm:w-1/2 mb-3 sm:mb-0 sm:pr-2 rounded">
                      <Field
                        name="owner_first_name"
                        placeholder="First name *"
                        component={Input}
                        data-testid={makeTestID(
                          "developerDetails",
                          "firstName"
                        )}
                      />
                    </div>
                    <div className="w-full sm:w-1/2 sm:pl-2 rounded">
                      <Field
                        name="owner_last_name"
                        placeholder="Last name *"
                        component={Input}
                        data-testid={makeTestID("developerDetails", "lastName")}
                      />
                    </div>
                  </div>
                </div>
                <SubmitButton testID={makeTestID("businessName", "next")}>
                  Signup
                </SubmitButton>
              </Form>
            );
          }}
        </Formik>
      </CardMain>
    </Layout>
  );
};

export default withFetchSignup(DeveloperDetailsPage);
