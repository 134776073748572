import xneeloImage from "@/assets/images/xneelo-img.png";
import logoImage from "@/assets/images/xneelo-logo.svg";
import Logo from "@/shared/components/Logo";
import Text from "@/shared/components/Text";
import SignupIcons from "@/signup/pages/HomePage/SignupIcons";
import { useSignupStore } from "@/signup/store";
import { UserJourney } from "@/signup/types";
import React, { FC } from "react";

const useStore = () => ({
  referralDetails: useSignupStore(state => state.referralDetails),
});
type Props = {
  hasReferralCode: boolean;
  userJourney: UserJourney | null;
};

const XneeloHeader: FC = () => {
  return (
    <>
      <div className="flex justify-center mb-4 pb-3 space-x-4">
        <div className="logo">
          <img
            className="yoco-logo transform motion-reduce:transform-none hover:-translate-x-1w hover:scale-110 transition ease-in-out duration-300 mt-2 w-32"
            src={logoImage}
            alt="Yoco Logo"
          />
        </div>

        <div className="w-px h-4 border-r border-black mt-4" />
        <Logo />
      </div>

      <div className="mx-auto mb-4 sm:mb-3 text-center flex items-center justify-center flex-col sm:w-3/4">
        <h1 className="hidden sm:block text-2xl lg:text-3xl font-bold leading-10 sm:w-4/5 sm:mx-auto text-green-900">
          Grow your business with xneelo and Yoco
        </h1>
        <div className="sm:w-3/4 sm:mx-auto mb-3 mt-3">
          <img src={xneeloImage} alt="Woman using a laptop" />
        </div>
        <div className="sm:w-5/6 sm:mx-auto mt-4 text-center">
          Use the form below to sign up for Yoco&apos;s payment gateway and
          enjoy zero transaction fees for the first three months
          <span className="text-red-500">*</span>
        </div>
        <div className="sm:w-5/6 sm:mx-auto my-4 text-gray-500 text-xs">
          *Transaction fees will be billed, but fully refunded at the end of
          each month.
        </div>

        <SignupIcons />
      </div>
    </>
  );
};

const ReferralHeader: FC<Omit<Props, "hasReferralCode">> = ({
  userJourney = UserJourney.BANANA,
}) => {
  const { referralDetails } = useStore();

  return (
    <>
      <div className="flex justify-center mb-4 pb-3">
        {userJourney !== UserJourney.CINNAMON && <Logo />}
      </div>

      <div className="mx-auto mb-4 sm:mb-3 text-center flex items-center justify-center flex-col sm:w-3/4">
        <div className="mt-6 mb-8 sm:mb-12 sm:w-3/4 md:w-full md:max-w-lg sm:mx-auto">
          <div className="sharp-grotesk relative text-center bg-yoco-dark-green leading-tight rounded-3xl lg:rounded-6xl mb-10 lg:mb-12">
            <h1 className="text-3xl sm:text-4xl lg:text-5xl py-5 px-2">
              <span className="text-white">
                You&apos;ve been referred{" "}
                <span role="img" aria-labelledby="eyes">
                  👀
                </span>{" "}
                get{" "}
              </span>
              <span className="text-yoco-yellow-100">R100 off</span>
            </h1>

            <div className="absolute w-full text-yoco-dark-green -mt-3.5 sm:-mt-4.5 lg:-mt-5">
              <svg
                version="1.1"
                viewBox="0 0 49 98"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                className="fill-current transform rotate-90 h-12 sm:h-16 lg:h-20 w-12 sm:w-16 lg:w-20 mx-auto"
              >
                <path d="M-0.00117166017,0 C-0.00117166017,27.0155468 21.9252768,48.9228862 49,48.9964948 C21.9252768,49.0771138 -0.00117166017,70.9832848 -0.00117166017,98 C-0.00117166017,70.9832848 -0.00117166017,27.0155468 -0.00117166017,0 Z" />
              </svg>
            </div>
          </div>

          <Text size="h4" className="text-center">
            <span className="font-normal">
              {referralDetails?.ownerFirstName || "Your Friend"} thinks you need
              a Yoco card machine for less{" "}
              <span role="img" aria-labelledby="smirk">
                😏
              </span>
            </span>

            <span className="font-bold"> get R100 off. It&apos;s on us!</span>
          </Text>
        </div>

        <SignupIcons />

        {userJourney === UserJourney.CINNAMON && (
          <h6 className="block text-base text-black-600">
            Please fill in the business owner&apos;s details if you&apos;re
            doing this for them.
          </h6>
        )}
      </div>
    </>
  );
};

const YocoShopHeader: FC = () => {
  return (
    <>
      <div className="flex justify-center mb-4 pb-3">
        <Logo />
      </div>
      <div className="mx-auto mb-4 sm:mb-3 text-center flex items-center justify-center flex-col sm:w-3/4">
        <h1 className="hidden sm:block text-2xl lg:text-3xl font-bold md:mb-4 leading-10 xl:truncate pb-3">
          Create your profile
        </h1>
      </div>
    </>
  );
};

const DefaultHeader: FC<Omit<Props, "hasReferralCode">> = ({
  userJourney = UserJourney.BANANA,
}) => {
  return (
    <>
      <div className="flex justify-center mb-4 pb-3">
        {userJourney !== UserJourney.CINNAMON && <Logo />}
      </div>

      <div className="mx-auto mb-4 sm:mb-3 text-center flex items-center justify-center flex-col sm:w-3/4">
        <h1 className="hidden sm:block text-2xl lg:text-3xl font-bold md:mb-4 leading-10 xl:truncate pb-3">
          {userJourney === UserJourney.DEVELOPER
            ? "Sign up for a developer account"
            : "Three easy steps to get paid"}
        </h1>

        {userJourney !== UserJourney.DEVELOPER && <SignupIcons />}

        {userJourney === UserJourney.CINNAMON && (
          <h6 className="block text-base text-black-600">
            Please fill in the business owner&apos;s details if you&apos;re
            doing this for them.
          </h6>
        )}
      </div>
    </>
  );
};

const LeadFormHeader: FC<Props> = ({
  hasReferralCode,
  userJourney = UserJourney.BANANA,
}) => {
  if (userJourney === UserJourney.XNEELO) {
    return <XneeloHeader />;
  }

  if (hasReferralCode) {
    return <ReferralHeader userJourney={userJourney} />;
  }

  if (userJourney === UserJourney.YOCOSHOP) {
    return <YocoShopHeader />;
  }

  return <DefaultHeader userJourney={userJourney} />;
};

export default LeadFormHeader;
