import React, { useCallback, useEffect, useMemo, useState } from "react";
import { formatRoute } from "react-router-named-routes";
import Loader from "@/shared/components/Loader";
import shop, { PaymentMethod } from "@/shared/shop/api";
import { useCartStore } from "@/shop/store";
import { useSignupStore } from "@/signup/store";
import SubmitButton from "@/signup/components/SubmitButton";
import { makeTestID } from "@/shared/utils/development";
import padlockIcon from "@/shop/pages/PaymentPage/images/padlock.svg";
import { ShopRoute } from "@/shop/types";
import { SignupRoute } from "@/signup/types";
import Segment from "@/shared/services/Segment";

const useStore = () => ({
  cart: useCartStore(state => state.cart),
  availablePaymentMethods: useCartStore(
    state => state.cart?.available_payment_methods
  ),
  signup: useSignupStore(state => state.signup),
});

const CheckoutPaymentMethod: React.FC = () => {
  const { availablePaymentMethods, cart, signup } = useStore();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isAutomaticallyRedirecting = useMemo(
    () => !availablePaymentMethods?.includes(PaymentMethod.CARD_ON_DELIVERY),
    [availablePaymentMethods]
  );

  const onRedirectToCheckout = useCallback(async () => {
    if (!cart?.id) {
      return;
    }
    Segment.track("web_signup_payment_select_checkout_payment");
    setIsSubmitting(true);
    const { successUrl, cancelUrl } = getCallbackUrls({
      cartId: cart.id,
      signupId: signup?.id,
    });
    const payment = await shop.carts.initiateCheckoutPayment(
      cart.id,
      cart.balance_due.toString(),
      successUrl,
      cancelUrl
    );
    window.location.replace(payment.redirect_url);
  }, [cart?.id, cart?.balance_due, signup?.id]);

  useEffect(() => {
    if (isAutomaticallyRedirecting) {
      onRedirectToCheckout();
    }
  }, [isAutomaticallyRedirecting, onRedirectToCheckout]);

  return (
    <div>
      {isAutomaticallyRedirecting ? (
        <Loader />
      ) : (
        <SubmitButton
          onClick={onRedirectToCheckout}
          submitting={isSubmitting}
          testID={makeTestID("payment", "checkout")}
        >
          <div className="flex flex-row justify-center space-x-4">
            <img src={padlockIcon} alt="pad lock icon" />
            <span>Pay {cart?.balance_due_formatted}</span>
          </div>
        </SubmitButton>
      )}
    </div>
  );
};

const getCallbackUrls = ({
  cartId,
  signupId,
}: {
  cartId: string;
  signupId?: string;
}) => {
  const isSignup = !!signupId;
  const baseSuccessUrl = window.location.origin;
  const successPath = isSignup
    ? formatRoute(SignupRoute.CompletePage, { id: signupId })
    : formatRoute(ShopRoute.CompletePage, { id: cartId });
  const cancelPath = isSignup
    ? formatRoute(SignupRoute.DeliveryPage, { id: signupId })
    : formatRoute(ShopRoute.DeliveryPage, { id: cartId });
  return {
    successUrl: `${baseSuccessUrl}${successPath}?isPaid=true`,
    cancelUrl: `${baseSuccessUrl}${cancelPath}`,
  };
};

export default CheckoutPaymentMethod;
