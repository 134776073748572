import loaderImage from "@/assets/images/loader.svg";
import React from "react";

type Props = {
  text?: string;
  size?: "sm" | "lg";
};

const Loader: React.FC<Props> = ({
  text = "Loading...",
  size = "sm",
}: Props) => {
  const width = size === "sm" ? 60 : 150;

  return (
    <div className="pt-4 flex flex-col w-full items-center">
      <img
        alt="Loading..."
        className="mx-auto"
        width={width}
        src={loaderImage}
      />
      <span className="pt-4">{text}</span>
    </div>
  );
};

export default Loader;
