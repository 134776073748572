import { Product } from "@/shared/shop/api";
import ProductBlock from "@/shop/pages/ProductCataloguePage/ProductBlock";
import {
  useAppConfigStore,
  useCartStore,
  useFormHelperStore,
} from "@/shop/store";
import React, { useEffect, useMemo } from "react";

const useStore = () => ({
  cartItems: useCartStore(state => state.cart?.items),
  products: useFormHelperStore(state => state.products),
  fetchProducts: useFormHelperStore(state => state.fetchProducts),
  storefront: useAppConfigStore(state => state.storefront),
  productTypes: useAppConfigStore(state => state.productTypes),
});

type Props = {
  onAddedProductToCart?: (product: Product) => void;
};

const ProductGrid: React.FC<Props> = ({ onAddedProductToCart }: Props) => {
  const { cartItems, products, fetchProducts, storefront, productTypes } =
    useStore();

  useEffect(() => {
    fetchProducts(storefront || "signup", productTypes);
  }, [fetchProducts, storefront]);

  const numGridColumns = useMemo(
    () => (products.length === 4 || products.length === 2 ? "2" : "3"),
    [products]
  );

  return (
    <div
      className={`grid flex-wrap items-stretch justify-center place-items-center gap-4 grid-cols-1 ${
        products.length > 1
          ? `sm:grid-cols-2 lg:grid-cols-${numGridColumns}`
          : ""
      }`}
    >
      {products.map(product => {
        const cartItem = cartItems?.find(
          item => item.product.id === product.id
        );
        const quantity = cartItem?.quantity || 0;

        return (
          <ProductBlock
            key={product.id}
            product={product}
            quantity={quantity}
            onAddedToCart={onAddedProductToCart}
          />
        );
      })}
    </div>
  );
};

export default ProductGrid;
