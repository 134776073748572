import React from "react";
import Field from "@/shared/components/form/Field";
import Input from "@/shared/components/form/Input";
import { makeTestID } from "@/shared/utils/development";

type Props = {
  className?: string;
};

const OrderNumberField: React.FC<Props> = ({ className }: Props) => {
  return (
    <div className={`${className}`}>
      <div className="font-bold">Enter your order number</div>
      <div className="shadow border-2 border-transparent rounded-lg p-3 pt-4 pb-4 text-sm mb-4 mt-2">
        You&apos;ll find it in your email inbox
        <div className="flex flex-row pb-2 pt-2">
          <div className="w-full">
            <Field
              name="order_number"
              placeholder="000 0000"
              component={Input}
              data-testid={makeTestID("registerReader", "orderNumber")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderNumberField;
