import {
  Formik as BaseFormik,
  FormikHelpers,
  FormikProps,
  FormikValues,
} from "formik";
import React from "react";

type Props = {
  children: (formik: FormikProps<any>) => React.ReactNode;
  initialValues?: Record<string, unknown>;
  validationSchema?: any;
  onSubmit: (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => void;
  [x: string]: any;
};

const Formik: React.FC<Props> = ({
  children,
  initialValues = {},
  validationSchema,
  onSubmit,
  ...props
}: Props) => {
  return (
    <BaseFormik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
      {...props}
    >
      {children}
    </BaseFormik>
  );
};

export default Formik;
