import CONFIG from "@/config";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import * as yup from "yup";
import { TestContext } from "yup";

const messages = {
  mixed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    required: ({ path }): string => {
      return `The ${path} field is required.`;
    },
  },
  string: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    email: ({ path }): string => {
      return `The ${path} is not a valid email address.`;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    min: ({ path, min }): string => {
      return `The ${path} must be a minimum of ${min} characters.`;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    max: ({ path, max }): string => {
      return `The ${path} must be a maximum of ${max} characters.`;
    },
  },
};

const isValidPhoneNumber = (value: string): boolean => {
  if (!value) {
    return false;
  }

  try {
    const parsed = parsePhoneNumberFromString(value, CONFIG.countryCode);

    return parsed?.isValid() || false;
  } catch (error) {
    return false;
  }
};

// The custom test() validation functions we write may duplicate some of the validation rules that exist earlier in a
// schema chain.  This is because Yup runs rules asynchronously, so we land up in situations where we might
// validate that a phone number matches a particular format, when the value is actually blank.  We also land up
// hitting backend validation services and passing bogus values, when we know they're actually bogus.

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const validatePhoneNumber = (args: any) => {
  return (yup as any)
    .string()
    .test(
      "is-phone-number",
      args,
      function test(this: TestContext, value: any) {
        if (value) {
          if (isValidPhoneNumber(value)) {
            return true;
          }
          return this.createError({
            // eslint-disable-next-line no-template-curly-in-string
            message: "The ${path} is not a valid mobile number.",
          });
        }
        return this.createError({
          // eslint-disable-next-line no-template-curly-in-string
          message: "The ${path} field is required.",
        });
      }
    );
};

export default {
  messages,
  isValidPhoneNumber,
  validatePhoneNumber,
};
