import CONFIG from "@/config";
import { Address } from "@/shared/types";
import { makeTestID } from "@/shared/utils/development";
import { executeScroll } from "@/shared/utils/helpers/general";
import AddressService from "@/shop/services/AddressService";
import { FieldInputProps, FormikProps } from "formik";
import React, { useRef, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import useScript from "react-script-hook";
import "./index.scss";

type Props = {
  form: FormikProps<any>;
  field: FieldInputProps<any>;
  placeholder?: string;
  onSelect: (address: Address) => void;
  readonly?: boolean;
};

const GooglePlacesAutocomplete: React.FC<Props> = ({
  form,
  field,
  placeholder,
  onSelect,
  readonly = false,
  ...props
}: Props) => {
  const inputRef = useRef<HTMLDivElement>(null);

  const [address, setAddress] = useState(form.initialValues.address_line1);

  const [isScriptLoading, scriptError] = useScript({
    src: `https://maps.googleapis.com/maps/api/js?key=${CONFIG?.googlePlacesPublicKey}&libraries=places`,
    checkForExisting: true,
  });
  const isScriptLoaded = !isScriptLoading && scriptError === null;

  const handleChange = (addressLine1: string) => {
    form.setFieldValue("address_line1", addressLine1);
    setAddress(addressLine1);
  };

  const handleSelect = (addressLine1: string) => {
    geocodeByAddress(addressLine1)
      .then(results => {
        const selectedAddress = AddressService.getStructuredAddress(results[0]);
        setAddress(selectedAddress.line1);
        onSelect(selectedAddress);
      })
      .catch(() => {
        //
      });
  };

  const searchOptions = {
    componentRestrictions: { country: CONFIG.countryCode },
    types: ["address"],
  };

  const { touched, errors } = form;

  if (!isScriptLoaded) {
    return null;
  }

  return (
    <PlacesAutocomplete
      name={field.name}
      id={field.name}
      {...props}
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={searchOptions}
      debounce={5}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <>
          <div
            style={{ position: "relative", top: "-30px", left: "0" }} // Note that we're using a relative position for this anchor, instead of absolute like in other places.
            ref={inputRef}
          />
          <input
            className={`block border rounded w-full py-4 px-4 border-gray-400 text-gray-700 leading-tight focus:outline-none
            focus:shadow-outline focus-within:border-teal-500
            ${errors[field.name] && touched[field.name] && " border-red-500"}`}
            {...getInputProps({
              placeholder,
              autoComplete: "new-password",
              name: field.name,
            })}
            value={field.value}
            onFocus={() => executeScroll(inputRef.current as HTMLDivElement)}
            data-testid={makeTestID("delivery", "deliveryAddress", "search")}
            disabled={readonly}
          />
          <div className="autocomplete-dropdown-container shadow-lg">
            {suggestions.map(suggestion => (
              <div
                className="p-4 autocomplete-dropdown-item"
                {...getSuggestionItemProps(suggestion)}
                key={suggestion.index}
              >
                {suggestion.description}
              </div>
            ))}
          </div>
        </>
      )}
    </PlacesAutocomplete>
  );
};

export default GooglePlacesAutocomplete;
