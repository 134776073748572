import { Field as FormikField, useFormikContext } from "formik";
import React from "react";

type Props = {
  name: string;
  [x: string]: any;
};

const Field: React.FC<Props> = (props: Props) => {
  const { setFieldTouched, validateField } = useFormikContext();
  const { name } = props;

  const handleBlur = () => {
    setFieldTouched(name, true);

    validateField(name);
  };

  return <FormikField onBlur={handleBlur} {...props} />;
};

export default Field;
