import CONFIG from "@/config";
import { Address } from "@/shared/types";
import { CountryCode } from "@/libs/sawubona-sdk/types";

const getAddressComponent = (
  place: google.maps.places.PlaceResult | google.maps.GeocoderResult,
  name: string
) => {
  if (place.address_components) {
    const match = place.address_components.find(component => {
      return component.types.includes(name);
    });

    if (match) {
      return match.long_name;
    }
  }

  return null;
};

const getStructuredAddress = (
  place: google.maps.places.PlaceResult | google.maps.GeocoderResult
): Address => {
  if (CONFIG.countryCode === CountryCode.Egypt) {
    return getEGStructuredAddress(place);
  }

  return getZAStructuredAddress(place);
};

const getCommonAddress = (
  place: google.maps.places.PlaceResult | google.maps.GeocoderResult
): Address => {
  return {
    line1: getLine1(
      getAddressComponent(place, "street_number"),
      getAddressComponent(place, "route")
    ),
    line2: getAddressComponent(place, "administrative_area_level_3"),
    country: getAddressComponent(place, "country"),
    latitude: place.geometry?.location?.lat() || null,
    longitude: place.geometry?.location?.lng() || null,
    city: "",
  };
};

const getZAStructuredAddress = (
  place: google.maps.places.PlaceResult | google.maps.GeocoderResult
): Address => {
  const commonAddress = getCommonAddress(place);
  return {
    line1: commonAddress.line1,
    line2: commonAddress.line2,
    latitude: commonAddress.latitude,
    longitude: commonAddress.longitude,
    country: commonAddress.country,
    city: getAddressComponent(place, "locality"),
    suburb: getAddressComponent(place, "sublocality"),
    province: getAddressComponent(place, "administrative_area_level_1"),
    // postalCode has been updated to snake case(postal_code)because google maps fails to map it if it is in camel case
    postal_code: getAddressComponent(place, "postal_code"),
  };
};

const getEGStructuredAddress = (
  place: google.maps.places.PlaceResult | google.maps.GeocoderResult
): Address => {
  const commonAddress = getCommonAddress(place);
  return {
    line1: commonAddress.line1,
    line2: commonAddress.line2,
    latitude: commonAddress.latitude,
    longitude: commonAddress.longitude,
    country: commonAddress.country,
    city: getAddressComponent(place, "administrative_area_level_1"),
    area: getAddressComponent(place, "administrative_area_level_2"),
  };
};

const getLine1 = (streetNumber, streetName): string | null => {
  if (streetNumber && streetName) {
    return `${streetNumber} ${streetName}`;
  }

  if (streetName) {
    return streetName;
  }

  return null;
};

const getAddressFromLatLng = (lat: number, lng: number): Promise<Address> => {
  const latlng = new google.maps.LatLng(lat, lng);

  const geocoder = new google.maps.Geocoder();

  return new Promise((resolve, reject) => {
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results) {
        resolve(getStructuredAddress(results[0]));
      } else {
        reject(new Error(status));
      }
    });
  });
};

export default {
  getAddressComponent,
  getStructuredAddress,
  getAddressFromLatLng,
};
