import CardMain from "@/shared/components/CardMain";
import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import Header from "@/shared/components/Header";
import Segment from "@/shared/services/Segment";
import { Product } from "@/shared/shop/api";
import { makeTestID } from "@/shared/utils/development";
import CartSummaryMobile from "@/shop/components/CartSummaryMobile";
import CartSummaryMobileOffset from "@/shop/components/CartSummaryMobileOffset";
import CheckoutSidePanel from "@/shop/components/CheckoutSidePanel";
import {
  getInitialValues as getPromoCodeInitialValues,
  getValidationSchema as getPromoCodeValidationSchema,
} from "@/shop/components/PromoCodeField";
import ProductGrid from "@/shop/pages/ProductCataloguePage/ProductGrid";
import { useCartStore } from "@/shop/store";
import Layout from "@/signup/components/Layout";
import StepProgressBar from "@/signup/components/StepProgressBar";
import SubmitButton from "@/signup/components/SubmitButton";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import {
  useAppConfigStore,
  useSignupStore,
  useStepStore,
} from "@/signup/store";
import { SignupStep } from "@yoco/sawubona-sdk";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";

const useStore = () => ({
  setActiveStep: useStepStore(state => state.setActiveStep),
  signup: useSignupStore(state => state.signup),
  updateSignup: useSignupStore(state => state.updateSignup),
  getNextStepLink: useStepStore(state => state.getNextStepLink),
  skipServiceSelectionStep: useAppConfigStore(
    state => state.skipServiceSelectionStep
  ),
  cart: useCartStore(state => state.cart),
});

const ProductCataloguePage: React.FC<any> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { setActiveStep, updateSignup, getNextStepLink, cart } = useStore();

  useEffect(() => {
    setActiveStep(SignupStep.ProductCataloguePage);
  }, [setActiveStep]);

  const initialValues = {
    ...getPromoCodeInitialValues(cart),
  };

  const schema = yup.object({
    ...getPromoCodeValidationSchema(),
  });

  useEffect(() => {
    Segment.track("web_signup_product_catalogue_start");
  }, [location]);

  const onSubmit = async () => {
    const isSignupUpdated = await updateSignup({
      stage: SignupStep.ProductCataloguePage,
    });

    if (isSignupUpdated) {
      Segment.trackWithSignup(
        "web_signup_product_catalogue_click_checkout_button"
      );

      navigate(getNextStepLink());
    }
  };

  const onAddedProductToCart = (product: Product) => {
    Segment.track("web_signup_product_catalogue_add_to_cart", {
      product,
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {() => {
        const button = (
          <SubmitButton
            block
            testID={makeTestID("productCatalogue", "checkout")}
          >
            Checkout
          </SubmitButton>
        );

        return (
          <Form>
            <Layout>
              <CardMain wider>
                <StepProgressBar />
                <Header title="Choose a card machine" />
                <ProductGrid onAddedProductToCart={onAddedProductToCart} />
                <CartSummaryMobileOffset />
              </CardMain>
              <CheckoutSidePanel button={button} />
              <CartSummaryMobile button={button} />
            </Layout>
          </Form>
        );
      }}
    </Formik>
  );
};

export default withFetchSignup(ProductCataloguePage);
