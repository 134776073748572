/**
 * Create a test identifier, with multiple optional nested IDs.
 *
 * The suggested convention is to name test IDs in a hierarchical fashion that
 * roughly conforms to `<coarseNavigation>-<fineNavigation>-<intent>`, some examples:
 *
 * - menu-profile-email: "Email" field on the "My Account" page
 * - menu-logout: "Logout" button in the menu.
 * - menu-manageStore-products: "Products" sub-item in the "Manage
 *   My Store" menu.
 *
 * @param {String} parentTestID - Parent test ID.
 * @param {Array<String>} ...subIDs - Optional additional sub-level test IDs.
 * @return {String} New test ID. If `parentTestID` is false-y then `undefined`
 * is returned; this is a convenience to avoid having to include conditional
 * logic at the site of creating the testID.
 */
export const makeTestID = (parentTestID, ...subIDs) => {
  const normalizeID = id => id.replace(/\s/g, "_");
  return parentTestID
    ? subIDs.reduce(
        (testID, subID) => (subID ? `${testID}-${normalizeID(subID)}` : testID),
        parentTestID
      )
    : undefined;
};
