import { UserJourney } from "@/signup/types";
import { Config } from "@/config";
import { CountryCode } from "@/libs/sawubona-sdk/types";

const CONFIG: Config = {
  apiURL: "https://hello.yoco.com/sawubona",
  autoSendAppDownloadSMS: true,
  baseDomain: "yoco.com",
  blackbirdPublicKey: "pk_live_368ab9e5M5oylzBf70f4",
  blackbirdSDKUrl:
    "https://blackbird-web-sdk.netlify.app/sdk/v1/blackbird-web-sdk.js",
  branchKey: "key_live_ooPbucEQt4xTgB2cUjRsXalhrtdPhou5",
  cookieYesKey: "32bc9f0efd26da04141ec068",
  countryCode: CountryCode.SouthAfrica,
  defaultUserJourney: UserJourney.BANANA,
  gitCommitSha: process.env.REACT_APP_GIT_COMMIT_SHA,
  googlePlacesPublicKey: "AIzaSyBe-XkLMzqkcRPyuMJNCcBe6ffCiitXKxs",
  googleTagManagerContainerID: "GTM-K529XQZ",
  paymentTermsBlackbirdPublicKey: "pk_live_49e9682eD93l3Pre4554",
  portalURL: "https://portal.yoco.co.za",
  segmentPublicKey: "Xkw7zDo50FtIslGHoOtcbZ0kYO6QKUAb",
  sentryDSN:
    "https://faaf8adf4a6b4a99af0e69a6b68d47f2@o406491.ingest.sentry.io/5544532",
  stateDebugEnabled: false,
  vwoAccountId: "513333",
};

export default CONFIG;
