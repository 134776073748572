import { sawubona } from "@yoco/sawubona-sdk";
import { debounce } from "lodash";
import * as yup from "yup";
import { TestContext } from "yup";

const DEBOUNCE_THRESHOLD = 300; // milliseconds

const isValidEmail = (value: string): boolean => {
  const schema = yup.string().email();
  return schema.isValidSync(value);
};

const isValidBusinessName = (businessName: string): boolean => {
  // Whilst we want to be lenient with what the merchant captures as their business name, the payment processors
  // and 3rd parties have different requirements.
  // eg: If the user enters "Pick 'n Pay (Pty) Ltd.", we actually send "Pick n Pay Pty Ltd" to the payment processor.
  // The requirements below have been put in place by the Merchant Account team.
  const normalisedBusinessName = businessName
    .replace(/[^a-zA-Z0-9 ]/g, "")
    .trim();

  // The normalised business name must be > 3 chars, whilst the original business name can't be more than 150 chars.
  return normalisedBusinessName.length >= 3 && businessName.length < 150;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const validateEmailAvailable = (args: any) => {
  const validator = debounce(
    async (context: TestContext, value: any, resolve) => {
      if (value) {
        if (isValidEmail(value)) {
          try {
            const result = await sawubona.validation.validateSignupEmail(value);

            if (result.is_valid) {
              resolve(true);
            } else {
              resolve(
                context.createError({
                  message: result.error,
                })
              );
            }
          } catch (error) {
            // If we fail to hit our backend, or our backend fails to validate, we'll fail silently
            resolve(true);
          }
        } else {
          resolve(
            context.createError({
              // eslint-disable-next-line no-template-curly-in-string
              message: "The ${path} is not a valid email address.",
            })
          );
        }
      } else {
        resolve(
          context.createError({
            // eslint-disable-next-line no-template-curly-in-string
            message: "The ${path} field is required.",
          })
        );
      }
    },
    DEBOUNCE_THRESHOLD
  );

  return (yup as any)
    .string()
    .test(
      "is-email-available",
      args,
      function test(this: TestContext, value: any) {
        return new Promise(resolve => validator(this, value, resolve));
      }
    );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const validatePassword = (args: any) => {
  const validator = debounce(
    async (context: TestContext, value: any, resolve) => {
      if (value) {
        try {
          const result = await sawubona.validation.validateSignupPassword(
            value
          );
          if (result.is_valid) {
            resolve(true);
          } else {
            resolve(
              context.createError({
                message: result.error,
              })
            );
          }
        } catch (error) {
          // If we fail to hit our backend, or our backend fails to validate with core, we'll fail silently
          // and allow the customer to continue ahead with the signup process.
          resolve(true);
        }
      } else {
        resolve(
          context.createError({
            // eslint-disable-next-line no-template-curly-in-string
            message: "The ${path} field is required.",
          })
        );
      }
    },
    DEBOUNCE_THRESHOLD
  );

  return (yup as any)
    .string()
    .test(
      "is-password-valid",
      args,
      function test(this: TestContext, value: any) {
        return new Promise(resolve => validator(this, value, resolve));
      }
    );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const validateSerialNumberAvailable = (args: any) => {
  const validator = debounce(
    async (context: TestContext, value: any, resolve) => {
      if (value) {
        try {
          const result = await sawubona.validation.validateSerialNumber(value);

          if (result.is_valid) {
            resolve(true);
          } else {
            resolve(
              context.createError({
                message: result.error,
              })
            );
          }
        } catch (error) {
          resolve(
            context.createError({
              message:
                "We are experiencing a temporary issue verifying your serial number.",
            })
          );
        }
      } else {
        resolve(
          context.createError({
            message: "The serial number field is required.",
          })
        );
      }
    },
    DEBOUNCE_THRESHOLD
  );

  return (yup as any)
    .string()
    .test(
      "is-serial-number-available",
      args,
      function test(this: TestContext, value: any) {
        return new Promise(resolve => validator(this, value, resolve));
      }
    );
};

const validateOrderNumber = (args: any) => {
  const validator = debounce(
    async (context: TestContext, value: any, resolve) => {
      if (value) {
        try {
          const result = await sawubona.validation.validateOrderNumber(value);
          if (result.is_valid) {
            resolve(true);
          } else {
            resolve(
              context.createError({
                message: result.error,
              })
            );
          }
        } catch (error) {
          resolve(true);
        }
      } else {
        resolve(
          context.createError({
            message: "The order number field is required",
          })
        );
      }
    },
    DEBOUNCE_THRESHOLD
  );

  return (yup as any)
    .string()
    .test(
      "is-order-number-valid",
      args,
      function test(this: TestContext, value: any) {
        return new Promise(resolve => validator(this, value, resolve));
      }
    );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const validateBusinessName = (args: any) => {
  const validator = debounce(
    async (context: TestContext, value: any, resolve) => {
      if (value) {
        if (isValidBusinessName(value)) {
          try {
            const result = await sawubona.validation.validateBusinessName(
              value
            );

            if (result.is_valid) {
              resolve(true);
            } else {
              resolve(
                context.createError({
                  message: result.error,
                })
              );
            }
          } catch (error) {
            // If we fail to hit our backend, or our backend fails to validate, we'll fail silently
            resolve(true);
          }
        } else {
          resolve(
            context.createError({
              message: "The business name is invalid.",
            })
          );
        }
      } else {
        resolve(
          context.createError({
            message: "The business name field is required",
          })
        );
      }
    },
    DEBOUNCE_THRESHOLD
  );

  return (yup as any)
    .string()
    .test(
      "is-business-name-valid",
      args,
      function test(this: TestContext, value: any) {
        return new Promise(resolve => validator(this, value, resolve));
      }
    );
};

export default {
  isValidEmail,
  isValidBusinessName,
  validateEmailAvailable,
  validatePassword,
  validateSerialNumberAvailable,
  validateOrderNumber,
  validateBusinessName,
};
