import { StateChangeEventListenerCallback } from "@/libs/feature-flags/types";
import React, { createContext, useEffect, useMemo, useState } from "react";
import Feature from "./Feature";
import FeatureFlagManager from "./FeatureFlagManager";

const FeatureFlagsContext = createContext<FeatureFlagManager | undefined>(
  undefined
);

type FeatureFlagProviderProps = {
  children?: (features: FeatureFlagManager) => React.ReactNode;
  features?: Feature[] | string[];
  onStateChange?: StateChangeEventListenerCallback;
};

export const FeatureFlagProvider: React.FC<FeatureFlagProviderProps> = ({
  children,
  features,
  onStateChange,
}: FeatureFlagProviderProps) => {
  const manager = useMemo(() => new FeatureFlagManager(features), [features]);

  if (onStateChange) {
    manager.subscribeToStateChange(onStateChange);
  }

  manager.restoreFromCookie().toggleFromQueryString().saveToCookie();

  return (
    <FeatureFlagsContext.Provider value={manager}>
      {children && children(manager)}
    </FeatureFlagsContext.Provider>
  );
};

type FeatureFlagProps = {
  children: React.ReactNode;
  name: string;
  disabled?: boolean;
};

export const FeatureFlag: React.FC<FeatureFlagProps> = ({
  children,
  name,
  disabled = false,
}: FeatureFlagProps) => {
  const isFeatureEnabled = useFeatureFlag(name);

  if (isFeatureEnabled === !disabled) {
    return <>{children}</>;
  }

  return null;
};

export const useFeatureFlags = (): FeatureFlagManager => {
  const context = React.useContext(FeatureFlagsContext);

  if (context === undefined) {
    throw new Error(
      "useFeatureFlags must be used within a FeatureFlagProvider"
    );
  }
  return context;
};

export const useFeatureFlag = (name: string) => {
  const features = useFeatureFlags();
  const [isFeatureEnabled, setFeatureState] = useState<boolean>(
    features.isEnabled(name)
  );

  useEffect(() => {
    const listener = (feature: Feature, state: boolean) => {
      if (feature.name.toLowerCase() === name.toLowerCase()) {
        setFeatureState(state);
      }
    };

    features.subscribeToStateChange(listener);

    return () => {
      features.unsubscribeFromStateChange(listener);
    };
  });

  return isFeatureEnabled;
};
