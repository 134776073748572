import React from "react";
import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import SubmitButton from "@/shared/components/form/SubmitButton";
import { PaymentMethod } from "@/shared/shop/api";
import CartSummaryMobile from "@/shop/components/CartSummaryMobile";
import { useCartStore } from "@/shop/store";
import { PaymentMethodProps } from "@/shop/types";

const useStore = () => ({
  expressCardOnDeliveryPaymentIntent: useCartStore(
    state => state.expressCardOnDeliveryPaymentIntent
  ),
});

const CardOnDeliveryPaymentMethod: React.FC<PaymentMethodProps> = ({
  onSuccess,
}: PaymentMethodProps) => {
  const { expressCardOnDeliveryPaymentIntent } = useStore();

  const onSubmit = async () => {
    const isSuccessful = await expressCardOnDeliveryPaymentIntent();

    if (isSuccessful) {
      if (onSuccess) {
        onSuccess(PaymentMethod.CARD_ON_DELIVERY);
      }
    }
  };

  return (
    <Formik onSubmit={onSubmit}>
      {() => {
        const button = <SubmitButton>Pay on delivery</SubmitButton>;
        return (
          <Form>
            <div className="hidden md:block">{button}</div>
            <CartSummaryMobile isCartEditable={false} button={button} />
          </Form>
        );
      }}
    </Formik>
  );
};

export default CardOnDeliveryPaymentMethod;
