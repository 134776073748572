import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import CardMain from "@/shared/components/CardMain";
import Header from "@/shared/components/Header";
import { PaymentMethod } from "@/shared/shop/api";
import CartSummaryMobileOffset from "@/shop/components/CartSummaryMobileOffset";
import CheckoutSidePanel from "@/shop/components/CheckoutSidePanel";
import Layout from "@/shop/components/Layout";
import StepProgressBar from "@/shop/components/StepProgressBar";
import withFetchCart from "@/shop/middleware/withFetchCart";
import withRedirectToCartPageIfCartEmpty from "@/shop/middleware/withRedirectToCartPageIfCartEmpty";
import DynamicPaymentMethod from "@/shop/pages/PaymentPage/DynamicPaymentMethod";
import { useCartStore } from "@/shop/store";
import { ShopRoute } from "@/shop/types";

const useStore = () => ({
  cart: useCartStore(state => state.cart),
  intendedPaymentMethod: useCartStore(state => state.intendedPaymentMethod),
  availablePaymentMethods: useCartStore(
    state => state.cart?.available_payment_methods
  ),
  setIntendedPaymentMethod: useCartStore(
    state => state.setIntendedPaymentMethod
  ),
});

const PaymentPage: React.FC<any> = () => {
  const navigate = useNavigate();
  const { availablePaymentMethods, cart, intendedPaymentMethod } = useStore();
  const [loaderMessage, setLoaderMessage] = useState<string>("");

  useEffect(() => {
    if (!availablePaymentMethods?.includes(PaymentMethod.CARD_ON_DELIVERY)) {
      setLoaderMessage("Please wait while you are redirected to make payment");
    }
  }, [availablePaymentMethods, loaderMessage]);

  const onPaymentSuccess = (paymentMethod: PaymentMethod, isPaid?: boolean) => {
    // The CompletePage will by default look at the payment state on the cart.  For certain payment methods which do
    // as async callback to us, we don't know that the order is paid for by the time the customer sees this page.
    // We therefore allow for an "isPaid" query string param to be passed. This only affects what the customer sees
    // on this page, not the actual payment state of the order.
    let url = formatRoute(ShopRoute.CompletePage, { id: cart?.id });
    if (isPaid !== undefined) {
      url += `?isPaid=${isPaid ? "true" : "false"}`;
    }

    navigate(url);
  };

  return (
    <div className="text-center">
      <Layout>
        <CardMain>
          <StepProgressBar number={3} />
          <Header title="Payment" description={loaderMessage} />
          <DynamicPaymentMethod
            name={intendedPaymentMethod}
            onSuccess={onPaymentSuccess}
          />
          <CartSummaryMobileOffset />
        </CardMain>
        <CheckoutSidePanel isCartEditable={false} />
      </Layout>
    </div>
  );
};

export default withFetchCart(withRedirectToCartPageIfCartEmpty(PaymentPage));
