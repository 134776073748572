import React from "react";
import "./SignupIcons.scss";

const SignupIcons: React.FC<any> = () => {
  return (
    <div className="block w-full ">
      <div className="flex flex-row w-full justify-center pb-3">
        <div className="p-icon bg-yoco-green text-white p-active">1</div>
        <div className="p-line" />
        <div className="p-icon text-gray-500">2</div>
        <div className="p-line" />
        <div className="p-icon text-gray-500">3</div>
      </div>
      <div className="flex flex-row w-full justify-between">
        <div
          className="w-1/3 text-center text-yoco-green"
          style={{ marginLeft: -10 }}
        >
          Sign up
        </div>
        <div className="w-1/3 text-center text-gray-500">Set up</div>
        <div
          className="w-1/3 text-center text-gray-500"
          style={{ marginRight: -10 }}
        >
          Get paid
        </div>
      </div>
    </div>
  );
};

export default SignupIcons;
