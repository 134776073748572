import ErrorMessage from "@/shared/components/form/ErrorMessage";
import Label from "@/shared/components/form/Label";
import { executeScroll } from "@/shared/utils/helpers/general";
import { connect, FieldInputProps, FormikProps } from "formik";
import React, { useRef, useState } from "react";

type Props = {
  form: FormikProps<any>;
  field: FieldInputProps<any>;
  label?: string;
  placeholder?: string;
  [x: string]: any;
};

const PasswordInput: React.FC<Props> = ({
  form: { touched, errors },
  field,
  label,
  placeholder,
  ...props
}: Props) => {
  const inputRef = useRef<HTMLDivElement>(null);

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      {label && <Label>{label}</Label>}
      <div style={{ position: "relative" }}>
        <div
          style={{ position: "absolute", top: "-30px", left: "0" }}
          ref={inputRef}
        />
        <span
          style={{
            position: "absolute",
            left: 17,
            top: 7,
            fontSize: "10px",
            fontWeight: 500,
            color: "#b9bcc2",
          }}
        >
          {field.value !== "" && placeholder}
        </span>
        <input
          type={showPassword ? "text" : "password"}
          className={`block placeholder-gray-500 border rounded w-full mb-0 px-4 border-gray-400 text-gray-900  leading-tight focus:outline-none focus:shadow-outline focus-within:border-teal-500
          ${field.value !== "" && placeholder ? "pt-6 pb-2" : "py-4"}
          ${errors[field.name] && touched[field.name] && " border-red-500"}
          `}
          placeholder={placeholder}
          autoComplete="new-password"
          onFocus={() => executeScroll(inputRef.current as HTMLDivElement)}
          data-lpignore="true"
          {...field}
          {...props}
        />
        <button
          type="button"
          style={{ position: "absolute", right: 8, top: 15 }}
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? "Hide" : "Show"}
        </button>
        {errors[field.name] && touched[field.name] && (
          <ErrorMessage>{errors[field.name] as string}</ErrorMessage>
        )}
      </div>
    </>
  );
};

export default connect(PasswordInput);
