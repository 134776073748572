import { useCartStore } from "@/shop/store";
import React from "react";

const useStore = () => ({
  cart: useCartStore(state => state.cart),
});

const InstalmentPlan: React.FC<any> = () => {
  const { cart } = useStore();
  const proposal = cart?.instalment_plan_proposal;
  const deposit = proposal?.instalments[0];
  const instalments = proposal?.instalments.slice(1);
  return (
    <div className="mb-6">
      <h3 className="text-base font-bold mb-2">
        Your card will be charged on the following payment dates
      </h3>

      <div className="mb-2">
        <ol className="list-decimal pl-5">
          <li>
            <div className="flex flex-row items-stretch">
              <span className="flex-1">Today</span>
              <span className="text-right">{deposit?.amount_formatted}</span>
            </div>
          </li>
          {instalments?.map(instalment => (
            <li key={instalment.number}>
              <div className="flex flex-row items-stretch">
                <span className="flex-1">
                  {instalment.payment_date.toString()}
                </span>
                <span className="text-right">
                  {instalment.amount_formatted}
                </span>
              </div>
            </li>
          ))}
        </ol>
        <div className="flex flex-row items-stretch font-bold">
          <span className="flex-1">Total due:</span>
          <span className="text-right">
            {proposal?.amount_with_service_fee_formatted}
          </span>
        </div>
      </div>

      <p className="text-sm text-gray-600 italic">
        Please note: Our instalment payment option includes a service fee of{" "}
        {proposal?.service_fee_formatted}.
      </p>
    </div>
  );
};

export default InstalmentPlan;
