import {
  useAppConfigStore,
  useSignupStore,
  useStepStore,
} from "@/signup/store";
import { SignupRoute } from "@/signup/types";
import { SignupStatus, SignupStep } from "@yoco/sawubona-sdk";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { validate as isValidUUID } from "uuid";

const useStore = () => ({
  signup: useSignupStore(state => state.signup),
  getSignup: useSignupStore(state => state.getSignup),
  fetchSignup: useSignupStore(state => state.fetchSignup),
  refreshSteps: useStepStore(state => state.refreshSteps),
  userJourney: useAppConfigStore(state => state.userJourney),
  skipServiceSelectionStep: useAppConfigStore(
    state => state.skipServiceSelectionStep
  ),
  skipProductCatalogueStep: useAppConfigStore(
    state => state.skipProductCatalogueStep
  ),
});

export default WrappedComponent => {
  const FetchSignup: React.FC<any> = props => {
    const navigate = useNavigate();
    const { id } = useParams();
    const {
      signup,
      getSignup,
      fetchSignup,
      refreshSteps,
      userJourney,
      skipServiceSelectionStep,
      skipProductCatalogueStep,
    }: any = useStore();

    useEffect(() => {
      refreshSteps();
    }, [
      signup,
      userJourney,
      skipServiceSelectionStep,
      skipProductCatalogueStep,
      refreshSteps,
    ]);

    useEffect(() => {
      if (id && isValidUUID(id)) {
        fetchSignup(id)
          .then(() => {
            const { activeStep } = useStepStore.getState();
            const { status } = getSignup();
            const isOnCompleteStep = activeStep === SignupStep.CompletePage;

            if (status === SignupStatus.COMPLETE && !isOnCompleteStep) {
              navigate(
                formatRoute(SignupRoute.CompletePage, {
                  id,
                })
              );
            } else if (status === SignupStatus.CANCELLED) {
              navigate(SignupRoute.HomePage);
            }
          })
          .catch(() => {
            navigate(SignupRoute.HomePage);
          });
      } else {
        navigate(SignupRoute.HomePage);
      }
    }, [id, navigate, fetchSignup, getSignup]);

    return <WrappedComponent {...props} />;
  };

  return FetchSignup;
};
