import CONFIG from "@/config";
import ScrollToTop from "@/shared/components/ScrollToTop";
import Segment from "@/shared/services/Segment";
import { getBool, getString } from "@/shared/utils/appConfig";
import { useAppConfigStore as useShopAppConfigStore } from "@/shop/store";
import { useAppConfigStore, useSignupStore } from "@/signup/store";
import { UserJourney } from "@/signup/types";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";

type Props = {
  children: React.ReactNode;
};

const useStore = () => ({
  signup: useSignupStore(state => state.signup),
  addExperiment: useSignupStore(state => state.addExperiment),
  shouldSyncExperiments: useSignupStore(state => state.shouldSyncExperiments),
  syncExperiments: useSignupStore(state => state.syncExperiments),
  setIsInApp: useAppConfigStore(state => state.setIsInApp),
  setStorefront: useShopAppConfigStore(state => state.setStorefront),
});

const Page: React.FC<Props> = ({ children }: Props) => {
  const location = useLocation();
  const {
    signup,
    addExperiment,
    shouldSyncExperiments,
    syncExperiments,
    setIsInApp,
    setStorefront,
  } = useStore();

  useEffect(() => {
    // The root path / redirects to /signup; so in order to avoid a double pageview, we ignore the initial
    // load
    if (location.pathname !== "/") {
      Segment.trackPageView();
    }
  }, [location]);

  useEffect(() => {
    // The following appConfig store values are configurable from the query string and/or
    // window.yc.config on any page load.
    const inApp = getString("in-app");

    if (inApp) {
      setIsInApp(getBool("in-app"));
    }

    // For some user journeys we want to use a specific storefront
    const userJourney = signup?.user_journey || getString("userJourney");

    const storefrontFromUserJourney =
      userJourney === UserJourney.PAYFORDATA ? "payfordata" : null;

    const storefront = getString("storefront") || storefrontFromUserJourney;

    if (storefront) {
      setStorefront(storefront);
    }
  }, [location, setIsInApp, setStorefront, signup?.user_journey]);

  useEffect(() => {
    // The following VWO code is taken directly from https://help.vwo.com/hc/en-us/articles/360021118454-Integrating-VWO-with-Mixpanel-Old-Version-
    // The "onVariationApplied" is undocumented but can be used to build a list of experiments & variations which are
    // applied to an identity.

    /* eslint-disable no-underscore-dangle */

    window.VWO = window.VWO || [];
    window._vwo_exp = window._vwo_exp || [];

    window.VWO.push([
      "onVariationApplied",
      data => {
        if (!data) {
          return;
        }

        const [, experimentID, variationID] = data;

        if (
          typeof window._vwo_exp[experimentID].comb_n[variationID] !==
            "undefined" &&
          ["VISUAL_AB", "VISUAL", "SPLIT_URL", "SURVEY"].indexOf(
            // eslint-disable-next-line no-underscore-dangle
            window._vwo_exp[experimentID].type
          ) > -1
        ) {
          addExperiment({
            key: `vwo${experimentID}`,
            platform: "vwo",
            id: experimentID,
            name: window._vwo_exp[experimentID].name,
            variation: window._vwo_exp[experimentID].comb_n[variationID],
          });
        }
      },
    ]);
    /* eslint-enable no-underscore-dangle */
  }, [addExperiment]);

  useEffect(() => {
    // In the event that we record an experiment before a signup exists in local state, we wouldn't have that
    // experiment synced to the backend.  When this happens, we set the shouldSyncExperiments flag, and as soon we
    // have a signup in state, we do the sync.
    if (signup && shouldSyncExperiments) {
      syncExperiments();
    }
  }, [signup, shouldSyncExperiments, syncExperiments]);

  return (
    <>
      <Helmet>
        <title>
          Yoco - Sign up to order your card machine or set up online card
          payments with Yoco
        </title>

        <script type="text/javascript">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${CONFIG.googleTagManagerContainerID}');`}
        </script>

        <script>
          {`
          !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
          analytics.load("${CONFIG.segmentPublicKey}");
          }}();`}
        </script>

        {CONFIG.vwoAccountId && (
          <script>
            {`
            window._vwo_code = window._vwo_code || (function(){
            var account_id=${CONFIG.vwoAccountId},
            settings_tolerance=2000,
            library_tolerance=2500,
            use_existing_jquery=false,
            is_spa=1,
            hide_element='body',
            /* DO NOT EDIT BELOW THIS LINE */
            f=false,d=document,code={use_existing_jquery:function(){return use_existing_jquery;},library_tolerance:function(){return library_tolerance;},finish:function(){if(!f){f=true;var a=d.getElementById('_vis_opt_path_hides');if(a)a.parentNode.removeChild(a);}},finished:function(){return f;},load:function(a){var b=d.createElement('script');b.src=a;b.type='text/javascript';b.innerText;b.onerror=function(){_vwo_code.finish();};d.getElementsByTagName('head')[0].appendChild(b);},init:function(){
            window.settings_timer=setTimeout('_vwo_code.finish()',settings_tolerance);var a=d.createElement('style'),b=hide_element?hide_element+'{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}':'',h=d.getElementsByTagName('head')[0];a.setAttribute('id','_vis_opt_path_hides');a.setAttribute('type','text/css');if(a.styleSheet)a.styleSheet.cssText=b;else a.appendChild(d.createTextNode(b));h.appendChild(a);this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+(+is_spa)+'&r='+Math.random());return settings_timer; }};window._vwo_settings_timer = code.init(); return code; }());
          `}
          </script>
        )}

        {CONFIG.cookieYesKey && (
          <script
            key="cookie-yes-script"
            id="cookieyes"
            type="text/javascript"
            src={`https://cdn-cookieyes.com/client_data/${CONFIG.cookieYesKey}/script.js`}
          />
        )}

        {CONFIG.branchKey && (
          <script>
            {`
            (function (b, r, a, n, c, h, _, s, d, k) {
              if (!b[n] || !b[n]._q) {
                for (; s < _.length; ) c(h, _[s++]);
                d = r.createElement(a);
                d.async = 1;
                d.src = "https://cdn.branch.io/branch-latest.min.js";
                k = r.getElementsByTagName(a)[0];
                k.parentNode.insertBefore(d, k);
                b[n] = h;
              }
            })(
              window,
              document,
              "script",
              "branch",
              function (b, r) {
                b[r] = function () {
                  b._q.push([r, arguments]);
                };
              },
              { _q: [], _v: 1 },
              "addListener applyCode banner closeBanner creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode".split(
                " "
              ),
              0
            );
            branch.init("${CONFIG.branchKey}");
            `}
          </script>
        )}
      </Helmet>
      <ToastContainer />
      <ScrollToTop />
      {children}
    </>
  );
};

export default Page;
