import Feature from "./Feature";

class FeatureState {
  public feature: Feature;

  public enabled: boolean;

  constructor(feature: Feature, enabled: boolean) {
    this.feature = feature;
    this.enabled = enabled;
  }
}

export default FeatureState;
