import CONFIG from "@/config";
import { ReferralCodeBusiness } from "@/libs/sawubona-sdk/types";
import SignupService from "@/signup/services/SignupService";
import { useAppConfigStore, useStepStore } from "@/signup/store";
import { UserJourney } from "@/signup/types";
import { Feature } from "@yoco/feature-flags";
import { Experiment, Platform, sawubona, Signup } from "@yoco/sawubona-sdk";
import Cookies from "js-cookie";
import { isMobile } from "react-device-detect";
import create from "zustand";
import { devtools, subscribeWithSelector } from "zustand/middleware";

export interface SignupState {
  signup: Signup | null;
  experiments: Experiment[];
  yocoAuthToken: string | null;
  referralDetails: ReferralCodeBusiness | null;
  shouldSyncExperiments: boolean;
  reset: () => void;
  getSignup: () => Signup;
  setSignup: (signup: Signup | null, action?: string) => void;
  fetchSignup: (id: string) => Promise<boolean>;
  refreshSignup: () => Promise<boolean>;
  createSignup: (payload: Record<string, unknown>) => Promise<boolean>;
  updateSignup: (payload: Record<string, unknown>) => Promise<boolean>;
  finaliseSignup: (
    payload?: Record<string, unknown> | null
  ) => Promise<boolean>;
  setSignupFeatureFlag: (feature: Feature, state: boolean) => Promise<boolean>;
  enableFeatureFlag: (feature: Feature) => Promise<boolean>;
  disableFeatureFlag: (feature: Feature) => Promise<boolean>;
  addExperiment: (experiment: Experiment) => Promise<boolean>;
  syncExperiments: () => Promise<boolean>;
  setYocoAuthToken: (yocoAuthToken: string) => void;
  pollUntilSignupHasAccountCreated: () => Promise<Signup>;
  setReferralDetails: (code: string | null) => Promise<void>;
  getPlatform: () => Platform;
}

export const useSignupStore = create<SignupState>()(
  devtools(
    subscribeWithSelector((set, get) => ({
      signup: null,
      experiments: [],
      referralDetails: null,
      yocoAuthToken: null,
      shouldSyncExperiments: false,
      reset: () => {
        get().setSignup(null, "reset");
      },
      getSignup: () => {
        // getSignup() will return the existing signup from the store.  If the store does not contain a signup, we
        // throw an exception here.  This differs to get().signup in that if one accesses the signup in the store in
        // a direct manner, it can potentially be null.
        // This is not to be confused with fetchSignup() which will retrieve the full signup state from the API backend
        // and save it to the local store.
        const { signup } = get();

        if (!signup) {
          throw new Error("The signup is not set.");
        }

        return signup;
      },
      setSignup: (signup, action) => {
        set({ signup }, false, action || "setSignup");

        useAppConfigStore.getState().setUserJourneyFromSignup(signup);
        useStepStore.getState().refreshSteps();
      },
      fetchSignup: async id => {
        // This will retrieve the signup state from the API backend and save it to local state.  To access the signup from
        // store, you can either call getSignup() or access the signup object directly in state.
        try {
          get().setSignup(await sawubona.signups.get(id), "fetchSignup");

          return true;
        } catch (error) {
          return false;
        }
      },
      refreshSignup: async () => {
        try {
          const signup = get().getSignup();

          return await get().fetchSignup(signup.id);
        } catch {
          return false;
        }
      },
      createSignup: async payload => {
        const wau = Cookies.get("wau");

        // eslint-disable-next-line no-param-reassign
        payload.tracking_attribution = {
          platform: get().getPlatform(),
          wau: wau || "",
        };

        try {
          set(
            { signup: await sawubona.signups.create(payload) },
            false,
            "createSignup"
          );

          return true;
        } catch (error) {
          return false;
        }
      },
      updateSignup: async payload => {
        try {
          const { id } = get().getSignup();
          get().setSignup(
            await sawubona.signups.update(id, payload),
            "updateSignup"
          );

          return true;
        } catch (error) {
          return false;
        }
      },
      finaliseSignup: async (payload = null) => {
        try {
          const { id } = get().getSignup();

          if (payload) {
            await sawubona.signups.update(id, payload);
          }

          const finalisedSignup = await sawubona.signups.finalise(id);

          get().setSignup(finalisedSignup, "finaliseSignup");

          SignupService.onFinaliseComplete(finalisedSignup);

          return true;
        } catch (error) {
          return false;
        }
      },
      setReferralDetails: async (code: string | null) => {
        if (code == null) {
          return;
        }
        try {
          const { isValid, business } =
            await sawubona.signups.getValidReferralCode(code);
          if (isValid) {
            set({ referralDetails: business }, false, "setReferralDetails");
          }
        } catch (error) {
          //
        }
      },
      setSignupFeatureFlag: async (feature, state) => {
        return state
          ? get().enableFeatureFlag(feature)
          : get().disableFeatureFlag(feature);
      },
      enableFeatureFlag: async feature => {
        try {
          const { id } = get().getSignup();
          get().setSignup(
            await sawubona.signups.enableFeatureFlag(id, feature),
            "enableFeatureFlag"
          );

          return true;
        } catch (error) {
          return false;
        }
      },
      disableFeatureFlag: async feature => {
        try {
          const { id } = get().getSignup();
          get().setSignup(
            await sawubona.signups.disableFeatureFlag(id, feature),
            "disableFeatureFlag"
          );

          return true;
        } catch (error) {
          return false;
        }
      },
      addExperiment: async experiment => {
        try {
          const { experiments } = get();

          const index = experiments.findIndex(e => e.key === experiment.key);

          if (index < 0) {
            experiments.push(experiment);

            set({ experiments }, false, "addExperiment");

            const { signup } = get();

            if (signup) {
              await get().syncExperiments();
            } else {
              // We don't yet have a signup in local state.  This could be because the customer is on the first page of
              // signup, continuing a signup or reloaded the page, and we haven't finished retrieving the signup from
              // the backend.
              set(
                { shouldSyncExperiments: true },
                false,
                "flagShouldSyncExperiments"
              );
            }
          }

          return true;
        } catch (error) {
          return false;
        }
      },
      syncExperiments: async () => {
        try {
          set(
            { shouldSyncExperiments: false },
            false,
            "flagShouldSyncExperiments"
          );

          const { experiments } = get();

          if (experiments && experiments.length > 0) {
            const { id } = get().getSignup();

            get().setSignup(
              await sawubona.signups.addExperiments(id, experiments),
              "syncExperiments"
            );
          }

          return true;
        } catch (error) {
          set(
            { shouldSyncExperiments: true },
            false,
            "flagShouldSyncExperiments"
          );

          return false;
        }
      },
      setYocoAuthToken: yocoAuthToken => {
        set({ yocoAuthToken }, false, "setYocoAuthToken");
      },
      pollUntilSignupHasAccountCreated: () => {
        // The pollUntilSignupHasAccountCreated() method will try and resolve to an instance of a signup once that signup has
        // been synced to core. We'll retry this process up to a certain amount of attempts with a slight delay between each
        // attempt.  If at the end of this, we haven't managed to obtain a signup, we'll reject the promise.

        return new Promise((resolve, reject) => {
          let retries = 0;
          const maxAttempts = 15;
          const delay = 3000;

          const onFail = (reason: string) => {
            if (retries < maxAttempts) {
              setTimeout(tryAndFetchSignup, delay);
            } else {
              reject(new Error(reason));
            }
          };

          const tryAndFetchSignup = async () => {
            retries += 1;

            const id = get().signup?.id;

            if (id) {
              try {
                const signup = await sawubona.signups.get(id);

                if (signup.is_created_in_core) {
                  get().setSignup(signup, "pollUntilSignupHasAccountCreated");

                  resolve(signup);
                } else {
                  onFail("The signup is not yet synced to core.");
                }
              } catch (error) {
                const reason =
                  typeof error === "object" &&
                  error !== null &&
                  "message" in error
                    ? String(error.message)
                    : "unknown";
                onFail(reason);
              }
            } else {
              onFail("The signup uuid is not yet set in state.");
            }
          };

          tryAndFetchSignup();
        });
      },
      getPlatform: () => {
        const { isInApp, userJourney } = useAppConfigStore.getState();

        if (userJourney === UserJourney.CINNAMON) {
          return Platform.KHUMO;
        }

        if (isInApp) {
          return Platform.APP;
        }

        return isMobile ? Platform.MOBILE_WEB : Platform.WEB;
      },
    })),
    { name: "SignupStore", enabled: CONFIG.stateDebugEnabled }
  )
);
