import * as yup from "yup";
import BaseAddressSchema from "./BaseAddressSchema";

class EgyptAddressSchema extends BaseAddressSchema {
  public static schema() {
    return {
      ...super.baseSchema(),
      area: yup.string().required().label("area"),
      description: yup.string().notRequired().label("description"),
    };
  }
}

export default EgyptAddressSchema;
