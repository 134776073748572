import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import SubmitButton from "@/shared/components/form/SubmitButton";
import Loader from "@/shared/components/Loader";
import shop, { PaymentMethod } from "@/shared/shop/api";
import { makeTestID } from "@/shared/utils/development";
import { toRoute } from "@/shared/utils/url";
import CartSummaryMobile from "@/shop/components/CartSummaryMobile";
import { useCartStore } from "@/shop/store";
import { PaymentMethodProps, ShopRoute } from "@/shop/types";
import React, { useEffect, useState } from "react";

enum InstantEFTState {
  INITIAL,
  GENERATING_PAYMENT_LINK,
  READY,
}

const useStore = () => ({
  cart: useCartStore(state => state.cart),
});

const InstantEFTPaymentMethod: React.FC<PaymentMethodProps> = ({
  onSuccess,
}: PaymentMethodProps) => {
  const { cart } = useStore();
  const [instantEFTState, setInstantEFTState] = useState<InstantEFTState>(
    InstantEFTState.INITIAL
  );
  const [instantEFTPaymentUrl, setInstantEFTPaymentUrl] = useState<any>(null);

  useEffect(() => {
    const onIFrameMessageReceived = async message => {
      // The StitchInstantEFTResultPage component is hosted within an iFrame.  When the user returns to us from Stitch,
      // we render this "page" and emit a message with the status and the payment request id.
      const event = message.data?.event;

      if (event === "RECEIVED_STITCH_INSTANT_EFT_RESULT") {
        if (message.data.status === "complete") {
          if (onSuccess) {
            onSuccess(PaymentMethod.INSTANT_EFT_STITCH, true);
          }
        } else {
          setInstantEFTState(InstantEFTState.INITIAL);
        }
      }
    };

    window.addEventListener("message", onIFrameMessageReceived);

    return () => {
      window.removeEventListener("message", onIFrameMessageReceived);
    };
  }, [onSuccess]);

  const onSubmit = async () => {
    setInstantEFTState(InstantEFTState.GENERATING_PAYMENT_LINK);

    const returnUrl = toRoute(ShopRoute.StitchInstantEFTResultPage);

    try {
      const paymentLink = await shop.carts.generateStitchInstantEFTPaymentLink(
        cart?.id || "",
        returnUrl
      );

      setInstantEFTPaymentUrl(paymentLink);
      setInstantEFTState(InstantEFTState.READY);
    } catch (error) {
      setInstantEFTState(InstantEFTState.INITIAL);
    }
  };

  return (
    <>
      <Formik onSubmit={onSubmit}>
        {() => {
          const button = (
            <SubmitButton testID={makeTestID("payment", "payByInstantEft")}>
              Pay by EFT with Stitch
            </SubmitButton>
          );

          return (
            <Form>
              {instantEFTState === InstantEFTState.INITIAL && (
                <>
                  <span className="block text-base">
                    Stitch is a secure payment solution that facilitates instant
                    EFT payments from all major South African banks
                  </span>
                  <br />
                  <p className="block text-base font-bold pb-1">
                    How does it work?
                  </p>
                  <p className="block text-base">
                    1. Click ‘Pay by EFT with Stitch’ to confirm your order.
                    <br />
                    2. Select your bank and login securely using your internet
                    banking details.
                    <br />
                    3. Select an account to pay from.
                    <br />
                    4. Your bank may send you an OTP or require you to
                    authenticate payment within the banking app.
                    <br />
                  </p>

                  <div className="hidden md:block mt-8">{button}</div>
                  <CartSummaryMobile isCartEditable={false} button={button} />
                </>
              )}
            </Form>
          );
        }}
      </Formik>

      {instantEFTState === InstantEFTState.GENERATING_PAYMENT_LINK && (
        <Loader />
      )}

      {instantEFTState === InstantEFTState.READY && (
        <iframe
          title="Stitch InstantEFT"
          src={instantEFTPaymentUrl}
          scrolling="no"
          style={{
            overflow: "hidden",
            width: "100%",
            height: "600px",
          }}
        />
      )}
    </>
  );
};

export default InstantEFTPaymentMethod;
