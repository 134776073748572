import { LabelValuePair } from "../types";
import BaseApi from "./BaseApi";

class BusinessTypeApi extends BaseApi {
  list(): Promise<LabelValuePair[]> {
    return this.http.get("/signup/api/v1/business_types/");
  }
}

export default BusinessTypeApi;
