import Card from "@/shared/components/Card";
import TermsAndConditionsLink from "@/shared/components/TermsAndConditionsLink";
import Text from "@/shared/components/Text";
import { PaymentMethod } from "@/shared/shop/api";
import { makeTestID } from "@/shared/utils/development";
import { useCartStore } from "@/shop/store";
import { isEmpty } from "lodash";
import React from "react";
import CartSummary from "./CartSummary";

const useStore = () => ({
  cart: useCartStore(state => state.cart),
  intendedPaymentMethod: useCartStore(state => state.intendedPaymentMethod),
});

type Props = {
  isCartEditable?: boolean;
  button?: any;
};

const CheckoutSidePanel: React.FC<Props> = ({
  isCartEditable = true,
  button,
}: Props) => {
  const { cart, intendedPaymentMethod } = useStore();

  if (!cart || isEmpty(cart.items)) {
    return null;
  }

  return (
    <div>
      <Card className="sticky top-0 hidden md:block">
        <CartSummary isCartEditable={isCartEditable} />

        <div className="flex justify-between flex-row items-start justify-center">
          <b>Total</b>
          <Text size="h3" testID={makeTestID("productCatalogue", "amountDue")}>
            {intendedPaymentMethod === PaymentMethod.PAYMENT_TERMS
              ? cart.instalment_plan_proposal.amount_with_service_fee_formatted
              : cart.balance_due_formatted}
          </Text>
        </div>
        {button && <div className="my-2">{button}</div>}
        <TermsAndConditionsLink />
      </Card>
    </div>
  );
};

export default CheckoutSidePanel;
