import Field from "@/shared/components/form/Field";
import Group from "@/shared/components/form/Group";
import Input from "@/shared/components/form/Input";
import { Cart } from "@/shared/shop/api";
import { makeTestID } from "@/shared/utils/development";
import { useCartStore } from "@/shop/store";
import { useFormikContext } from "formik";
import { debounce } from "lodash";
import React from "react";
import * as yup from "yup";
import { ObjectShape } from "yup/lib/object";

const useStore = () => ({
  cart: useCartStore(state => state.cart),
  updateCart: useCartStore(state => state.updateCart),
});

type Props = {
  label?: string;
};

const PromoCodeField: React.FC<Props> = ({ label }: Props) => {
  const { cart, updateCart } = useStore();
  const formik = useFormikContext();
  const { values } = formik as any;

  const isPromoCodeApplied = !!cart?.promo_code;

  const onClickPromoCode = async () => {
    handleClearOrApplyPromoCode(String(values?.promo_code_str || ""));
  };

  const handleClearOrApplyPromoCode = debounce((promoCode: string) => {
    return isPromoCodeApplied
      ? handleClearPromoCode()
      : handleApplyPromoCode(promoCode);
  }, 100);

  const handleApplyPromoCode = (promoCode: string) => {
    formik.validateField("promo_code_str");
    formik.setFieldTouched("promo_code_str", true);

    if (promoCode) {
      // noinspection JSIgnoredPromiseFromCall
      updateCart({ promo_code_str: promoCode }).then(res => {
        if (!res.success && res.message) {
          formik.setErrors({ promo_code_str: res.message });
        }
      });
    }
  };

  const handleClearPromoCode = async () => {
    const isPromoCodeCleared = await updateCart({ promo_code_str: "" });

    if (isPromoCodeCleared.success) {
      await formik.setFieldValue("promo_code_str", "");
      formik.setFieldTouched("promo_code_str", true);
    }
  };

  const promoBtnBackground = promoCode => {
    if (promoCode === "") {
      return "bg-black";
    }
    if (isPromoCodeApplied) {
      return "bg-red-600 hover:bg-red-700";
    }
    return "bg-black";
  };

  return (
    <Group name="promo_code_str" label={label}>
      <div className="flex flex-row">
        <div className="w-3/4">
          <Field
            name="promo_code_str"
            placeholder="Promo code"
            component={Input}
            disabled={isPromoCodeApplied}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleClearOrApplyPromoCode(e.target.value);
            }}
            data-testid={makeTestID("payment", "promoCode")}
          />
        </div>
        <div className="w-1/3 ml-3">
          <button
            className={`text-white font-bold pt-4 pb-4 rounded w-full focus:outline-none focus:shadow-outline
                      ${promoBtnBackground(values?.promo_code_str)}`}
            type="button"
            onClick={() => onClickPromoCode()}
            disabled={values?.promo_code_str === ""}
          >
            <span className="font-button text-base">
              {isPromoCodeApplied ? "Remove" : "Apply"}
            </span>
          </button>
        </div>
      </div>
    </Group>
  );
};

export default PromoCodeField;

export const getValidationSchema = (): ObjectShape => {
  return {
    promo_code_str: yup.string(),
  };
};

export const getInitialValues = (
  cart?: Cart | null
): { [key: string]: any } => {
  return {
    promo_code_str: cart?.promo_code?.code || "",
  };
};
