import { UserJourney } from "@/signup/types";
import { Config } from "@/config";
import { CountryCode } from "@/libs/sawubona-sdk/types";

const CONFIG: Config = {
  apiURL: "http://hello.yoco.lvh.me:3030/sawubona",
  autoSendAppDownloadSMS: false,
  baseDomain: "yoco.lvh.me",
  blackbirdPublicKey: "pk_test_8f133808J4bGznOb4c45",
  blackbirdSDKUrl:
    "https://blackbird-web-sdk-camel.netlify.app/sdk/v1/blackbird-web-sdk.js",
  branchKey: "key_test_geKnqdsLE8tRiAWmNaS8zohlwucNbiKh",
  cookieYesKey: "",
  countryCode: CountryCode.SouthAfrica,
  defaultUserJourney: UserJourney.BANANA,
  gitCommitSha: process.env.REACT_APP_GIT_COMMIT_SHA,
  googlePlacesPublicKey: "AIzaSyBe-XkLMzqkcRPyuMJNCcBe6ffCiitXKxs",
  googleTagManagerContainerID: "GTM-PZQ88R2",
  paymentTermsBlackbirdPublicKey: "pk_test_123abc456def789gff",
  portalURL: "http://hello.yoco.lvh.me:3000",
  segmentPublicKey: "iUfhZtaL8Kh4LrXJGoV3CHUweauX7xM6",
  sentryDSN: "",
  stateDebugEnabled: true,
  vwoAccountId: "513333",
};

export default CONFIG;
