import React from "react";

type Props = {
  size?: "h2" | "h3" | "h4" | "h5" | "base" | "description" | "subLabel";
  red?: boolean;
  className?: string;
  style?: any;
  children: React.ReactNode;
  testID?: string;
};

const Text: React.FC<Props> = ({
  size,
  red,
  className,
  style,
  children,
  testID,
}: Props) => {
  const redColor = red ? "text-yoco-red" : "";

  const typeSize = variant => {
    switch (variant) {
      case "h2":
        return "block text-2xl font-bold";

      case "h3":
        return "block text-lg font-bold";

      case "h4":
        return "block text-base font-medium";

      case "h5":
        return "block text-md font-medium";

      case "base":
        return "block text-base";

      case "description":
        return "block text-base text-gray-800";

      case "subLabel":
        return "block";

      default:
        return "block text-sm text-gray-800";
    }
  };

  return (
    <p
      className={`${typeSize(size)} ${redColor} ${className}`}
      style={{ ...style }}
      data-testid={testID}
    >
      {children}
    </p>
  );
};

export default Text;
