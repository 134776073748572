import * as yup from "yup";

class BaseAddressSchema {
  public static baseSchema() {
    return {
      address_line1: yup
        .string()
        .matches(
          /^[a-zA-Z0-9 \-._()']+$/g,
          "Please enter a valid number and street address."
        )
        .required()
        .trim()
        .min(
          2,
          "The number and street address must be a minimum of 2 characters."
        )
        .label("address line 1"),
      address_line2: yup.mixed().notRequired().label("apartment / building"),
      city: yup
        .string()
        .matches(/^[a-zA-Z0-9 \-._()']+$/g, "Please enter a valid city.")
        .required()
        .trim()
        .min(2)
        .label("city"),
      country: yup.string().required().label("country"),
    };
  }
}

export default BaseAddressSchema;
