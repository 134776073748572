import HttpClient from "../HttpClient";

abstract class BaseApi {
  protected http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }
}

export default BaseApi;
