import CONFIG from "@/config";
import Segment from "@/shared/services/Segment";
import { useAppConfigStore } from "@/signup/store";
import { Signup } from "@yoco/sawubona-sdk";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { UserJourney } from "../types";

const onFinaliseComplete = (signup: Signup) => {
  const khumoPurchaseText =
    "Welcome to Yoco! You're part of a 200K+ merchant community. Download the Yoco app to track your delivery and run your business from anywhere {{ link }}";
  const khumoSignupText =
    "Welcome to Yoco! You're part of a 200K+ merchant community. Download the Yoco app and run your business from anywhere {{ link }}";
  try {
    Segment.trackWithSignup("web_signup_complete");
    Segment.trackWithSignup("Account Created", "milestones");

    if (signup.cart && signup.cart.is_order) {
      Segment.track("web_signup_order_placed", signup.cart);
    }

    const { isInApp, userJourney } = useAppConfigStore.getState();

    const isOnKhumo = userJourney === UserJourney.CINNAMON;
    const containsKhumoOrder = signup?.cart?.items.find(
      item => item.product.sku === "KOZP5"
    );

    if (signup.mobile_number && CONFIG.autoSendAppDownloadSMS) {
      if (isOnKhumo) {
        sendAppDownloadSMS(signup.mobile_number, khumoSignupText);
      } else if (containsKhumoOrder && !isInApp) {
        sendAppDownloadSMS(signup.mobile_number, khumoPurchaseText);
      } else if (!isInApp) {
        sendAppDownloadSMS(signup.mobile_number);
      }
    }
  } catch (error) {
    // We specifically catch and suppress the exception here, as we don't want a minor failure to be indicative of the
    // signup having failed to finalise.
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

const defaultText =
  "Welcome to Yoco! You're part of a 200K+ merchant community. Download the app to start accepting payments {{ link }}";

const sendAppDownloadSMS = (
  mobileNumber: string,
  smsText: string = defaultText
) => {
  return new Promise<void>((resolve, reject) => {
    if (window.branch) {
      // Branch requires us to send the number in an E164 format.  We therefore run the number through libphonenumber
      // first, instead of sending what's been captured from the user.

      const parsedMobileNumber = parsePhoneNumberFromString(mobileNumber, "ZA");

      const finalMobileNumber = parsedMobileNumber?.isValid()
        ? parsedMobileNumber.number
        : mobileNumber;
      const linkData = {
        tags: [],
        channel: "Website",
        feature: "TextMeTheApp",
        data: {
          $custom_sms_text: smsText,
        },
      };

      window.branch.sendSMS(finalMobileNumber, linkData, {}, (err: any) => {
        if (!err) {
          resolve();
        } else {
          reject(err);
        }
      });
    } else {
      reject(new Error("The branch SDK is not loaded on the window object."));
    }
  });
};

const isResellerCode = (code: string): boolean => {
  // This is a temporary hacky function which contains a list of reseller codes that haven't yet switched to the new
  // resellerCode links.  We need to hard-code these so that we can differentiate between referral codes and reseller
  // codes, since the old link was the same for both, eg: https://referral.yoco.com/FooBar
  const codes: string[] = [
    "AmazingMall",
    "BR001",
    "BR002",
    "BR003",
    "BR004",
    "BR005",
    "BR006",
    "BR007",
    "BR008",
    "BR009",
    "BR010",
    "BR011",
    "BR012",
    "BR013",
    "BR014",
    "Donovan",
    "GrowMyBusiness",
    "IG001",
    "IG002",
    "IG003",
    "IG004",
    "IG005",
    "IG006",
    "IG007",
    "IG008",
    "IG009",
    "IG010",
    "IG011",
    "IG012",
    "IG013",
    "IG014",
    "IG015",
    "IG016",
    "Katlego012",
    "Kesh",
    "Klein200",
    "Luhyoco",
    "melissa17",
    "Mfanah",
    "Mhligo",
    "mycoza",
    "MyTech",
    "Nthabi",
    "OrarunGroup",
    "SbuYoco",
    "Shandu",
    "vinny22",
    "YoBK",
    "YoCberg",
    "YoChris",
    "YocoBahle",
    "YocoLonwabo",
    "YocoMphot",
    "YOCORUFF",
    "YoEunice",
    "YoGrey",
    "YoGrowth",
    "YoHeirz",
    "YoHugo",
    "YoInsaka",
    "YoItu",
    "YoJames",
    "YoJerry",
    "YoLebo",
    "YoLemonade",
    "YoMall",
    "Yomalvinkoketso",
    "YoMashilane",
    "YoMmule",
    "YoMotso",
    "YoNkulu",
    "YoSfiso",
    "YoSof",
    "YoSolly",
    "Yostudio",
    "YoTam",
    "YoThulani",
    "YoTman",
    "YoTony",
    "YoTshepo",
    "YoTsheps",
    "YoVicinity",
    "YoXneelo",
    "YoZan1",
    "3wGd2j",
  ];
  const normalisedCodes = codes.map(str => str.toUpperCase());

  return normalisedCodes.includes(code.toUpperCase());
};

/**
 * Determine if a code is an affilaite partner code
 * @param code code in string format
 * @returns `true` if the code is an affiliate partner code
 */
const isPartnerCode = (code: string): boolean => {
  const partnerCodes = [
    "xneelo",
    "semantica",
    "woww",
    "digitalboutique",
    "YoMall",
    "YoStudio",
    "stonehut",
    "devscape",
    "erafold",
    "i22",
    "bastionflowe",
    "rubydigital",
    "YoInsaka",
    "medscape",
  ];
  const normalisedCodes = partnerCodes.map(str => str.toUpperCase());

  return normalisedCodes.includes(code.toUpperCase());
};

export default {
  onFinaliseComplete,
  isPartnerCode,
  isResellerCode,
  sendAppDownloadSMS,
};
