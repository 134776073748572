import buttonLoaderImage from "@/assets/images/loader.gif";
import React from "react";

type Props = {
  children: React.ReactNode;
  type?: "submit" | "button";
  block?: boolean;
  disabled?: boolean;
  submitting?: boolean;
  onClick?: () => void;
  testID?: string;
  [x: string]: any;
};

const Button: React.FC<Props> = ({
  children,
  type = "button",
  block,
  disabled,
  submitting,
  onClick,
  testID,
  ...props
}: Props) => {
  return (
    <button
      className={`block mx-auto w-full md:w-9/12
        ${block && " md:w-full"}
        ${submitting && " submitting"}
        bg-yoco-green hover:bg-yoco-dark-green text-white font-bold py-3 px-8 rounded focus:outline-none focus:shadow-outline`}
      style={{ minHeight: "48px" }}
      type={type === "submit" ? "submit" : "button"}
      disabled={disabled || submitting}
      {...props}
      data-testid={testID}
      onClick={onClick}
    >
      <span className="font-button text-base">
        {!submitting && children}
        {submitting && (
          <img
            alt="Loading..."
            className="mx-auto"
            width={25}
            src={buttonLoaderImage}
          />
        )}
      </span>
    </button>
  );
};

export default Button;
