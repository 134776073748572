import khumoProdImage from "@/assets/images/khumo.png";
import Segment from "@/shared/services/Segment";
import { useCartStore } from "@/shop/store";
import { ProductID } from "@/shop/types";
import { useSignupStore } from "@/signup/store";
import React, { FC, useState } from "react";
import "./SwapGoForKhumo.scss";

type Props = {
  onDismiss: () => void;
};

const useStore = () => ({
  replace: useCartStore(state => state.replace),
  signup: useSignupStore(state => state.signup),
});

const SwapGoForKhumo: FC<Props> = ({ onDismiss }: Props) => {
  const [viewMore, setViewMore] = useState(false);
  const { replace, signup } = useStore();

  const onClickReplace = () => {
    if (signup) {
      Segment.trackWithSignup("web_signup_replace_go_with_khumo");
    }
    replace(ProductID.GO, ProductID.KHUMO_SPECIAL);
  };

  const descriptionVisibilityClass = viewMore ? "is-visible" : "";

  return (
    <div className="swap-khumo-go-wrapper bg-yoco-yellow -mx-4 px-4 pt-3 pb-8 relative rounded-t-md tracking-normal">
      <div className="bg-yoco-green-500 text-white absolute -ml-4 py-1 pl-4 pr-10 rounded-r-full">
        <span className="font-bold">Free offer</span>
      </div>

      <button
        type="button"
        onClick={onDismiss}
        className="p-1 absolute right-0 mr-3 focus:outline-none focus:shadow-outline rounded"
      >
        <svg
          fill="none"
          viewBox="0 0 24 24"
          className="w-5 h-5 stroke-current text-yoco-gray-900 hover:text-yoco-yellow-100 transition-colors ease-in duration-300"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m5 5 14 14M19 5 5 19"
          />
        </svg>
      </button>

      <div className="mt-12 flex flex-wrap">
        <div className="w-16 md:w-10 flex-shrink-0 relative">
          <img
            alt="Khumo"
            src={khumoProdImage}
            className="object-cover w-32 h-24 absolute -ml-1 md:-ml-4 mt-3 transform scale-110 md:scale-125"
          />
        </div>

        <div className="flex-1">
          <h4 className="font-bold text-lg">Swap Go for Khumo Offer !</h4>
          <span className="text-yoco-gray-900 text-xs">
            Want to swap your Yoco Go for Yoco Khumo?
          </span>

          <button
            type="button"
            onClick={onClickReplace}
            className="swap-khumo-go-button block mt-2 mb-3 bg-yoco-green-500 text-white rounded-full w-full py-3 px-4 text-left text-sm font-bold"
          >
            <span className="inline-flex items-center">
              Yes, swap Go for Khumo
              <svg
                fill="none"
                viewBox="0 0 21 14"
                xmlns="http://www.w3.org/2000/svg"
                className="h-3 w-5 ml-3 transition-all ease-in duration-300"
              >
                <path
                  fill="#fff"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="m11.068.5 5.505 5.391H0V8.11h16.573L11.068 13.5h3.294L21 7 14.362.5h-3.294Z"
                />
              </svg>
            </span>
          </button>

          <div className="text-yoco-gray-900 text-sm">
            <div className={`swap-khumo-go-text ${descriptionVisibilityClass}`}>
              <p className="mb-3">
                Instead of sending you a Yoco Go we would like to offer you a{" "}
                <span className="font-bold">Khumo</span> at{" "}
                <span className="font-bold">no extra cost.</span> The Khumo is
                worth <span className="font-bold">R699</span> and comes with{" "}
                <span className="font-bold">unlimited 4G data.</span>
              </p>

              <p>
                Yoco wants to give more merchants the opportunity to try{" "}
                <span className="font-bold">
                  the smartest card machine in South Africa.
                </span>
              </p>
            </div>

            <button
              type="button"
              onClick={() => setViewMore(val => !val)}
              className={`swap-khumo-go-view-more-btn font-bold mt-2 inline-flex items-center focus:outline-none rounded ${descriptionVisibilityClass}`}
            >
              <span className="underline">
                View {viewMore ? "less" : "more"}
              </span>

              <svg
                width="10"
                height="6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current ml-3 transform"
              >
                <path
                  d="M9 5 5 1 1 5"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div className="absolute text-white bottom-0 right-0 -mb-4 mr-3">
        <svg
          version="1.1"
          viewBox="0 0 49 98"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          className="fill-current w-full h-16 transform -rotate-90"
        >
          <path d="M-0.00117166017,0 C-0.00117166017,27.0155468 21.9252768,48.9228862 49,48.9964948 C21.9252768,49.0771138 -0.00117166017,70.9832848 -0.00117166017,98 C-0.00117166017,70.9832848 -0.00117166017,27.0155468 -0.00117166017,0 Z" />
        </svg>
      </div>
    </div>
  );
};

export default SwapGoForKhumo;
