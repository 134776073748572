import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import SubmitButton from "@/shared/components/form/SubmitButton";
import { PaymentMethod } from "@/shared/shop/api";
import { makeTestID } from "@/shared/utils/development";
import CartSummaryMobile from "@/shop/components/CartSummaryMobile";
import { useCartStore } from "@/shop/store";
import { PaymentMethodProps } from "@/shop/types";
import React from "react";

const useStore = () => ({
  expressEFTPaymentIntent: useCartStore(state => state.expressEFTPaymentIntent),
});

const EFTPaymentMethod: React.FC<PaymentMethodProps> = ({
  onSuccess,
}: PaymentMethodProps) => {
  const { expressEFTPaymentIntent } = useStore();

  const onSubmit = async () => {
    const isSuccessful = await expressEFTPaymentIntent();

    if (isSuccessful) {
      if (onSuccess) {
        onSuccess(PaymentMethod.EFT);
      }
    }
  };

  return (
    <Formik onSubmit={onSubmit}>
      {() => {
        const button = (
          <SubmitButton testID={makeTestID("payment", "payByEft")}>
            Pay by EFT
          </SubmitButton>
        );

        return (
          <Form>
            <div className="text-base font-bold mb-2">How does it work?</div>

            <div className="block text-base mb-6">
              <ol className="text-black list-decimal pl-6">
                <li className="pb-2">
                  Click ‘Pay by EFT’ to confirm your order.
                </li>
                <li className="pb-2">
                  You’ll be given Yoco’s banking details, and a unique reference
                  number to use when making the payment.
                </li>
                <li className="pb-2">
                  You’ll need to upload your Proof of Payment in the Yoco App or
                  Yoco Business Portal.
                </li>
              </ol>
            </div>

            <div className="text-sm font-bold mb-2">Please note:</div>

            <div>
              Your order will only be processed once proof of payment has been
              received.
            </div>

            <div className="hidden md:block mt-8">{button}</div>
            <CartSummaryMobile isCartEditable={false} button={button} />
          </Form>
        );
      }}
    </Formik>
  );
};

export default EFTPaymentMethod;
