import * as yup from "yup";
import BaseAddressSchema from "./BaseAddressSchema";

class SouthAfricaAddressSchema extends BaseAddressSchema {
  public static schema() {
    return {
      ...super.baseSchema(),
      suburb: yup.string().required().label("suburb"),
      postal_code: yup
        .string()
        .matches(/^\d+$/, "Please enter a valid postal code.")
        .required()
        .min(4, "The postal code must be a minimum of 4 characters")
        .label("postal code")
        .test("is-valid-postal-code", "Postal code cannot be 0000", value => {
          const stringValue = value ? value.toString() : "";
          const numberValue = parseInt(stringValue, 10);
          return numberValue > 0;
        }),
      province: yup.string().required().label("province"),
    };
  }
}

export default SouthAfricaAddressSchema;
