import Header from "@/shared/components/Header";
import EFTBankAccountDetails from "@/shop/pages/CompletePage/EFTBankAccountDetails";
import eftIcon from "@/shop/pages/PaymentPage/images/eft.svg";
import React from "react";

const EFTContent: React.FC<any> = () => {
  return (
    <div className="text-base flex flex-1 flex-col">
      <Header
        title="EFT details"
        description="We've emailed you your EFT details if you would like to make payment later."
      />

      <img src={eftIcon} alt="" className="mx-auto mb-8" />

      <EFTBankAccountDetails />

      <div className="mb-4">
        <h3 className="font-bold">Please note:</h3>
        Your order will only be processed once proof of payment has been
        received.
      </div>
    </div>
  );
};

export default EFTContent;
