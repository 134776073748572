import ErrorMessage from "@/shared/components/form/ErrorMessage";
import { ErrorMessage as Error, useFormikContext } from "formik";
import Field from "@/shared/components/form/Field";
import Input from "@/shared/components/form/Input";
import { Address } from "@/shared/types";
import React, { useCallback } from "react";
import { toast } from "react-toastify";
import UseMyLocation from "@/shared/components/Address/UseMyLocation";
import GooglePlacesAutocomplete from "./GooglePlacesAutocomplete";

type Props = {
  addressLine1Placeholder: string;
  addressLine2PlaceHolder: string;
  onChange: (data: any) => void;
  readonly?: boolean;
};

const CommonAddressFormField: React.FC<Props> = ({
  addressLine1Placeholder,
  addressLine2PlaceHolder,
  onChange,
  readonly = false,
}: Props) => {
  const { setFieldValue, setFieldTouched, validateField } =
    useFormikContext<any>();

  const onBlurField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;

    // We've hooked in to the onBlur event here, which means that we've actually overridden the native one in the
    // Field component.  We therefore need to mimic the original onBlur business logic which marks the field as touched
    // and kicks off validation.
    setFieldTouched(name, true);
    validateField(name);
  };

  const updateAddressValues = useCallback(
    (adrs?: Address) => {
      // for onLocateAddress function
      updateCommonAddressValues(setFieldValue, adrs, setFieldTouched);
      updateZAAddressValues(setFieldValue, adrs, setFieldTouched);
      updateEGAddressValues(setFieldValue, adrs, setFieldTouched);
    },
    [setFieldTouched, setFieldValue]
  );

  const onLocateAddress = (adrs: Address) => {
    // noinspection JSIgnoredPromiseFromCall
    updateAddressValues(adrs);
  };

  return (
    <div className="grid gap-3">
      <div>
        <UseMyLocation
          onLocateAddress={onLocateAddress}
          onError={() =>
            toast.error("We were unable to retrieve your current location.")
          }
        />
      </div>
      <div>
        <Field
          name="address_line1"
          placeholder={addressLine1Placeholder}
          component={GooglePlacesAutocomplete}
          onSelect={onChange}
          readonly={readonly}
        />
        <Error name="address_line1">
          {msg => <ErrorMessage>{msg}</ErrorMessage>}
        </Error>
      </div>
      <Field
        name="address_line2"
        placeholder={addressLine2PlaceHolder}
        component={Input}
        disabled={readonly}
        onBlur={onBlurField}
      />
      <Field name="country" component={Input} type="hidden" />
    </div>
  );
};

export default CommonAddressFormField;

export const updateCommonAddressValues = (
  setFieldValue,
  adrs,
  setFieldTouched
) => {
  setFieldValue("address_line1", adrs?.line1 || "");
  setFieldTouched("address_line1", true);

  setFieldValue("address_line2", adrs?.line2 || "");
  setFieldTouched("address_line2", true);

  setFieldValue("city", adrs?.city || "");
  setFieldTouched("city", true);

  let latitude: string | number | null = adrs?.latitude || null;
  if (latitude && typeof latitude === "number") {
    latitude = latitude.toFixed(6);
  }
  setFieldValue("latitude", latitude);
  setFieldTouched("latitude", true);

  let longitude: string | number | null = adrs?.longitude || null;
  if (longitude && typeof longitude === "number") {
    longitude = longitude.toFixed(6);
  }
  setFieldValue("longitude", longitude);
  setFieldTouched("longitude", true);
};

export const updateZAAddressValues = (setFieldValue, adrs, setFieldTouched) => {
  setFieldValue("province", adrs?.province || "");
  setFieldTouched("province", true);

  setFieldValue("postal_code", adrs?.postal_code || "");
  setFieldTouched("postal_code", true);

  setFieldValue("suburb", adrs?.suburb || "");
  setFieldTouched("suburb", true);
};

export const updateEGAddressValues = (setFieldValue, adrs, setFieldTouched) => {
  // For the first release city code default value is Cairo with city code 00001
  setFieldValue("cityCode", adrs?.cityCode || "00001");

  setFieldValue("area", adrs?.area || "");
  setFieldTouched("area", true);

  setFieldValue("description", adrs?.description || "");
  setFieldTouched("description", true);
};
