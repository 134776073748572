import CardMain from "@/shared/components/CardMain";
import { getQueryStringParams } from "@/shared/utils/url";
import Layout from "@/shop/components/Layout";
import withFetchCart from "@/shop/middleware/withFetchCart";
import { useCartStore } from "@/shop/store";
import React from "react";
import EFTContent from "./EFTContent";
import PaymentCompleteContent from "./PaymentCompleteContent";

const useStore = () => ({
  cart: useCartStore(state => state.cart),
});

const CompletePage: React.FC<any> = () => {
  const { cart } = useStore();
  const queryStringParams = getQueryStringParams();

  let isPaid;

  if (queryStringParams.isPaid && queryStringParams.isPaid === "true") {
    isPaid = true;
  } else {
    isPaid = cart?.is_paid || false;
  }

  return (
    <Layout>
      <div className="flex flex-col">
        <CardMain>
          {isPaid ? <PaymentCompleteContent /> : <EFTContent />}
        </CardMain>
        <div className="hidden sm:block" style={{ minWidth: "650px" }} />
      </div>
    </Layout>
  );
};

export default withFetchCart(CompletePage);
