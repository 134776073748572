import React from "react";

type Props = {
  children: React.ReactNode;
  className?: string;
};

const HelperText: React.FC<Props> = ({ className, children }: Props) => {
  return (
    <p className={`block text-xs text-gray-500 ${className}`}>{children}</p>
  );
};

export default HelperText;
