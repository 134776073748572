import { Address } from "@/shared/types";
import CONFIG from "@/config";
import { isEmpty } from "lodash";

export const isAddressPopulated = (address: Address | null): boolean => {
  // If the primary expected fields of an address have values, we treat it as populated
  return (
    !isEmpty(address?.line1?.trim()) &&
    !isEmpty(address?.suburb?.trim()) &&
    !isEmpty(address?.city?.trim()) &&
    !isEmpty(address?.province?.trim()) &&
    !isEmpty(address?.country?.trim()) &&
    !isEmpty(address?.postal_code?.trim())
  );
};

export const createBlankAddress = (): Address => {
  return {
    line1: "",
    line2: "",
    suburb: "",
    city: "",
    province: "",
    postal_code: "",
    area: "",
    description: "",
    country: CONFIG.countryCode,
    latitude: null,
    longitude: null,
  } as Address;
};
