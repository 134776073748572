import { useAppConfigStore } from "@/signup/store";
import FeatureFlagManager from "@yoco/feature-flags";
import { Config } from "@/config";

export enum SignupRoute {
  HomePage = "/signup/",
  XneeloLandingPage = "/xneelo/",
  PartnerLandingPage = "/partner/:name/",
  ContinuePage = "/signup/:id/continue/",
  BusinessNamePage = "/signup/:id/business_name/",
  BusinessPreQualificationPage = "/signup/:id/pre_qualification/",
  TradingAddressPage = "/signup/:id/trading_address/",
  BusinessTypePage = "/signup/:id/business_type/",
  BusinessOwnerPage = "/signup/:id/business_owner/",
  BusinessCategoryPage = "/signup/:id/business_category/",
  DeveloperDetailsPage = "/signup/:id/developer_details/",
  ServiceSelectionPage = "/signup/:id/services/",
  RegisterReaderPage = "/signup/:id/retail/",
  ProductCataloguePage = "/signup/:id/products/",
  DeliveryPage = "/signup/:id/delivery/",
  PaymentPage = "/signup/:id/payment/",
  CompletePage = "/signup/:id/complete/",
}

export enum UserJourney {
  /*
    2. "banana" - The business details step is split into multiple smaller steps
    3. "cinnamon" - A user journey specific to the Kozen P5 / Evo card machine
    5. "yocoshop" - The user journey specific to yoco.com shop where signup has been created externally via yoco-shop-sdk
    7. "egypt" - Sign up journey which has been modified to handle the egypt flow
    8. "mankai" - A user journey specific to the Kozen P12 card machine
    9. "mango" - A user journey specific to offering merchants online payments on intent screen
    10. "litchi" - A user journey specific to not offering merchants online payments on intent screen
  */
  XNEELO = "xneelo",
  BANANA = "banana",
  CINNAMON = "cinnamon",
  DEVELOPER = "developer",
  YOCOSHOP = "yocoshop",
  PAYFORDATA = "payfordata",
  EGYPT = "egypt",
  MANKAI = "mankai",
  MANGO = "mango",
  LITCHI = "litchi",
}

export interface YocoWindow {
  appEnv: string;
  appConfig: Config;
  appConfigStore: typeof useAppConfigStore;
  config: { [key: string]: any };
  ft: FeatureFlagManager;
}

declare global {
  interface Window {
    _vwo_exp: any;
    analytics: SegmentAnalytics.AnalyticsJS;
    branch: any;
    VWO: any;
    yc: YocoWindow;
    YocoSDK: any;
    ReactNativeWebView?: any;
  }
}

export enum Features {
  TERMS = "terms",
  OWNER_DETAILS_WITH_ID_NUMBER = "ownerDetailsWithIdNumber",
  SWAP_GO_FOR_KHUMO = "swapGoforKhumo",
}

export enum ReferralCampaign {
  // The AMBASSADOR_ campaigns are legacy campaigns which are still required to cater for old Ambassador referral links.
  // We use the campaigns to differentiate between referral and reseller signups.
  AMBASSADOR_REFERRAL = 34324,
  AMBASSADOR_RESELLER = 47531,
}
