import Card from "@/shared/components/Card";
import TermsAndConditionsLink from "@/shared/components/TermsAndConditionsLink";
import { PaymentMethod } from "@/shared/shop/api";
import CartSummary from "@/shop/components/CartSummary";
import { useAppConfigStore, useCartStore } from "@/shop/store";
import { isEmpty } from "lodash";
import React from "react";
import { useSwipeable } from "react-swipeable";
import arrowDownIcon from "./images/arrow-down.svg";
import arrowUpIcon from "./images/arrow-up.svg";

const useStore = () => ({
  cart: useCartStore(state => state.cart),
  intendedPaymentMethod: useCartStore(state => state.intendedPaymentMethod),
  expandMobileSummary: useAppConfigStore(state => state.expandMobileSummary),
  setExpandMobileSummary: useAppConfigStore(
    state => state.setExpandMobileSummary
  ),
});

type Props = {
  isCartEditable?: boolean;
  button?: any;
};

const CartSummaryMobile: React.FC<Props> = ({
  isCartEditable = true,
  button,
}: Props) => {
  const {
    cart,
    intendedPaymentMethod,
    expandMobileSummary,
    setExpandMobileSummary,
  } = useStore();
  const toggleSummary = () => {
    setExpandMobileSummary(!expandMobileSummary);
  };

  const swipeHandler = useSwipeable({
    onSwipedDown: () => setExpandMobileSummary(false),
    onSwipedUp: () => setExpandMobileSummary(true),
    preventScrollOnSwipe: true,
  });

  if (!cart || isEmpty(cart.items)) {
    return null;
  }

  return (
    <>
      <div
        {...swipeHandler}
        onClick={toggleSummary}
        onKeyDown={toggleSummary}
        role="button"
        tabIndex={-1}
        className={`${
          expandMobileSummary
            ? "block fixed left-0 top-0 w-full h-full fade-in"
            : "hidden"
        } `}
        style={{
          backgroundColor: "rgba(0,0,0,0.5)",
        }}
      />
      <Card
        style={{ boxShadow: "0px -2px 8px 0px rgba(0,0,0,0.1)" }}
        className={`${
          expandMobileSummary ? "rounded-t-lg" : ""
        } left-0 bottom-0 mr-4 w-full w-64 block md:hidden fixed z-10`}
      >
        <div {...swipeHandler}>
          <div
            className={`${expandMobileSummary ? "block fade-in" : "hidden"}`}
          >
            <button
              type="button"
              className="mb-2 bg-gray-300 flex w-1/3 mx-auto justify-center text-transparent rounded-lg h-2"
              onClick={toggleSummary}
            >
              <span className="text-xs">Hide</span>
            </button>
            <CartSummary isCartEditable={isCartEditable} />
          </div>
          <button
            type="button"
            className="p-1 mb-2 w-full flex items-center justify-between text-base focus-outline-none"
            onClick={toggleSummary}
          >
            <span className="font-medium text-sm">Total</span>
            <span className="font-bold flex">
              <span>
                {intendedPaymentMethod === PaymentMethod.PAYMENT_TERMS
                  ? cart.instalment_plan_proposal
                      .amount_with_service_fee_formatted
                  : cart.balance_due_formatted}
              </span>
              <span className="pl-2 pt-1">
                {expandMobileSummary ? (
                  <img
                    src={arrowDownIcon}
                    alt="Toggle cart summary"
                    className="h-4"
                  />
                ) : (
                  <img
                    src={arrowUpIcon}
                    alt="Toggle cart summary"
                    className="h-4"
                  />
                )}
              </span>
            </span>
          </button>
        </div>
        {button}
        <div className="mt-2">
          <TermsAndConditionsLink />
        </div>
      </Card>
    </>
  );
};

export default CartSummaryMobile;
