import React from "react";
import CardMain from "@/shared/components/CardMain";
import Header from "@/shared/components/Header";
import Layout from "@/signup/components/Layout";
import ContinueToAppButton from "./ContinueToAppButton";
import cardMachineIcon from "./images/cardMachine-icon.svg";

const CardMachineInAppContent: React.FC<any> = () => {
  return (
    <Layout>
      <CardMain>
        <div className="flex-1 mb-4 sm:mb-8 md:pt-2 pt-8">
          <Header title="You're ready to accept payments" />
          <img
            src={cardMachineIcon}
            alt="Yoco Card Machine"
            className="mx-auto"
          />
          <div className="py-6 leading-8">
            <h3 className="font-bold">Next Steps</h3>
            <ol className="list-decimal list-outside ml-4">
              <li>
                Get your documents together. We&apos;ll need you take a photo of
                your ID or Passport.
              </li>
              <li>Complete your bank details.</li>
              <li>Complete your business details.</li>
            </ol>
          </div>
        </div>
        <ContinueToAppButton />
      </CardMain>
    </Layout>
  );
};

export default CardMachineInAppContent;
