import { ValidationResult } from "../types";
import BaseApi from "./BaseApi";

class ValidationApi extends BaseApi {
  validateSignupEmail(email: string): Promise<ValidationResult> {
    return this.http.post(
      "/signup/api/v1/signups/validate_email/",
      { email },
      {
        timeout: 8000,
      }
    );
  }

  validateSignupPassword(password: string): Promise<ValidationResult> {
    return this.http.post(
      "/signup/api/v1/signups/validate_password/",
      { password },
      {
        timeout: 8000,
      }
    );
  }

  validateSerialNumber(serialNumber: string): Promise<ValidationResult> {
    return this.http.post(
      "/signup/api/v1/signups/validate_serial_number/",
      {
        serial_number: serialNumber,
      },
      {
        timeout: 8000,
      }
    );
  }

  validateBusinessName(business_name: string): Promise<ValidationResult> {
    return this.http.post(
      "/signup/api/v1/signups/validate_business_name/",
      { business_name },
      {
        timeout: 8000,
      }
    );
  }

  validateOrderNumber(order_number: string): Promise<ValidationResult> {
    return this.http.get("/signup/api/v1/signups/validate_order_number/", {
      timeout: 8000,
      params: {
        order_number,
      },
    });
  }
}

export default ValidationApi;
