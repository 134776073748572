import CONFIG from "@/config";
import shop from "@/shared/shop/api";
import { LabelValuePair } from "@yoco/sawubona-sdk";
import create from "zustand";
import { devtools } from "zustand/middleware";

export interface SharedFormHelperState {
  provinces: LabelValuePair[];
  fetchProvinces: () => void;
}

export const useSharedFormHelperStore = create<SharedFormHelperState>()(
  devtools(
    set => ({
      provinces: [],
      fetchProvinces: async () => {
        set(
          { provinces: await shop.provinces.list() },
          false,
          "fetchProvinces"
        );
      },
    }),
    { name: "SharedFormHelperStore", enabled: CONFIG.stateDebugEnabled }
  )
);
