import { useFeatureFlags } from "@yoco/feature-flags";
import { BusinessType, Service } from "@yoco/sawubona-sdk";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import Text from "@/shared/components/Text";
import shop, { Product, ReasonNotAvailable } from "@/shared/shop/api";
import { makeTestID } from "@/shared/utils/development";
import CartQuantityActions from "@/shop/components/CartQuantityActions";
import { useAppConfigStore, useCartStore } from "@/shop/store";
import { useSignupStore } from "@/signup/store";
import { Features, SignupRoute } from "@/signup/types";
import tickIcon from "./images/tick.svg";
import yocoGoImage from "./images/yoco-go.png";
import yocoKhumoLadumaImage from "./images/yoco-khumo-laduma.png";
import yocoKhumoPrintImage from "./images/yoco-khumo-print.png";
import yocoKhumoImage from "./images/yoco-khumo.png";
import yocoNeoTouchImage from "./images/yoco-neo-touch.png";
import yocoNeoPlusImage from "./images/yoco-neo-plus.png";
import yocoNeoImage from "./images/yoco-neo.png";
import yocoProImage from "./images/yoco-pro.png";
import "./ProductBlock.scss";

const useStore = () => ({
  add: useCartStore(state => state.add),
  cart: useCartStore(state => state.cart),
  business: useAppConfigStore(state => state.business),
  signup: useSignupStore(state => state.signup),
  getSignup: useSignupStore(state => state.getSignup),
  finaliseSignup: useSignupStore(state => state.finaliseSignup),
  updateSignup: useSignupStore(state => state.updateSignup),
});

const getProductImage = (product: Product) => {
  switch (product.sku) {
    case "DSQ001":
      return yocoGoImage;
    case "MIU010":
      return yocoProImage;
    case "BLU55A":
      return yocoNeoImage;
    case "KOZP5":
      return yocoKhumoImage;
    case "KOZP12B":
      return yocoNeoTouchImage;
    case "NXGN86":
      return yocoKhumoPrintImage;
    case "KOZP5L":
      return yocoKhumoLadumaImage;
    case "BLUE55P":
      return yocoNeoPlusImage;
    default:
      return "";
  }
};

type Props = {
  product: Product;
  quantity: number;
  onAddedToCart?: (product: Product) => void;
};

const ProductBlock: React.FC<Props> = ({
  product,
  quantity,
  onAddedToCart,
}: Props) => {
  const {
    add,
    cart,
    business,
    signup,
    getSignup,
    finaliseSignup,
    updateSignup,
  } = useStore();
  const features = useFeatureFlags();
  const navigate = useNavigate();

  const defaultAnimation =
    " animate-bounce transition-all duration-1000 ease-out scale-110 ";
  const [stopAnimation, setStopAnimation] = useState<string>(defaultAnimation);

  useEffect(() => {
    setStopAnimation(defaultAnimation);
    const timeoutID = (window as any).setTimeout(() => {
      setStopAnimation("");
    }, 1000);

    return () => (window as any).clearTimeout(timeoutID);
  }, [quantity]);

  const handleAdd = async e => {
    e.preventDefault();

    const success = await add(product.id);

    if (success) {
      if (onAddedToCart) {
        onAddedToCart(product);
      }
    }
  };

  const [isExpandFeatures, setExpandFeatures] = useState(false);

  const isAvailableOnTerms = useMemo(
    () =>
      (signup?.business_type === BusinessType.SOLE_TRADER ||
        signup?.business_type === BusinessType.COMPANY ||
        signup?.business_type === BusinessType.NOT_REGISTERED) &&
      features.isEnabled(Features.TERMS) &&
      product.can_be_purchased &&
      product.is_available_on_payment_terms,
    [signup, features, product]
  );

  const [isOnWaitlist, setIsOnWaitlist] = useState(false);

  const onAddToWaitlist = async () => {
    const result = await shop.waitlist.add({
      email: signup?.email || business?.owner.email,
      mobile_number: signup?.mobile_number || business?.telephone_number,
      product: product.id,
    });

    if (result.id) {
      setIsOnWaitlist(true);
    }
  };

  // TODO: Remove this after Go out of stock experiment or standardise this behaviour
  // This will render a disabled "out of stock" button instead of waitlist CTA
  const isExperimentalOutOfStockProduct = useMemo(() => {
    return (
      product.reason_not_available === ReasonNotAvailable.OUT_OF_STOCK &&
      product.sku === "DSQ001"
    );
  }, [product]);

  const isWaitListAvailable = useMemo(() => {
    return !!(
      product.reason_not_available &&
      (signup || business) &&
      !isExperimentalOutOfStockProduct
    );
  }, [product, signup, business, isExperimentalOutOfStockProduct]);

  const canFinaliseSignup = useMemo(() => {
    return signup && isOnWaitlist && cart?.items.length === 0;
  }, [signup, isOnWaitlist, cart]);

  const onClickFinalise = async () => {
    const signupServices = {
      services: [Service.WAITLIST],
      primary_service: Service.WAITLIST,
    };
    await updateSignup(signupServices);

    const isSignupFinalised = await finaliseSignup();

    if (isSignupFinalised) {
      const finalisedSignup = getSignup();

      navigate(
        formatRoute(SignupRoute.CompletePage, {
          id: finalisedSignup.id,
        })
      );
    }
  };

  return (
    <div
      className={`w-full sm:max-w-xs pr-0 mb-4 lg:m-0 product-block-${product.sku}`}
    >
      <div className="block w-full rounded relative bg-yoco-gray">
        <div className="sharp-grotesk-medium">
          {product.reason_not_available === ReasonNotAvailable.OUT_OF_STOCK ? (
            <div className="ribbon ribbon-red">Sold out</div>
          ) : (
            <>
              <div className={`ribbon ${product.ribbon_class}`}>
                {product.ribbon_text}{" "}
              </div>

              {isAvailableOnTerms && (
                <div className="ribbon instalment-label">
                  Available over <br /> 3 installments
                </div>
              )}
            </>
          )}
        </div>
        <img
          className="w-3/4 mx-auto -mb-12 pt-4 ml-12"
          src={getProductImage(product)}
          alt="Card machine"
        />

        <div className="p-4">
          <div className="text-center grid gap-2">
            {product.short_name && (
              <Text size="h2" className="sharp-grotesk">
                {product.short_name}
              </Text>
            )}

            <div className="flex flex-row justify-center text-yoco-dark-green">
              {product.is_discounted ? (
                <>
                  <Text
                    size="h3"
                    className="product-current-price text-yoco-orange sharp-grotesk-medium"
                  >
                    {product.unit_price_formatted}
                  </Text>
                  <Text
                    size="base"
                    className="line-through text-gray-500 ml-2 font-medium product-discount-price"
                  >
                    {product.original_unit_price_formatted}
                  </Text>
                </>
              ) : (
                <Text size="h3" className="product-current-price">
                  {product.unit_price_formatted}
                </Text>
              )}
            </div>

            <div>
              <Text size="description" className="product-description-text">
                {product.description}
              </Text>
              <div className={product.features ? "block" : "hidden"}>
                <button
                  type="button"
                  className="text-blue-500 hover:underline py-2"
                  onClick={() => setExpandFeatures(!isExpandFeatures)}
                >
                  {isExpandFeatures ? "Hide" : "View"} details
                </button>
                <div
                  className={`text-left text-sm product-features mb-2 ${
                    isExpandFeatures ? "block" : "hidden"
                  }`}
                  dangerouslySetInnerHTML={{ __html: product.features }}
                />
              </div>
            </div>
          </div>

          {quantity !== 0 && (
            <div className="flex justify-between flex-row items-center justify-center">
              <CartQuantityActions
                product={product}
                disabled={quantity >= product.max_quantity_per_cart}
              />

              <div
                className={`flex justify-between flex-row items-center justify-center ${stopAnimation}`}
              >
                <img src={tickIcon} className="h-5 mr-3" alt="Tick" />
                <Text size="h5">Added to cart</Text>
              </div>
            </div>
          )}

          {quantity === 0 && product.can_be_purchased && (
            <button
              className="mx-auto block w-full bg-black text-white font-bold py-3 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={handleAdd}
              data-testid={makeTestID(
                "productCatalogue",
                "addToCart",
                product.short_name
              )}
            >
              <span className="font-bold text-base">Add to cart</span>
            </button>
          )}

          {isWaitListAvailable && (
            <>
              {isOnWaitlist ? (
                <>
                  <div className="flex py-3 flex-row items-center justify-center">
                    <img src={tickIcon} className="h-5 mr-3" alt="Tick" />
                    <Text>
                      You have been added to the waitlist. We&apos;ll let you
                      know when it becomes available.
                    </Text>
                  </div>
                  {canFinaliseSignup && (
                    <button
                      type="button"
                      onClick={onClickFinalise}
                      className="mx-auto block w-full bg-black text-white font-bold py-3 rounded focus:outline-none focus:shadow-outline mt-3"
                    >
                      Complete signup
                    </button>
                  )}
                </>
              ) : (
                <button
                  className="mx-auto block w-full bg-red-500 text-white font-bold py-3 rounded focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={onAddToWaitlist}
                >
                  <span className="font-bold text-base">
                    Reserve your {product.short_name}
                  </span>
                </button>
              )}
            </>
          )}

          {isExperimentalOutOfStockProduct && (
            <button
              className="mx-auto block w-full bg-black text-white font-bold py-3 rounded focus:outline-none focus:shadow-outline opacity-70 cursor-disabled"
              type="button"
              disabled
              data-testid={makeTestID(
                "productCatalogue",
                "outOfStock",
                product.short_name
              )}
            >
              <span className="font-bold text-base">Out of stock</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductBlock;
