import CardMain from "@/shared/components/CardMain";
import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import SubmitButton from "@/shared/components/form/SubmitButton";
import Header from "@/shared/components/Header";
import { makeTestID } from "@/shared/utils/development";
import CartSummaryMobile from "@/shop/components/CartSummaryMobile";
import CartSummaryMobileOffset from "@/shop/components/CartSummaryMobileOffset";
import CheckoutSidePanel from "@/shop/components/CheckoutSidePanel";
import Layout from "@/shop/components/Layout";
import {
  getInitialValues as getPromoCodeInitialValues,
  getValidationSchema as getPromoCodeValidationSchema,
} from "@/shop/components/PromoCodeField";
import StepProgressBar from "@/shop/components/StepProgressBar";
import ProductGrid from "@/shop/pages/ProductCataloguePage/ProductGrid";
import { useCartStore } from "@/shop/store";
import { ShopRoute } from "@/shop/types";
import { useFeatureFlags } from "@yoco/feature-flags";
import React from "react";
import { useNavigate } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import * as yup from "yup";

const ProductCataloguePage: React.FC<any> = () => {
  const navigate = useNavigate();
  const { cart, updateCart } = useCartStore.getState();
  const features = useFeatureFlags().getEnabledFeatureNames();

  const onSubmit = async () => {
    const { id } = useCartStore.getState().getCart();

    if (cart && features) {
      await updateCart({ feature_flags: features });
    }

    navigate(
      formatRoute(ShopRoute.DeliveryPage, {
        id,
      })
    );
  };

  const initialValues = {
    ...getPromoCodeInitialValues(cart),
  };

  const schema = yup.object({
    ...getPromoCodeValidationSchema(),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {() => {
        const button = (
          <SubmitButton
            block
            testID={makeTestID("productCatalogue", "checkout")}
          >
            Checkout
          </SubmitButton>
        );

        return (
          <Form>
            <Layout>
              <CardMain wider>
                <StepProgressBar number={1} />
                <Header title="Choose a card machine" />
                <ProductGrid />
                <CartSummaryMobileOffset />
              </CardMain>
              <CheckoutSidePanel button={button} />
              <CartSummaryMobile button={button} />
            </Layout>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProductCataloguePage;
