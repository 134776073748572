import Text from "@/shared/components/Text";
import { percentage } from "@/shared/utils/helpers/general";
import { useCartStore } from "@/shop/store";
import { ShopRoute } from "@/shop/types";
import React from "react";
import { useNavigate } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import arrowIcon from "./arrow-left.svg";

type Props = {
  number?: number;
};

interface Step {
  number: number;
  link: () => string;
}

const useStore = () => ({
  cart: useCartStore(state => state.cart),
});

const StepProgressBar: React.FC<Props> = ({
  number: currentStepNumber = 1,
}: Props) => {
  const navigate = useNavigate();
  const { cart } = useStore();
  const steps: Step[] = [
    {
      number: 1,
      link: () => {
        return cart
          ? formatRoute(ShopRoute.CartPage, {
              id: cart?.id,
            })
          : formatRoute(ShopRoute.HomePage);
      },
    },
    {
      number: 2,
      link: () => {
        return formatRoute(ShopRoute.DeliveryPage, {
          id: cart?.id,
        });
      },
    },
    {
      number: 3,
      link: () => {
        return formatRoute(ShopRoute.PaymentPage, {
          id: cart?.id,
        });
      },
    },
  ];

  const getPreviousStep = () => {
    return steps.find(step => step.number === currentStepNumber - 1);
  };

  const previousStep = getPreviousStep();
  const totalSteps = steps.length;
  const isBackButtonVisible = !!previousStep;
  const calculatedPercentage = percentage(currentStepNumber, totalSteps);

  const onClickBackToPreviousStep = () => {
    if (previousStep) {
      navigate(previousStep.link());
    }
  };

  const backButton = (
    <button
      type="button"
      className="w-full py-4 pr-8"
      onClick={onClickBackToPreviousStep}
    >
      <img src={arrowIcon} alt="Arrow" />
    </button>
  );

  if (previousStep) {
    return (
      <div className="w-full mb-4 sm:mb-8 grid gap-6">
        <div className="flex flex-wrap items-center">
          <div className="flex-1 flex-shrink hidden md:block">
            {isBackButtonVisible && backButton}
          </div>
          <Text className="hidden md:block">
            Step {currentStepNumber}/{totalSteps}
          </Text>
        </div>
        <div className="flex items-center">
          <div className="w-1/6 md:hidden">
            {isBackButtonVisible && backButton}
          </div>
          <div className="w-4/6 md:w-full">
            <div className="bg-yoco-gray rounded">
              <div
                className="bg-yoco-green rounded text-xs leading-none h-1 text-center text-white"
                style={{ width: `${calculatedPercentage}%` }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default StepProgressBar;
