import CardMain from "@/shared/components/CardMain";
import Field from "@/shared/components/form/Field";
import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import FormOption from "@/shared/components/form/FormOption";
import Header from "@/shared/components/Header";
import Segment from "@/shared/services/Segment";
import { makeTestID } from "@/shared/utils/development";
import Layout from "@/signup/components/Layout";
import StepProgressBar from "@/signup/components/StepProgressBar";
import SubmitButton from "@/signup/components/SubmitButton";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import SignupService from "@/signup/services/SignupService";
import Validator from "@/signup/services/Validator";
import { useSignupStore, useStepStore } from "@/signup/store";
import { SignupRoute, UserJourney } from "@/signup/types";
import { Service, SignupStep } from "@yoco/sawubona-sdk";
import { FormikValues } from "formik";
import { remove, uniq } from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import * as yup from "yup";
import activateCardIcon from "./images/activate-card.svg";
import buyCardIcon from "./images/buy-card.svg";
import deliveryIcon from "./images/delivery-icon.svg";
import onlineCardPayment from "./images/online-payments.svg";
import OrderNumberField from "./OrderNumberField";
import SerialNumberField from "./SerialNumberField";

const useStore = () => ({
  setActiveStep: useStepStore(state => state.setActiveStep),
  signup: useSignupStore(state => state.signup),
  getSignup: useSignupStore(state => state.getSignup),
  updateSignup: useSignupStore(state => state.updateSignup),
  isOnFinalStep: useStepStore(state => state.isOnFinalStep),
  finaliseSignup: useSignupStore(state => state.finaliseSignup),
  getNextStep: useStepStore(state => state.getNextStep),
  getNextStepLink: useStepStore(state => state.getNextStepLink),
});

const ONLINE_SERVICES = "online_payments";

const onlineServices: Service[] = [
  Service.GIFT_VOUCHERS,
  Service.INVOICES,
  Service.PAYMENT_REQUEST,
  Service.WEBSITE,
];

const isSubmitDisabled = (services: string[]) => {
  return (
    services.length === 0 ||
    (services.length === 1 && services.includes(ONLINE_SERVICES))
  );
};

const ServiceSelectionPage: React.FC<any> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    setActiveStep,
    signup,
    getSignup,
    updateSignup,
    isOnFinalStep,
    finaliseSignup,
    getNextStepLink,
  } = useStore();

  const [isReaderRegistration, setIsReaderRegistration] = useState(
    signup?.services.includes(Service.READER_REGISTRATION)
  );

  const [isWaitingForDelivery, setIsWaitingForDelivery] = useState(
    signup?.services.includes(Service.WAITING_FOR_DELIVERY)
  );

  useEffect(() => {
    setActiveStep(SignupStep.ServiceSelectionPage);
  }, [setActiveStep]);

  useEffect(() => {
    Segment.track("web_signup_intent_start");
  }, [location]);

  const initialServices = (services: string[]) => {
    if (services.some(service => onlineServices.includes(service as Service))) {
      services.push(ONLINE_SERVICES as Service);
    }
    return services;
  };

  const initialValues = {
    stage: SignupStep.ServiceSelectionPage,
    services: signup?.services ? initialServices(signup?.services) : "",
    serial_number: signup?.serial_number || "",
    order_number: signup?.order_number || "",
  };

  yup.addMethod(
    yup.string,
    "serialNumberAvailable",
    Validator.validateSerialNumberAvailable
  );

  yup.addMethod(yup.string, "orderNumber", Validator.validateOrderNumber);

  const serialNumberSchema = isReaderRegistration
    ? (yup as any)
        .string()
        .serialNumberAvailable()
        .required()
        .label("serial number")
    : yup.mixed();

  const orderNumberSchema = isWaitingForDelivery
    ? (yup as any).string().orderNumber().required().label("order number")
    : yup.mixed();

  const schema = yup.object({
    services: yup
      .array()
      .required("You must select at least one service.")
      .min(1, "You must select at least one service.")
      .label("services"),
    serial_number: serialNumberSchema,
    order_number: orderNumberSchema,
  });

  const setCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    formik: any,
    servicesToRemove?: Service[]
  ) => {
    const { name, value } = e.target;
    const initialCheckboxValues: Array<string> = uniq(formik.values[name]);
    let selectedServices: Array<string> = [...formik.values[name], value];

    if (initialCheckboxValues.includes(value)) {
      selectedServices = remove(initialCheckboxValues, item => {
        return item !== value;
      });
    }

    if (servicesToRemove) {
      selectedServices = selectedServices.filter(
        service => !servicesToRemove.includes(service as Service)
      );
    }

    if (
      value === ONLINE_SERVICES &&
      selectedServices.includes(ONLINE_SERVICES)
    ) {
      if (signup?.reseller_code) {
        selectedServices.push(Service.WEBSITE);
      }
    }

    const clearOnlineServices = (services: string[]) => {
      return services.filter(
        service => !onlineServices.includes(service as Service)
      );
    };

    if (!selectedServices.includes(ONLINE_SERVICES)) {
      selectedServices = clearOnlineServices(selectedServices);
    }

    const isReader = selectedServices.includes(Service.READER_REGISTRATION);
    setIsReaderRegistration(isReader);
    if (!isReader) {
      formik.setFieldValue("serial_number", "");
    }

    const isWaiting = selectedServices.includes(Service.WAITING_FOR_DELIVERY);
    setIsWaitingForDelivery(isWaiting);
    if (!isWaiting) {
      formik.setFieldValue("order_number", "");
    }

    formik.setFieldValue(name, selectedServices);
    formik.validateField(name);
  };

  const onSubmit = async (values: FormikValues) => {
    // We use a fake service called ONLINE_SERVICES to show/hide the actual online services.
    // Therefore, we strip this fake service from the services list before submitting.
    const newValues = { ...values };
    newValues.services = newValues.services.filter(
      service => service !== ONLINE_SERVICES
    );

    const isSignupUpdated = await updateSignup(newValues);

    if (isSignupUpdated) {
      Segment.trackWithSignup("web_signup_intent_click_next_button");

      if (isOnFinalStep()) {
        const isSignupFinalised = await finaliseSignup();

        if (isSignupFinalised) {
          const finalisedSignup = getSignup();

          navigate(
            formatRoute(SignupRoute.CompletePage, {
              id: finalisedSignup.id,
            })
          );
        }
      } else {
        navigate(getNextStepLink());
      }
    }
  };

  const isUserJourneyMango = signup?.user_journey === UserJourney.MANGO;

  const isNotResellerSignup =
    !signup?.reseller_code ||
    SignupService.isPartnerCode(signup?.reseller_code);

  const isOnlinePaymentServiceVisible =
    isNotResellerSignup && isUserJourneyMango;

  return (
    <Layout>
      <CardMain>
        <StepProgressBar />
        <Header title="How can we get you started?" />
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {(formik: any) => {
            return (
              <Form>
                <div className="sm:mb-8">
                  <div className="grid gap-3 mb-4 sm:justify-center">
                    <div className="flex flex-col sm:flex-row flex-shrink sm:space-x-6 space-y-2 sm:space-y-0">
                      <Field
                        type="checkbox"
                        name="services"
                        value={Service.READER_REGISTRATION}
                        option={{
                          label: "I have my card machine already",
                          image: activateCardIcon,
                          noFlex: true,
                          padding: true,
                          margin: true,
                          display: "block",
                        }}
                        component={FormOption}
                        className="flex-initial h-28 sm:w-11/12 sm:max-w-xs4"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          // noinspection JSIgnoredPromiseFromCall
                          setCheckbox(e, formik, [
                            Service.WAITING_FOR_DELIVERY,
                            Service.CARD_MACHINE,
                          ]);
                        }}
                        data-testid={makeTestID(
                          "serviceSelection",
                          "paymentLinks"
                        )}
                      />

                      {formik.values.services.includes(
                        Service.READER_REGISTRATION
                      ) ? (
                        <SerialNumberField className="block sm:hidden" />
                      ) : null}

                      <Field
                        type="checkbox"
                        name="services"
                        value={Service.WAITING_FOR_DELIVERY}
                        option={{
                          label: "I've ordered my card machine",
                          image: deliveryIcon,
                          noFlex: true,
                          padding: true,
                          margin: true,
                          display: "block",
                        }}
                        component={FormOption}
                        className="flex-initial h-28 sm:w-11/12 sm:max-w-xs4"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          // noinspection JSIgnoredPromiseFromCall
                          setCheckbox(e, formik, [
                            Service.CARD_MACHINE,
                            Service.READER_REGISTRATION,
                          ]);
                        }}
                      />

                      {formik.values.services.includes(
                        Service.WAITING_FOR_DELIVERY
                      ) ? (
                        <OrderNumberField className="block sm:hidden" />
                      ) : null}

                      <Field
                        type="checkbox"
                        name="services"
                        value={Service.CARD_MACHINE}
                        option={{
                          label: "I want to buy a card machine",
                          image: buyCardIcon,
                          noFlex: true,
                          padding: true,
                          margin: true,
                          display: "block",
                        }}
                        component={FormOption}
                        className="flex-initial h-28 sm:w-11/12 sm:max-w-xs4"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          // noinspection JSIgnoredPromiseFromCall
                          setCheckbox(e, formik, [
                            Service.WAITING_FOR_DELIVERY,
                            Service.READER_REGISTRATION,
                          ]);
                        }}
                      />

                      {isOnlinePaymentServiceVisible ? (
                        <Field
                          type="checkbox"
                          name="services"
                          value={ONLINE_SERVICES}
                          option={{
                            label: "I want to take payments online",
                            image: onlineCardPayment,
                            noFlex: true,
                            padding: true,
                            margin: true,
                            display: "block",
                          }}
                          component={FormOption}
                          className="flex-initial h-28 sm:w-11/12 sm:max-w-xs4"
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            // noinspection JSIgnoredPromiseFromCall
                            setCheckbox(e, formik);
                          }}
                          data-testid={makeTestID(
                            "serviceSelection",
                            "vouchers"
                          )}
                        />
                      ) : null}
                    </div>
                  </div>
                  {formik.values.services.includes(
                    Service.READER_REGISTRATION
                  ) ? (
                    <SerialNumberField className="hidden sm:block" />
                  ) : null}

                  {formik.values.services.includes(
                    Service.WAITING_FOR_DELIVERY
                  ) ? (
                    <OrderNumberField className="hidden sm:block" />
                  ) : null}

                  {formik.values.services.includes(ONLINE_SERVICES) ? (
                    <div className="mb-3">
                      <Field
                        type="checkbox"
                        name="services"
                        value={Service.WEBSITE}
                        option={{
                          label: "Yoco Gateway",
                          description:
                            "Accept payments on your online store with our ecommerce integration. Compatible with WooCommerce, Wix and Shopify.",
                        }}
                        component={FormOption}
                        className="mb-2"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          // noinspection JSIgnoredPromiseFromCall
                          setCheckbox(e, formik);
                        }}
                        data-testid={makeTestID(
                          "serviceSelection",
                          "onYourWebsite"
                        )}
                      />
                      {isUserJourneyMango ? null : (
                        <>
                          <Field
                            type="checkbox"
                            name="services"
                            value={Service.PAYMENT_REQUEST}
                            option={{
                              label: "Yoco Link",
                              description:
                                "Accept payments online without a website. Send payment links to your customers on WhatsApp, email, social media or SMS.",
                            }}
                            component={FormOption}
                            className="mb-2"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              // noinspection JSIgnoredPromiseFromCall
                              setCheckbox(e, formik);
                            }}
                            data-testid={makeTestID(
                              "serviceSelection",
                              "paymentLinks"
                            )}
                          />
                          <Field
                            type="checkbox"
                            name="services"
                            value={Service.INVOICES}
                            option={{
                              label: "Yoco Invoice",
                              description:
                                "Get paid online by sending professional, tax-compliant invoices to your customers with a few clicks.",
                            }}
                            component={FormOption}
                            className="mb-4"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              // noinspection JSIgnoredPromiseFromCall
                              setCheckbox(e, formik);
                            }}
                            data-testid={makeTestID(
                              "serviceSelection",
                              "invoices"
                            )}
                          />
                        </>
                      )}
                    </div>
                  ) : null}
                </div>

                <SubmitButton
                  disabled={isSubmitDisabled(formik.values.services)}
                  testID={makeTestID("serviceSelection", "next")}
                >
                  Next
                </SubmitButton>
              </Form>
            );
          }}
        </Formik>
      </CardMain>
    </Layout>
  );
};

export default withFetchSignup(ServiceSelectionPage);
