import Text from "@/shared/components/Text";
import React from "react";

const TermsAndConditionsLink: React.FC<any> = () => {
  return (
    <label className="flex items-center">
      <Text className="text-left text-base font-meduim text-black">
        <input
          type="checkbox"
          required
          onInvalid={e =>
            (e.target as HTMLInputElement).setCustomValidity(
              "Please review and accept Yoco's terms of service before you continue."
            )
          }
          onInput={e => (e.target as HTMLInputElement).setCustomValidity("")}
        />{" "}
        By checking this box, I agree to Yoco's{" "}
        <a
          href="https://www.yoco.com/za/terms/"
          target="_blank"
          rel="noopener noreferrer"
        >
          terms
        </a>
      </Text>
    </label>
  );
};

export default TermsAndConditionsLink;
