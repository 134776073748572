import { connect, FieldInputProps } from "formik";
import React from "react";

type Props = {
  field: FieldInputProps<any>;
  label?: string;
  className?: string;
  [x: string]: any;
};

const Radio: React.FC<Props> = ({
  field,
  label,
  className,
  ...props
}: Props) => {
  return (
    <div className="form-check mb-1 w-100">
      <label className="form-check-label w-100 block py-1">
        <input
          type="radio"
          className={`w-5 h-5 pl-2 align-middle -mt-1 form-radio text-yoco-green ${className}`}
          {...field}
          {...props}
        />
        {label && <span className="mx-3">{label}</span>}
      </label>
    </div>
  );
};

export default connect(Radio);
