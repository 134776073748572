import { CartType } from "@/shared/shop/api";
import { useCartStore } from "@/shop/store";
import { ShopRoute } from "@/shop/types";
import React, { useEffect } from "react";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { validate as isValidUUID } from "uuid";

const useStore = () => ({
  fetchCart: useCartStore(state => state.fetchCart),
  getCart: useCartStore(state => state.getCart),
});

export default WrappedComponent => {
  const FetchCart: React.FC<any> = props => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { fetchCart, getCart }: any = useStore();

    const isOnOrderConfirmationPage = !!useMatch(ShopRoute.DeliveryPage);

    useEffect(() => {
      if (id && isValidUUID(id)) {
        fetchCart(id)
          .then(() => {
            const cart = getCart();

            if (cart.type !== CartType.SIGNUP) {
              if (cart.is_order && !isOnOrderConfirmationPage) {
                navigate(
                  formatRoute(ShopRoute.CompletePage, {
                    id,
                  })
                );
              }
            } else {
              navigate(ShopRoute.HomePage);
            }
          })
          .catch(() => {
            navigate(ShopRoute.HomePage);
          });
      } else {
        navigate(ShopRoute.HomePage);
      }
    }, [id, navigate, fetchCart, getCart, isOnOrderConfirmationPage]);

    return <WrappedComponent {...props} />;
  };

  return FetchCart;
};
