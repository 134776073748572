import CardMain from "@/shared/components/CardMain";
import Header from "@/shared/components/Header";
import Layout from "@/shop/components/Layout";
import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage: React.FC<any> = () => {
  return (
    <Layout>
      <CardMain>
        <Header title="404 - Page not found" />
        <div className="flex justify-center">
          <Link to="/shop">Back to shop</Link>
        </div>
      </CardMain>
    </Layout>
  );
};

export default NotFoundPage;
