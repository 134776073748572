import { useCartStore } from "@/shop/store";
import { ShopRoute } from "@/shop/types";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";

const useStore = () => ({
  cart: useCartStore(state => state.cart),
});

export default WrappedComponent => {
  const RedirectToCartPageIfCartEmpty: React.FC<any> = props => {
    const navigate = useNavigate();
    const { cart } = useStore();

    useEffect(() => {
      if (cart && isEmpty(cart.items)) {
        navigate(
          formatRoute(ShopRoute.CartPage, {
            id: cart.id,
          })
        );
      }
    }, [cart, navigate]);

    return <WrappedComponent {...props} />;
  };

  return RedirectToCartPageIfCartEmpty;
};
