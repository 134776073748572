/* eslint-disable jsx-a11y/click-events-have-key-events */
import Label from "@/shared/components/form/Label";
import { executeScroll } from "@/shared/utils/helpers/general";
import { BusinessCategory, BusinessSubcategory } from "@yoco/sawubona-sdk";
import { connect, FieldInputProps } from "formik";
import React, { useEffect, useRef, useState } from "react";

type Props = {
  field: FieldInputProps<any>;
  label?: string;
  placeholder?: string;
  [x: string]: any;
  categories: BusinessCategory[];
  onSelectResult: (result: BusinessSubcategory) => void;
  maxResults?: number;
};

const CategorySearch: React.FC<Props> = ({
  field,
  label,
  placeholder,
  categories,
  onSelectResult,
  maxResults = 8,
  ...props
}: Props) => {
  const inputRef = useRef<HTMLDivElement>(null);

  const [subcategories, setSubcategories] = useState([] as any);
  const [results, setResults] = useState([] as any);

  useEffect(() => {
    // The categories given to this component are structured as an array of parent categories, with each parent
    // having one or many subcategories.  We allow for searching on the subcategory, therefore we'll reformat
    // this array as an array of subcategories.
    const subcats: BusinessSubcategory[] = [];

    categories.forEach(category => {
      category.subcategories.forEach(subcategory => {
        subcats.push(subcategory);
      });
    });

    setSubcategories(subcats);
  }, [categories]);

  useEffect(() => {
    const getMatchingResults = (query: string) => {
      return subcategories.filter(subcategory => {
        return (
          subcategory.full_name.toLowerCase().includes(query.toLowerCase()) ||
          subcategory.synonyms.toLowerCase().includes(query.toLowerCase())
        );
      });
    };

    if (field.value) {
      setResults(getMatchingResults(field.value));
    } else {
      setResults([]);
    }
  }, [field.value, subcategories]);

  return (
    <>
      {label && <Label>{label}</Label>}
      <div style={{ position: "relative" }}>
        <div
          style={{ position: "absolute", top: "-30px", left: "0" }}
          ref={inputRef}
        />
        <span
          style={{
            position: "absolute",
            left: 17,
            top: 7,
            fontSize: "10px",
            fontWeight: 500,
            color: "#b9bcc2",
          }}
        >
          {field.value !== "" && placeholder}
        </span>
        <input
          type="text"
          className={`block placeholder-gray-500 border rounded w-full mb-0 px-4 border-gray-400 text-gray-900  leading-tight focus:outline-none focus:shadow-outline focus-within:border-teal-500
          ${field.value !== "" && placeholder ? "pt-6 pb-2" : "py-4"}
          `}
          placeholder={placeholder}
          autoComplete="off"
          onFocus={() => executeScroll(inputRef.current as HTMLDivElement)}
          data-lpignore="true"
          {...field}
          {...props}
        />
        <div className="autocomplete-dropdown-container shadow-lg">
          {results &&
            results.slice(0, maxResults).map(result => (
              /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
              <div
                className="p-4 autocomplete-dropdown-item"
                key={result.id}
                onClick={() => {
                  onSelectResult(result);

                  setResults([]);
                }}
              >
                <div className="font-bold">
                  {result.full_name.split(">")[0]}
                </div>
                <div>{result.full_name.split(">")[1]}</div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default connect(CategorySearch);
