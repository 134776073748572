import Segment from "@/shared/services/Segment";
import { Product } from "@/shared/shop/api";
import { makeTestID } from "@/shared/utils/development";
import { useCartStore } from "@/shop/store";
import React, { useEffect, useState } from "react";
import minusIcon from "./images/minus.svg";
import plusIcon from "./images/plus.svg";
import trashIcon from "./images/trash.svg";

const useStore = () => ({
  cartItems: useCartStore(state => state.cart?.items),
  add: useCartStore(state => state.add),
  remove: useCartStore(state => state.remove),
});

type Props = {
  product: Product;
  disabled?: boolean;
  onChangedItemQuantity?: (
    product: Product,
    quantity: number,
    action: "add" | "remove"
  ) => void;
};

const CartQuantityActions: React.FC<Props> = ({
  product,
  disabled,
  ...props
}: Props) => {
  const { cartItems, add, remove } = useStore();

  const [count, setCount] = useState(0);

  const cartItem = cartItems?.find(item => item.product.id === product.id);
  const quantity = cartItem?.quantity || 0;

  useEffect(() => {
    if (quantity !== 0) {
      setCount(quantity);
    }
    if (quantity === 0) {
      setCount(quantity);
    }
  }, [cartItems, quantity, add, remove]);

  const handleAdd = async e => {
    e.preventDefault();
    const success = await add(product.id);

    if (success) {
      // FIXME: We need to consider renaming this event and/or doing this in the propagated onChangedItemQuantity
      // event.
      Segment.track("web_signup_product_catalogue_add_to_cart", {
        product,
      });

      const newQuantity = quantity + 1;
      setCount(newQuantity);

      if (props.onChangedItemQuantity) {
        props.onChangedItemQuantity(product, newQuantity, "add");
      }
    }
  };
  const handleSubtract = async e => {
    e.preventDefault();

    if (count > 0) {
      const success = await remove(product.id);

      if (success) {
        // FIXME: We need to consider renaming this event and/or doing this in the propagated onChangedItemQuantity
        // event.
        Segment.track("web_signup_product_catalogue_remove_from_cart", {
          product,
        });

        const newQuantity = quantity - 1;

        if (count > 1) {
          setCount(newQuantity);
        }

        if (props.onChangedItemQuantity) {
          props.onChangedItemQuantity(product, newQuantity, "remove");
        }
      }
    }
  };

  const scaleAnimation = `transform motion-reduce:transform-none hover:scale-110 transition ease-in-out duration-300`;

  return (
    <div className="flex w-16 h-12 justify-between flex-row items-center justify-center">
      <button
        type="button"
        className={`focus:outline-none ${scaleAnimation}`}
        disabled={quantity === 0}
        onClick={handleSubtract}
      >
        {quantity === 1 ? (
          <img
            src={trashIcon}
            alt="Remove from cart"
            className="h-5"
            data-testid={makeTestID(
              "productCatalogue",
              "removeFromCart",
              product.short_name
            )}
          />
        ) : (
          <img
            src={minusIcon}
            alt="Decrease quantity"
            className="h-5"
            data-testid={makeTestID(
              "productCatalogue",
              "decreaseQuantity",
              product.short_name
            )}
          />
        )}
      </button>
      <div>{quantity}</div>
      <button
        type="button"
        className={`focus:outline-none ${scaleAnimation}`}
        disabled={disabled}
        onClick={handleAdd}
        data-testid={makeTestID(
          "productCatalogue",
          "increaseQuantity",
          product.short_name
        )}
      >
        <img src={plusIcon} alt="Increase quantity" className="h-5" />
      </button>
    </div>
  );
};

export default CartQuantityActions;
