import CardMain from "@/shared/components/CardMain";
import Field from "@/shared/components/form/Field";
import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import Input from "@/shared/components/form/Input";
import Header from "@/shared/components/Header";
import Segment from "@/shared/services/Segment";
import { makeTestID } from "@/shared/utils/development";
import Layout from "@/signup/components/Layout";
import StepProgressBar from "@/signup/components/StepProgressBar";
import SubmitButton from "@/signup/components/SubmitButton";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import { useSignupStore, useStepStore } from "@/signup/store";
import { SignupStep } from "@yoco/sawubona-sdk";
import { FormikValues } from "formik";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

const useStore = () => ({
  setActiveStep: useStepStore(state => state.setActiveStep),
  signup: useSignupStore(state => state.signup),
  updateSignup: useSignupStore(state => state.updateSignup),
  getNextStepLink: useStepStore(state => state.getNextStepLink),
});

const BusinessUrlPage: React.FC<any> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { setActiveStep, signup, updateSignup, getNextStepLink } = useStore();

  useEffect(() => {
    setActiveStep(SignupStep.BusinessUrlPage);
  }, [setActiveStep]);

  useEffect(() => {
    Segment.track("web_signup_business_url_start");
  }, [location]);

  const initialValues = {
    stage: SignupStep.BusinessUrlPage,
    business_url: signup?.business_url || "",
  };

  const schema = Yup.object().shape({
    business_url: Yup.string()
      .url("Must be a valid URL")
      .required("Business URL is required"),
  });

  const onSubmit = async (value: FormikValues) => {
    const isSignupUpdated = await updateSignup(value);

    if (isSignupUpdated) {
      Segment.trackWithSignup("web_signup_business_url_next_button_clicked");

      navigate(getNextStepLink());
    }
  };

  return (
    <Layout>
      <CardMain>
        <StepProgressBar />
        <Header
          title="What's your business url?"
          description={
            <p className="py-2 text-base">
              We need it to validate your online store.
            </p>
          }
        />
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {(formik: any) => {
            return (
              <Form>
                <div className="mb-8">
                  <Field
                    name="business_url"
                    placeholder="https://www.example.com"
                    component={Input}
                    data-testid={makeTestID("businessUrl", "businessUrl")}
                  />
                </div>

                <SubmitButton testID={makeTestID("businessName", "next")}>
                  Next
                </SubmitButton>
              </Form>
            );
          }}
        </Formik>
      </CardMain>
    </Layout>
  );
};

export default withFetchSignup(BusinessUrlPage);
