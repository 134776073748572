import AddressFormFields, {
  getValidationSchema as getShippingAddressValidationSchema,
} from "@/shared/components/Address/AddressFormFields";
import CardMain from "@/shared/components/CardMain";
import Field from "@/shared/components/form/Field";
import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import Input from "@/shared/components/form/Input";
import SubmitButton from "@/shared/components/form/SubmitButton";
import Header from "@/shared/components/Header";
import { Cart } from "@/shared/shop/api";
import { Address } from "@/shared/types";
import { makeTestID } from "@/shared/utils/development";
import CartSummaryMobile from "@/shop/components/CartSummaryMobile";
import CartSummaryMobileOffset from "@/shop/components/CartSummaryMobileOffset";
import CheckoutSidePanel from "@/shop/components/CheckoutSidePanel";
import Layout from "@/shop/components/Layout";
import {
  getInitialValues as getPromoCodeInitialValues,
  getValidationSchema as getPromoCodeValidationSchema,
} from "@/shop/components/PromoCodeField";
import StepProgressBar from "@/shop/components/StepProgressBar";
import withFetchCart from "@/shop/middleware/withFetchCart";
import withRedirectToCartPageIfCartEmpty from "@/shop/middleware/withRedirectToCartPageIfCartEmpty";
import { useCartStore } from "@/shop/store";
import { ShopRoute } from "@/shop/types";
import { getShippingAddressFromCart } from "@/shop/utils/address";
import { FormikValues } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import * as yup from "yup";

const useStore = () => ({
  cart: useCartStore(state => state.cart),
  updateCart: useCartStore(state => state.updateCart),
});

type InitialShippingAddressValues = {
  initialShippingAddress: Address;
  initialShippingAddressFormValues: { [key: string]: any };
};

const getInitialAddressFormValues = (
  address: Address
): { [key: string]: any } => {
  return {
    address_line1: address.line1,
    address_line2: address.line2,
    suburb: address.suburb,
    city: address.city,
    province: address.province,
    postal_code: address.postal_code,
    country: address.country,
    latitude: address.latitude,
    longitude: address.longitude,
  };
};

const getInitialShippingAddressValues = (
  cart?: Cart | null
): InitialShippingAddressValues => {
  const initialShippingAddress = getShippingAddressFromCart(cart);
  const initialShippingAddressFormValues = getInitialAddressFormValues(
    initialShippingAddress
  );
  return {
    initialShippingAddress,
    initialShippingAddressFormValues,
  };
};

const DeliveryPage: React.FC<any> = () => {
  const navigate = useNavigate();
  const { cart, updateCart } = useStore();
  const { initialShippingAddress, initialShippingAddressFormValues } =
    getInitialShippingAddressValues(cart);

  const [address] = useState(initialShippingAddress);

  const initialValues = {
    shipping_method: "courier",
    shipping_first_name: cart?.shipping_first_name || "",
    shipping_last_name: cart?.shipping_last_name || "",
    shipping_email: cart?.shipping_email || "",
    shipping_mobile_number: cart?.shipping_mobile_number || "",
    ...getPromoCodeInitialValues(cart),
    ...initialShippingAddressFormValues,
  };

  const schema = yup.object({
    shipping_first_name: yup.string().required().label("first name"),
    shipping_last_name: yup.string().required().label("last name"),
    shipping_email: yup.string().email().required().label("email"),
    shipping_mobile_number: (yup as any)
      .string()
      .phone()
      .required()
      .label("mobile number"),
    ...getShippingAddressValidationSchema(),
    ...getPromoCodeValidationSchema(),
  });

  const onSubmit = async (values: FormikValues) => {
    const newValues = { ...values };

    // Set the specific shipping address field values from the generic form address fields
    newValues.shipping_address_line1 = newValues.address_line1;
    newValues.shipping_address_line2 = newValues.address_line2;
    newValues.shipping_suburb = newValues.suburb;
    newValues.shipping_city = newValues.city;
    newValues.shipping_province = newValues.province;
    newValues.shipping_postal_code = newValues.postal_code;
    newValues.shipping_country = newValues.country;
    newValues.shipping_latitude = newValues.latitude;
    newValues.shipping_longitude = newValues.longitude;

    const isCartUpdated = await updateCart(newValues);

    if (isCartUpdated.success) {
      navigate(
        formatRoute(ShopRoute.PaymentPage, {
          id: cart?.id,
        })
      );
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {() => {
        const button = (
          <SubmitButton testID={makeTestID("delivery", "next")}>
            Next
          </SubmitButton>
        );

        return (
          <Form>
            <Layout>
              <CardMain>
                <StepProgressBar number={2} />
                <Header title="Delivery" />
                <div className="grid gap-3">
                  <div className="flex flex-wrap">
                    <div className="w-full sm:w-1/2 mb-3 sm:mb-0 sm:pr-2 rounded">
                      <Field
                        name="shipping_first_name"
                        placeholder="First name *"
                        component={Input}
                        data-testid={makeTestID("delivery", "firstName")}
                      />
                    </div>
                    <div className="w-full sm:w-1/2 sm:pl-2 rounded">
                      <Field
                        name="shipping_last_name"
                        placeholder="Last name *"
                        component={Input}
                        data-testid={makeTestID("delivery", "lastName")}
                      />
                    </div>
                  </div>

                  <div className="flex flex-wrap">
                    <div className="w-full sm:w-1/2 mb-3 sm:mb-0 sm:pr-2 rounded">
                      <Field
                        name="shipping_email"
                        placeholder="Email *"
                        component={Input}
                        data-testid={makeTestID("delivery", "email")}
                      />
                    </div>
                    <div className="w-full sm:w-1/2 sm:pl-2 rounded">
                      <Field
                        name="shipping_mobile_number"
                        placeholder="Mobile number *"
                        component={Input}
                        data-testid={makeTestID("delivery", "mobileNumber")}
                      />
                    </div>
                  </div>

                  <AddressFormFields address={address} />
                  <div className="hidden md:block mt-4">{button}</div>
                </div>
                <CartSummaryMobileOffset />
              </CardMain>
              <CheckoutSidePanel />
              <CartSummaryMobile button={button} />
            </Layout>
          </Form>
        );
      }}
    </Formik>
  );
};

export default withFetchCart(withRedirectToCartPageIfCartEmpty(DeliveryPage));
