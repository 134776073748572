import CONFIG from "@/config";
import { LabelValuePair } from "@/libs/sawubona-sdk/types";
import shop from "@/shared/shop/api";
import { BusinessCategory, sawubona } from "@yoco/sawubona-sdk";
import create from "zustand";
import { devtools } from "zustand/middleware";

export interface FormHelperState {
  areas: LabelValuePair[];
  businessTypes: LabelValuePair[];
  cities: LabelValuePair[];
  businessCategories: BusinessCategory[];
  provinces: LabelValuePair[];
  fetchAreas: (city: string) => void;
  fetchBusinessTypes: () => void;
  fetchBusinessCategories: () => void;
  fetchCities: () => void;
  fetchProvinces: () => void;
}

export const useFormHelperStore = create<FormHelperState>()(
  devtools(
    set => ({
      areas: [],
      businessTypes: [],
      businessCategories: [],
      cities: [],
      provinces: [],
      fetchAreas: async (city: string) => {
        set({ areas: await sawubona.area.list(city) }, false, "fetchAreas");
      },
      fetchBusinessTypes: async () => {
        set(
          { businessTypes: await sawubona.businessTypes.list() },
          false,
          "fetchBusinessTypes"
        );
      },
      fetchBusinessCategories: async () => {
        set(
          { businessCategories: await sawubona.businessCategories.list() },
          false,
          "fetchBusinessCategories"
        );
      },
      fetchCities: async () => {
        set({ cities: await sawubona.city.list() }, false, "fetchCities");
      },
      fetchProvinces: async () => {
        set(
          { provinces: await shop.provinces.list() },
          false,
          "fetchProvinces"
        );
      },
    }),
    { name: "SignupFormHelperStore", enabled: CONFIG.stateDebugEnabled }
  )
);
