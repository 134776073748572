import { SignupStep } from "@yoco/sawubona-sdk";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import CardMain from "@/shared/components/CardMain";
import Header from "@/shared/components/Header";
import Segment from "@/shared/services/Segment";
import { PaymentMethod } from "@/shared/shop/api";
import CartSummaryMobileOffset from "@/shop/components/CartSummaryMobileOffset";
import CheckoutSidePanel from "@/shop/components/CheckoutSidePanel";
import DynamicPaymentMethod from "@/shop/pages/PaymentPage/DynamicPaymentMethod";
import { useCartStore } from "@/shop/store";
import Layout from "@/signup/components/Layout";
import StepProgressBar from "@/signup/components/StepProgressBar";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import withRedirectToProductCatalogueIfCartEmpty from "@/signup/middleware/withRedirectToProductCatalogueIfCartEmpty";
import SignupService from "@/signup/services/SignupService";
import { useSignupStore, useStepStore } from "@/signup/store";
import { SignupRoute } from "@/signup/types";

const useStore = () => ({
  setActiveStep: useStepStore(state => state.setActiveStep),
  intendedPaymentMethod: useCartStore(state => state.intendedPaymentMethod),
  setIntendedPaymentMethod: useCartStore(
    state => state.setIntendedPaymentMethod
  ),
});

const PaymentPage: React.FC<any> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { setActiveStep, intendedPaymentMethod } = useStore();

  useEffect(() => {
    setActiveStep(SignupStep.PaymentPage);
  }, [setActiveStep]);

  useEffect(() => {
    Segment.track("web_signup_payment_start");
  }, [location]);

  const onPaymentSuccess = async (
    paymentMethod: PaymentMethod,
    isPaid?: boolean
  ) => {
    await useSignupStore.getState().refreshSignup();
    const signup = useSignupStore.getState().getSignup();

    Segment.trackWithSignup("web_signup_payment_click_pay_button");

    SignupService.onFinaliseComplete(signup);

    // The CompletePage will by default look at the payment state on the cart.  For certain payment methods which do
    // as async callback to us, we don't know that the order is paid for by the time the customer sees this page.
    // We therefore allow for an "isPaid" query string param to be passed. This only affects what the customer sees
    // on this page, not the actual payment state of the order.
    let url = formatRoute(SignupRoute.CompletePage, { id: signup?.id });
    if (isPaid !== undefined) {
      url += `?isPaid=${isPaid ? "true" : "false"}`;
    }

    navigate(url);
  };

  return (
    <Layout>
      <CardMain>
        <StepProgressBar />
        <Header title="Payment" />
        <DynamicPaymentMethod
          name={intendedPaymentMethod}
          onSuccess={onPaymentSuccess}
        />
        <CartSummaryMobileOffset />
      </CardMain>
      <CheckoutSidePanel isCartEditable={false} />
    </Layout>
  );
};

export default withFetchSignup(
  withRedirectToProductCatalogueIfCartEmpty(PaymentPage)
);
