class Feature {
  public name: string;

  public enabled: boolean;

  public toggleable: boolean;

  public originalValue: boolean;

  constructor(name: string, enabled = false, toggleable = true) {
    this.name = name;
    this.enabled = enabled;
    this.toggleable = toggleable;
    this.originalValue = enabled;
  }
}

export default Feature;
