import ErrorMessage from "@/shared/components/form/ErrorMessage";
import Label from "@/shared/components/form/Label";
import { connect, FieldInputProps, FormikProps } from "formik";
import React from "react";
import { isIOS } from "react-device-detect";

export type SelectOption = {
  value: string;
  label: string;
};

type Props = {
  form: FormikProps<any>;
  field: FieldInputProps<any>;
  label?: string;
  placeholder?: string;
  options: SelectOption[];
  [x: string]: any;
};

const Select: React.FC<Props> = ({
  form: { touched, errors },
  field,
  label,
  placeholder,
  options,
  ...props
}: Props) => {
  return (
    <>
      {label && <Label>{label}</Label>}
      <select
        className={`block placeholder-gray-500 bg-white border rounded w-full mb-0 py-4 px-4 border-gray-400 text-gray-900  leading-tight focus:outline-none focus:shadow-outline focus-within:border-teal-500
        ${errors[field.name] && touched[field.name] && " border-red-500"}
        ${isIOS && " ios-select-fix "}
        `}
        {...field}
        {...props}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options.map(option => (
          <option key={option.label} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {errors[field.name] && touched[field.name] && (
        <ErrorMessage>{errors[field.name] as string}</ErrorMessage>
      )}
    </>
  );
};

export default connect(Select);
