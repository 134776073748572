import { Address } from "@/shared/types";
import { useFormikContext } from "formik";
import Field from "@/shared/components/form/Field";
import Input from "@/shared/components/form/Input";
import Select from "@/shared/components/form/Select";
import { useSharedFormHelperStore } from "@/shared/store/formHelper";
import React, { useCallback, useEffect } from "react";
import CommonAddressFormField, {
  updateCommonAddressValues,
  updateZAAddressValues,
} from "./CommonAddressFormField";

const useStore = () => ({
  fetchProvinces: useSharedFormHelperStore(state => state.fetchProvinces),
  provinces: useSharedFormHelperStore(state => state.provinces),
});

type Props = {
  address?: Address;
  readonly?: boolean;
};

const SouthAfricaAddressFormField: React.FC<Props> = ({
  address,
  readonly = false,
}: Props) => {
  const { setFieldValue, setFieldTouched, validateField } =
    useFormikContext<any>();
  const { fetchProvinces, provinces } = useStore();

  useEffect(() => {
    fetchProvinces();
  }, [fetchProvinces]);

  const onBlurField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;

    // We've hooked in to the onBlur event here, which means that we've actually overridden the native one in the
    // Field component.  We therefore need to mimic the original onBlur business logic which marks the field as touched
    // and kicks off validation.
    setFieldTouched(name, true);
    validateField(name);
  };

  const updateAddressValues = useCallback(
    (adrs?: Address) => {
      updateCommonAddressValues(setFieldValue, adrs, setFieldTouched);
      updateZAAddressValues(setFieldValue, adrs, setFieldTouched);
    },
    [setFieldTouched, setFieldValue]
  );

  useEffect(() => {
    if (!address) {
      return;
    }

    updateAddressValues(address);
  }, [address, setFieldValue, setFieldTouched, updateAddressValues]);

  const handleChange = (data: Address) => {
    updateAddressValues(data);
  };

  return (
    <div>
      <CommonAddressFormField
        addressLine1Placeholder="Building number and street name"
        addressLine2PlaceHolder="Address Line 2 (Optional)"
        onChange={handleChange}
      />
      <div className="grid gap-3">
        <Field
          name="suburb"
          placeholder="Suburb *"
          component={Input}
          disabled={readonly}
          onBlur={onBlurField}
        />
        <Field
          name="city"
          placeholder="City *"
          component={Input}
          disabled={readonly}
          onBlur={onBlurField}
        />
        <Field
          name="postal_code"
          placeholder="Postal code *"
          component={Input}
          disabled={readonly}
          onBlur={onBlurField}
        />
        <Field
          name="province"
          placeholder="Province *"
          component={Select}
          disabled={readonly}
          options={provinces}
          onBlur={onBlurField}
        />
      </div>
    </div>
  );
};

export default SouthAfricaAddressFormField;
