import CONFIG from "@/config";
import { CartSource } from "@/libs/shop-sdk/types";
import shop, { Cart, PaymentMethod } from "@/shared/shop/api";
import { useAppConfigStore } from "@/shop/store";
import { Feature } from "@yoco/feature-flags";
import create from "zustand";
import { devtools } from "zustand/middleware";

export enum CartModifyAction {
  Add,
  Remove,
  Replace,
}

export interface CartState {
  cart: Cart | null;
  cartSource: CartSource;
  intendedPaymentMethod: PaymentMethod;
  setIntendedPaymentMethod: (intendedPaymentMethod: PaymentMethod) => void;
  reset: () => void;
  getOrCreateCart: () => Promise<Cart>;
  getCart: () => Cart;
  setCartSource: (cartSource: CartSource) => void;
  setCart: (cart: Cart | null, action?: string) => void;
  fetchCart: (id: string) => Promise<boolean>;
  createCart: () => Promise<boolean>;
  updateCart: (payload: Record<string, unknown>) => Promise<{
    success: boolean;
    message?: string;
  }>;
  modifyItems: (
    action: CartModifyAction,
    productID: number,
    quantity?: number,
    replacementProductID?: number
  ) => Promise<boolean>;
  add: (productID: number, quantity?: number) => Promise<boolean>;
  remove: (productID: number, quantity?: number) => Promise<boolean>;
  replace: (
    productID: number,
    replacementProductID: number
  ) => Promise<boolean>;
  enableFeatureFlag: (feature: Feature) => Promise<boolean>;
  disableFeatureFlag: (feature: Feature) => Promise<boolean>;
  setCartFeatureFlag: (feature: Feature, state: boolean) => Promise<boolean>;
  processBlackbirdPayment: (
    paymentId: string,
    paymentMethod: PaymentMethod
  ) => Promise<boolean>;
  processBlackbirdCreditCardPaymentAndActivateInstalmentPlan: (
    paymentId: string
  ) => Promise<boolean>;
  expressEFTPaymentIntent: () => Promise<boolean>;
  expressCardOnDeliveryPaymentIntent: () => Promise<boolean>;
}

export const useCartStore = create<CartState>()(
  devtools(
    (set, get) => ({
      cart: null,
      cartSource: CartSource.SHOP,
      intendedPaymentMethod: PaymentMethod.CREDIT_CARD_CHECKOUT,
      setIntendedPaymentMethod: (intendedPaymentMethod: PaymentMethod) => {
        set({ intendedPaymentMethod }, false, "setIntendedPaymentMethod");
      },
      reset: () => {
        get().setCart(null, "reset");
      },
      getOrCreateCart: async () => {
        const { cart } = get();
        if (!cart) {
          const isSuccessful = await get().createCart();

          return isSuccessful
            ? Promise.resolve(get().getCart())
            : Promise.reject(
                new Error("An error occurred creating a shopping cart.")
              );
        }
        return Promise.resolve(cart);
      },
      getCart: () => {
        const { cart } = get();

        if (!cart) {
          throw new Error("The cart is not set.");
        }

        return cart;
      },
      setCartSource: cartSource => {
        set({ cartSource }, false, "setCartSource");
      },
      setCart: (cart, action) => {
        set({ cart }, false, action || "setCart");
      },
      fetchCart: async id => {
        // This will retrieve the cart state from the API backend and save it to local state.  To access the cart from
        // store, you can either call getCart() or access the cart object directly in state.
        try {
          get().setCart(await shop.carts.get(id), "fetchCart");

          return true;
        } catch (error) {
          return false;
        }
      },
      createCart: async () => {
        try {
          const payload = {
            source: get().cartSource,
          } as any;
          const { business } = useAppConfigStore.getState();
          if (business) {
            payload.business_uuid = business.uuid;
            payload.shipping_email = business.owner.email;
            payload.shipping_mobile_number = business.telephone_number;
          }

          get().setCart(await shop.carts.create(payload), "createCart");

          return true;
        } catch (error) {
          return false;
        }
      },
      updateCart: async payload => {
        try {
          const { id } = get().getCart();
          get().setCart(await shop.carts.update(id, payload), "updateCart");

          return { success: true, message: undefined };
        } catch (error: any) {
          return {
            success: false,
            message: (error?.response?.data?.promo_code_str?.[0] ??
              error?.message) as string,
          };
        }
      },
      modifyItems: async (
        action,
        productID,
        quantity = 1,
        replacementProductID = 1
      ) => {
        const cart = await get().getOrCreateCart();

        try {
          if (action === CartModifyAction.Add) {
            set(
              {
                cart: await shop.carts.addProduct(cart.id, productID, quantity),
              },
              false,
              "addToCart"
            );
          } else if (action === CartModifyAction.Remove) {
            set(
              {
                cart: await shop.carts.removeProduct(
                  cart.id,
                  productID,
                  quantity
                ),
              },
              false,
              "removeFromCart"
            );
          } else if (action === CartModifyAction.Replace) {
            set(
              {
                cart: await shop.carts.replaceProduct(
                  cart.id,
                  productID,
                  quantity,
                  replacementProductID
                ),
              },
              false,
              "replaceCartItem"
            );
          }

          return true;
        } catch (error) {
          return false;
        }
      },
      add: async (productID, quantity = 1) => {
        return get().modifyItems(CartModifyAction.Add, productID, quantity);
      },
      remove: async (productID, quantity = 1) => {
        return get().modifyItems(CartModifyAction.Remove, productID, quantity);
      },
      replace: async (productID, replacementProductID) => {
        const quantity = 1;
        return get().modifyItems(
          CartModifyAction.Replace,
          productID,
          quantity,
          replacementProductID
        );
      },
      enableFeatureFlag: async feature => {
        try {
          const { id } = get().getCart();

          // Shop SDK doesn't include the feature-flags lib, so we cast the feature to Record<string, unknown>
          const cart = await shop.carts.enableFeatureFlag(
            id,
            feature as unknown as Record<string, unknown>
          );

          get().setCart(cart, "enableFeatureFlag");

          return true;
        } catch (error) {
          return false;
        }
      },
      disableFeatureFlag: async feature => {
        try {
          const { id } = get().getCart();

          // Shop SDK doesn't include the feature-flags lib, so we cast the feature to Record<string, unknown>
          const cart = await shop.carts.disableFeatureFlag(
            id,
            feature as unknown as Record<string, unknown>
          );

          get().setCart(cart, "disableFeatureFlag");

          return true;
        } catch (error) {
          return false;
        }
      },
      setCartFeatureFlag: async (feature, state) => {
        return state
          ? get().enableFeatureFlag(feature)
          : get().disableFeatureFlag(feature);
      },
      processBlackbirdPayment: async (paymentId, paymentMethod) => {
        try {
          const { id } = get().getCart();
          set(
            {
              cart: await shop.carts.processBlackbirdPayment(
                id,
                paymentId,
                paymentMethod
              ),
            },
            false,
            "processBlackbirdPayment"
          );

          return true;
        } catch (error) {
          return false;
        }
      },
      processBlackbirdCreditCardPaymentAndActivateInstalmentPlan:
        async paymentId => {
          try {
            const { id } = get().getCart();
            set(
              {
                cart: await shop.carts.processBlackbirdCreditCardPaymentAndActivateInstalmentPlan(
                  id,
                  paymentId
                ),
              },
              false,
              "processBlackbirdCreditCardPaymentAndActivateInstalmentPlan"
            );

            return true;
          } catch (error) {
            return false;
          }
        },
      expressEFTPaymentIntent: async () => {
        try {
          const { id } = get().getCart();
          set(
            {
              cart: await shop.carts.expressEFTPaymentIntent(id),
            },
            false,
            "expressEFTPaymentIntent"
          );

          return true;
        } catch (error) {
          return false;
        }
      },
      expressCardOnDeliveryPaymentIntent: async () => {
        try {
          const { id } = get().getCart();
          set(
            {
              cart: await shop.carts.expressCardOnDeliveryPaymentIntent(id),
            },
            false,
            "expressCardOnDeliveryPaymentIntent"
          );

          return true;
        } catch (error) {
          return false;
        }
      },
    }),
    { name: "CartStore", enabled: CONFIG.stateDebugEnabled }
  )
);
