import CONFIG from "@/config";
import HttpClient from "./HttpClient";
import SawubonaApi from "./SawubonaApi";
import {
  BusinessCategory,
  BusinessSubcategory,
  BusinessType,
  Experiment,
  LabelValuePair,
  Platform,
  Service,
  Signup,
  SignupStatus,
  SignupStep,
  ValidationResult,
} from "./types";

const sawubona = new SawubonaApi(CONFIG.apiURL);

export default SawubonaApi;
export {
  BusinessType,
  HttpClient,
  Platform,
  sawubona,
  Service,
  SignupStatus,
  SignupStep,
};
export type {
  BusinessCategory,
  BusinessSubcategory,
  Experiment,
  Signup,
  ValidationResult,
  LabelValuePair,
};
