import Text from "@/shared/components/Text";
import { PaymentMethod, Product } from "@/shared/shop/api";
import CartQuantityActions from "@/shop/components/CartQuantityActions";
import PromoCodeField from "@/shop/components/PromoCodeField";
import SwapGoForKhumo from "@/shop/components/SwapGoForKhumo";
import { useAppConfigStore, useCartStore } from "@/shop/store";
import { ProductID } from "@/shop/types";
import { Features } from "@/signup/types";
import { useFeatureFlags } from "@yoco/feature-flags";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import yocoGoImage from "./images/yoco-go-thumb.png";
import yocoKhumoLadumaImage from "./images/yoco-khumo-laduma-thumb.png";
import yocoKhumoPrintImage from "./images/yoco-khumo-print-thumb.png";
import yocoKhumoImage from "./images/yoco-khumo-thumb.png";
import yocoNeoPlusImage from "./images/yoco-neo-plus-thumb.png";
import yocoNeoImage from "./images/yoco-neo-thumb.png";
import yocoNeoTouchImage from "./images/yoco-neo-touch.png";
import yocoProImage from "./images/yoco-pro-thumb.png";

const YOCO_GO_SKU = "DSQ001";
const MOBILE_VIEW_THRESHHOLD = 768;

// We default to this limit. Once we have a copy in the cart, we use the value set on the product.
// However, doing an extra API call seems unnecessary
const KHUMO_CART_LIMIT_DEFAULT = 1;

const getProductThumbnailImage = (product: Product) => {
  switch (product.sku) {
    case "DSQ001":
      return yocoGoImage;
    case "MIU010":
      return yocoProImage;
    case "BLU55A":
      return yocoNeoImage;
    case "KOZP5":
      return yocoKhumoImage;
    case "KOZP12B":
      return yocoNeoTouchImage;
    case "NXGN86":
      return yocoKhumoPrintImage;
    case "KOZP5L":
      return yocoKhumoLadumaImage;
    case "BLUE55P":
      return yocoNeoPlusImage;
    default:
      return "";
  }
};

const useStore = () => ({
  cart: useCartStore(state => state.cart),
  intendedPaymentMethod: useCartStore(state => state.intendedPaymentMethod),
  setExpandMobileSummary: useAppConfigStore(
    state => state.setExpandMobileSummary
  ),
});

type Props = {
  isCartEditable?: boolean;
};

const CartSummary: React.FC<Props> = ({ isCartEditable = true }: Props) => {
  const [swapOfferVisibility, setSwapOfferVisibility] = useState(false);
  const { cart, intendedPaymentMethod, setExpandMobileSummary } = useStore();
  const features = useFeatureFlags();

  const hasGoInCart = cart?.items.some(
    item => item.product.id === ProductID.GO
  );

  const khumoCartLimit =
    cart?.items.find(item => item.product.id === ProductID.KHUMO_SPECIAL)
      ?.product.max_quantity_per_cart || KHUMO_CART_LIMIT_DEFAULT;

  const khumosInCart = cart
    ? cart.items.filter(item => item.product.id === ProductID.KHUMO_SPECIAL)
        .length
    : 0;

  // eslint-disable-next-line no-restricted-globals
  const isOnPaymentPage = !!location.pathname
    .toLowerCase()
    .endsWith("/payment");

  const isMobileView = window.screen.width < MOBILE_VIEW_THRESHHOLD;

  const isSwapOfferAvailable =
    khumoCartLimit > khumosInCart &&
    hasGoInCart &&
    isOnPaymentPage &&
    features.isEnabled(Features.SWAP_GO_FOR_KHUMO);

  useEffect(() => {
    if (isSwapOfferAvailable) {
      setSwapOfferVisibility(true);

      if (isMobileView) {
        setExpandMobileSummary(true);
      }
    } else {
      setSwapOfferVisibility(false);
    }
  }, [
    isSwapOfferAvailable,
    setSwapOfferVisibility,
    setExpandMobileSummary,
    isMobileView,
  ]);

  if (!cart || isEmpty(cart.items)) {
    return null;
  }

  return (
    <>
      <Text size="h3" className="mb-5">
        Order summary
      </Text>

      {cart.items.map(({ id, quantity, product }) => {
        const isYocoGo = product.sku === YOCO_GO_SKU;

        return (
          <div
            key={id}
            className="flex justify-between flex-row justify-start items-start mb-3"
          >
            <div className="flex justify-between flex-row items-start justify-start">
              <div>
                <img
                  src={getProductThumbnailImage(product)}
                  alt="Card machine"
                  className="justify-items-start mr-4"
                />
              </div>
              <div className="flex justify-between flex-col items-start justify-center">
                <span>
                  {quantity} x {product.short_name}
                </span>

                <span className="font-bold">
                  {product.unit_price_formatted}
                </span>
              </div>
            </div>
            {isCartEditable && (
              <div className="justify-items-end">
                <CartQuantityActions
                  product={product}
                  disabled={quantity >= product.max_quantity_per_cart}
                />
              </div>
            )}

            {isYocoGo && swapOfferVisibility && (
              <div className="py-3">
                <SwapGoForKhumo
                  onDismiss={() => setSwapOfferVisibility(false)}
                />
              </div>
            )}
          </div>
        );
      })}

      <hr className="my-4" />

      <div className="flex justify-between flex-row items-start justify-center">
        <span>Delivery</span>
        <span>Free</span>
      </div>

      {intendedPaymentMethod === PaymentMethod.PAYMENT_TERMS && (
        <div className="flex justify-between flex-row items-start justify-center">
          <span>Instalments service fee</span>
          <span>{cart.instalment_plan_proposal.service_fee_formatted}</span>
        </div>
      )}

      {isCartEditable && (
        <>
          <hr className="my-4" />
          <div className="mt-4">
            <PromoCodeField />
          </div>
        </>
      )}

      {Number(cart.discount) !== 0 && (
        <>
          <hr className="my-4" />

          <div className="flex justify-between flex-row items-start justify-center">
            <span>Discount</span>
            <span>{cart.discount_formatted}</span>
          </div>
        </>
      )}

      <hr className="my-4" />
    </>
  );
};

export default CartSummary;
