import Field from "@/shared/components/form/Field";
import Input from "@/shared/components/form/Input";
import { makeTestID } from "@/shared/utils/development";
import React from "react";

type Props = {
  className?: string;
};

const SerialNumberField: React.FC<Props> = ({ className }: Props) => {
  return (
    <div className={`${className}`}>
      <div className="font-bold">Register a card machine</div>
      <div className="shadow border-2 border-transparent rounded-lg p-3 pt-4 pb-4 text-sm mb-4 mt-2">
        {" "}
        Enter the serial number on your existing card machine
        <div className="flex flex-row pb-2 pt-2">
          <div className="w-full">
            <Field
              name="serial_number"
              placeholder="000 0000"
              component={Input}
              data-testid={makeTestID("registerReader", "serialNumber")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SerialNumberField;
