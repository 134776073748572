import CardMain from "@/shared/components/CardMain";
import Field from "@/shared/components/form/Field";
import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import Input from "@/shared/components/form/Input";
import Header from "@/shared/components/Header";
import Segment from "@/shared/services/Segment";
import { makeTestID } from "@/shared/utils/development";
import Layout from "@/signup/components/Layout";
import StepProgressBar from "@/signup/components/StepProgressBar";
import SubmitButton from "@/signup/components/SubmitButton";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import Validator from "@/signup/services/Validator";
import { useSignupStore, useStepStore } from "@/signup/store";
import { SignupRoute } from "@/signup/types";
import { SignupStep } from "@yoco/sawubona-sdk";
import { FormikValues } from "formik";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import * as yup from "yup";

const useStore = () => ({
  setActiveStep: useStepStore(state => state.setActiveStep),
  signup: useSignupStore(state => state.signup),
  finaliseSignup: useSignupStore(state => state.finaliseSignup),
});

const RegisterReaderPage: React.FC<any> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setActiveStep, signup, finaliseSignup } = useStore();

  useEffect(() => {
    setActiveStep(SignupStep.RegisterReaderPage);
  }, [setActiveStep]);

  useEffect(() => {
    Segment.track("web_signup_retail_start");
  }, [location]);

  const initialValues = {
    stage: SignupStep.RegisterReaderPage,
    serial_number: signup?.serial_number || "",
  };

  yup.addMethod(
    yup.string,
    "serialNumberAvailable",
    Validator.validateSerialNumberAvailable
  );

  const schema = yup.object({
    serial_number: (yup as any)
      .string()
      .serialNumberAvailable()
      .required()
      .label("serial number"),
  });

  const onSubmit = async (values: FormikValues) => {
    const isSignupFinalised = await finaliseSignup({
      serial_number: values.serial_number,
    });

    if (isSignupFinalised) {
      Segment.trackWithSignup(
        "web_signup_retail_click_activate_card_machine_button"
      );

      navigate(
        formatRoute(SignupRoute.CompletePage, {
          id: signup?.id,
        })
      );
    }
  };

  return (
    <Layout>
      <CardMain>
        <StepProgressBar />
        <Header
          title="Enter your serial number"
          description="The serial number appears on the back of your card machine, to the left of the bar code."
        />

        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {() => (
            <Form>
              <div className="mb-8">
                <Field
                  name="serial_number"
                  placeholder="1234"
                  component={Input}
                  data-testid={makeTestID("registerReader", "serialNumber")}
                />
              </div>

              <SubmitButton testID={makeTestID("registerReader", "activate")}>
                Activate card machine
              </SubmitButton>
            </Form>
          )}
        </Formik>
      </CardMain>
    </Layout>
  );
};

export default withFetchSignup(RegisterReaderPage);
