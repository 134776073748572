import React from "react";

type Props = {
  children: React.ReactNode;
  wider?: boolean;
};

const CardMain: React.FC<Props> = ({ children, wider }: Props) => {
  return (
    <div
      className={`card bg-white p-0 px-4 sm:px-8 md:py-8 sm:pb-16 mb-4 md:w-full rounded-xl ${
        wider ? "lg:w-auto max-w-6xl" : ""
      }`}
      style={{ maxWidth: wider ? "default" : "680px" }}
    >
      {children}
    </div>
  );
};

export default CardMain;
