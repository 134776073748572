import { LabelValuePair } from "../types";
import BaseApi from "./BaseApi";

class ProvinceApi extends BaseApi {
  list(): Promise<LabelValuePair[]> {
    return this.http.get("/shop/api/v1/provinces/");
  }
}

export default ProvinceApi;
