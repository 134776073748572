import Header from "@/shared/components/Header";
import React from "react";
import deliveryImage from "./delivery-icon.svg";

const PaymentCompleteContent: React.FC<any> = () => {
  return (
    <div className="text-base flex flex-1 flex-col">
      <Header
        title="We’ve got your order"
        description="Your order is on its way. Check your email for more details."
      />
      <img
        src={deliveryImage}
        alt="Yoco POS on phone and tablet"
        className="mx-auto"
      />
    </div>
  );
};

export default PaymentCompleteContent;
