import { Address } from "@/shared/types";
import Field from "@/shared/components/form/Field";
import Input from "@/shared/components/form/Input";
import Select from "@/shared/components/form/Select";
import { useFormHelperStore } from "@/signup/store";
import { useFormikContext } from "formik";
import React, { useCallback, useEffect } from "react";
import CommonAddressFormField, {
  updateCommonAddressValues,
  updateEGAddressValues,
} from "./CommonAddressFormField";

const useStore = () => ({
  fetchAreas: useFormHelperStore(state => state.fetchAreas),
  areas: useFormHelperStore(state => state.areas),
  fetchCities: useFormHelperStore(state => state.fetchCities),
  cities: useFormHelperStore(state => state.cities),
});

const EgyptAddressFormField = () => {
  const { setFieldValue, setFieldTouched, validateField } =
    useFormikContext<any>();
  const { fetchAreas, areas, fetchCities, cities } = useStore();

  useEffect(() => {
    fetchCities();
  }, [fetchCities]);

  useEffect(() => {
    // For our initial launch in Egypt, we will only support Cairo, other cities will be added later
    fetchAreas("Cairo");
  }, [fetchAreas]);

  const onBlurField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;

    // We've hooked in to the onBlur event here, which means that we've actually overridden the native one in the
    // Field component.  We therefore need to mimic the original onBlur business logic which marks the field as touched
    // and kicks off validation.
    setFieldTouched(name, true);
    validateField(name);
  };

  const updateAddressValues = useCallback(
    (adrs?: Address) => {
      updateCommonAddressValues(setFieldValue, adrs, setFieldTouched);
      updateEGAddressValues(setFieldValue, adrs, setFieldTouched);
    },
    [setFieldTouched, setFieldValue]
  );

  const handleChange = (data: Address) => {
    updateAddressValues(data);
  };

  const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    // A value ,representing city and code ["value": "00001,Cairo"]
    const cityDetails = value.split(",");
    if (cityDetails.length === 2) {
      setFieldValue("cityCode", cityDetails[0]);
      setFieldValue("city", cityDetails[1]);
    }
  };

  return (
    <div>
      <div className="grid gap-3">
        <CommonAddressFormField
          addressLine1Placeholder="Building number and street name"
          addressLine2PlaceHolder="Address Line 2 (Optional)"
          onChange={handleChange}
        />

        <Field
          name="description"
          placeholder="Further description (Optional)"
          component={Input}
          onBlur={onBlurField}
        />

        <Field
          name="city"
          placeholder="City *"
          component={Select}
          options={cities}
          onBlur={onBlurField}
          onChange={handleCityChange}
        />

        <Field
          name="area"
          placeholder="Area *"
          component={Select}
          options={areas}
          onBlur={onBlurField}
        />
      </div>
    </div>
  );
};

export default EgyptAddressFormField;
