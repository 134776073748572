import queryString from "query-string";
import { formatRoute } from "react-router-named-routes";

export const getBaseUrl = () => {
  return `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ""
  }`;
};

export const toRoute = (
  routeName: string,
  routeParams = {},
  queryStringParams = {}
) => {
  const route = formatRoute(routeName, routeParams);
  const url = getBaseUrl() + route;
  return toUrlWithQueryStringParams(url, queryStringParams);
};

export const toUrlWithQueryStringParams = (
  url: string,
  queryStringParams: any
) => {
  const qs = toQueryString(queryStringParams);
  return qs ? `${url}?${qs}` : url;
};

export const toRouteWithQueryStringParams = (
  routeName: string,
  routeParams: any,
  queryStringParams: any
) => {
  const url = toRoute(routeName, routeParams);
  return toUrlWithQueryStringParams(url, queryStringParams);
};

export const toQueryString = (queryStringParams: any) => {
  const str: string[] = [];

  Object.keys(queryStringParams).forEach(key => {
    // Instead of turning null to 'null', convert to empty string
    let value = queryStringParams[key];
    if (value === null) {
      value = "";
    }

    str.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
  });

  return str.join("&");
};

export const getQueryStringParams = () => {
  return queryString.parse(window.location.search);
};

export const getQueryStringParam = (key: string): any => {
  return getQueryStringParams()[key];
};
