import React from "react";
import logoImage from "./logo.svg";

const Logo: React.FC<any> = () => {
  return (
    <div className="logo">
      <img
        className="yoco-logo transform motion-reduce:transform-none hover:-translate-x-1w hover:scale-110 transition ease-in-out duration-300"
        style={{
          width: "100px",
        }}
        src={logoImage}
        alt="Yoco Logo"
      />
    </div>
  );
};

export default Logo;
