import CONFIG from "@/config";
import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import SubmitButton from "@/shared/components/form/SubmitButton";
import Loader from "@/shared/components/Loader";
import shop, { PaymentMethod } from "@/shared/shop/api";
import { makeTestID } from "@/shared/utils/development";
import CartSummaryMobile from "@/shop/components/CartSummaryMobile";
import { useCartStore } from "@/shop/store";
import { FormikValues } from "formik";
import React, { useEffect, useState } from "react";
import useScript from "react-script-hook";

type Props = {
  amount?: string;
  onSubmit?: (values: FormikValues) => Promise<boolean>;
  onTransactionComplete?: (result: any) => Promise<any>;
  apiPublicKey?: string;
  paymentMethod?: PaymentMethod;
};

enum InstantEFTState {
  INITIAL,
  LOADING,
  INFLIGHT,
}

const useStore = () => ({
  cart: useCartStore(state => state.cart),
  intendedPaymentMethod: useCartStore(state => state.intendedPaymentMethod),
});

const blackbirdEftContainerId = "#blackbird-instant-eft-container";
const yocoOnlineAuthContentClass = "yoco-online-auth-content";

const InstantEft: React.FC<Props> = ({
  amount,
  onTransactionComplete,
  apiPublicKey = CONFIG.blackbirdPublicKey,
  paymentMethod = PaymentMethod.INSTANT_EFT_BLACKBIRD,
  ...props
}: Props) => {
  const { cart } = useStore();
  const [isScriptLoading, scriptError] = useScript({
    src: CONFIG.blackbirdSDKUrl,
    checkForExisting: false,
  });
  const [blackbirdEftSdk, setBlackbirdEftSdk] = useState<any>();
  const [instantEFTState, setInstantEFTState] = useState<InstantEFTState>(
    InstantEFTState.INITIAL
  );
  const isScriptLoaded = !isScriptLoading && scriptError === null;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (isScriptLoaded && window.BlackbirdSDK) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const sdk = new window.BlackbirdSDK({
        publicKey: apiPublicKey,
      });

      sdk.mount({ eftContainer: blackbirdEftContainerId });

      setBlackbirdEftSdk(sdk.eft());
    }
  }, [isScriptLoaded, setBlackbirdEftSdk, apiPublicKey]);

  const waitForIframeToBeLoaded = () => {
    const iframeList = Array.from(document.getElementsByTagName("iframe"));
    const iframe = iframeList.filter(i =>
      i.getAttribute("class")?.includes(yocoOnlineAuthContentClass)
    );

    if (iframe.length > 0) {
      setInstantEFTState(InstantEFTState.INFLIGHT);
      return;
    }
    window.setTimeout(waitForIframeToBeLoaded, 100);
  };

  const onSubmit = async (values: FormikValues) => {
    try {
      setInstantEFTState(InstantEFTState.LOADING);

      const isOnSubmitSuccessful = props.onSubmit
        ? await props.onSubmit(values)
        : true;

      if (isOnSubmitSuccessful) {
        const initiatePaymentResponse =
          await shop.carts.initiateBlackbirdPayment(
            cart?.id || "",
            paymentMethod,
            false,
            amount
          );

        const result = await blackbirdEftSdk
          .submit({
            id: initiatePaymentResponse.id,
          })
          .then(waitForIframeToBeLoaded());

        if (onTransactionComplete) {
          await onTransactionComplete(result);
        }
      } else {
        setInstantEFTState(InstantEFTState.INITIAL);
      }
    } catch (error) {
      setInstantEFTState(InstantEFTState.INITIAL);
    }
  };

  return (
    <Formik onSubmit={onSubmit}>
      {() => {
        const button = (
          <SubmitButton testID={makeTestID("payment", "payByInstantEft")}>
            Pay by EFT
          </SubmitButton>
        );

        return (
          <div>
            <Form>
              {instantEFTState === InstantEFTState.INITIAL && (
                <>
                  <p className="block text-base">
                    Paying with EFT uses a secure payment solution that
                    facilitates instant EFT payments from all major South
                    African banks
                  </p>
                  <br />
                  <p className="block text-base font-bold pb-1">
                    How does it work?
                  </p>
                  <p className="block text-base">
                    1. Click ‘Pay by EFT’ to confirm your order.
                    <br />
                    2. Select your bank and login securely using your internet
                    banking details.
                    <br />
                    3. Select an account to pay from.
                    <br />
                    4. Your bank may send you an OTP or require you to
                    authenticate payment within the banking app.
                    <br />
                  </p>
                  <div className="hidden md:block mt-8">{button}</div>
                  <CartSummaryMobile isCartEditable={false} button={button} />
                </>
              )}
            </Form>

            {instantEFTState === InstantEFTState.LOADING && <Loader />}
            <div id="blackbird-instant-eft-container" />
          </div>
        );
      }}
    </Formik>
  );
};

export default InstantEft;
