import AreaApi from "@/libs/sawubona-sdk/api/AreaApi";
import BusinessCategoryApi from "./api/BusinessCategoryApi";
import BusinessTypeApi from "./api/BusinessTypeApi";
import CityApi from "./api/CityApi";
import ProvinceApi from "./api/ProvinceApi";
import SignupApi from "./api/SignupApi";
import ValidationApi from "./api/ValidationApi";
import HttpClient from "./HttpClient";

class SawubonaApi {
  public http: HttpClient;

  public area: AreaApi;

  public businessTypes: BusinessTypeApi;

  public businessCategories: BusinessCategoryApi;

  public city: CityApi;

  // Right now the provinceApi is coupled to the shop backend, even though it's shared functionality across signup/shop.
  // This will have to change when shop/signup are eventually decoupled
  public provinces: ProvinceApi;

  public signups: SignupApi;

  public validation: ValidationApi;

  constructor(apiURL = "https://hello.yoco.com/sawubona") {
    this.http = new HttpClient(apiURL);
    this.area = new AreaApi(this.http);
    this.businessTypes = new BusinessTypeApi(this.http);
    this.businessCategories = new BusinessCategoryApi(this.http);
    this.city = new CityApi(this.http);
    this.provinces = new ProvinceApi(this.http);
    this.signups = new SignupApi(this.http);
    this.validation = new ValidationApi(this.http);
  }
}

export default SawubonaApi;
