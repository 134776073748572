import AddressFormFields, {
  getValidationSchema as getShippingAddressValidationSchema,
} from "@/shared/components/Address/AddressFormFields";
import CardMain from "@/shared/components/CardMain";
import Field from "@/shared/components/form/Field";
import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import Group from "@/shared/components/form/Group";
import Input from "@/shared/components/form/Input";
import Radio from "@/shared/components/form/Radio";
import RadioGroup from "@/shared/components/form/RadioGroup";
import Header from "@/shared/components/Header";
import Segment from "@/shared/services/Segment";
import { Cart } from "@/shared/shop/api";
import { Address } from "@/shared/types";
import { createBlankAddress, isAddressPopulated } from "@/shared/utils/address";
import { makeTestID } from "@/shared/utils/development";
import CartSummaryMobile from "@/shop/components/CartSummaryMobile";
import CartSummaryMobileOffset from "@/shop/components/CartSummaryMobileOffset";
import CheckoutSidePanel from "@/shop/components/CheckoutSidePanel";
import {
  getInitialValues as getPromoCodeInitialValues,
  getValidationSchema as getPromoCodeValidationSchema,
} from "@/shop/components/PromoCodeField";
import { useCartStore } from "@/shop/store";
import { getShippingAddressFromCart } from "@/shop/utils/address";
import Layout from "@/signup/components/Layout";
import StepProgressBar from "@/signup/components/StepProgressBar";
import SubmitButton from "@/signup/components/SubmitButton";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import withRedirectToProductCatalogueIfCartEmpty from "@/signup/middleware/withRedirectToProductCatalogueIfCartEmpty";
import { useSignupStore, useStepStore } from "@/signup/store";
import { Features } from "@/signup/types";
import { getTradingAddressFromSignup } from "@/signup/utils/address";
import { useFeatureFlag } from "@yoco/feature-flags";
import { Signup, SignupStep } from "@yoco/sawubona-sdk";
import { FormikValues } from "formik";
import { isMatch } from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";

const useStore = () => ({
  setActiveStep: useStepStore(state => state.setActiveStep),
  cart: useCartStore(state => state.cart),
  updateCart: useCartStore(state => state.updateCart),
  signup: useSignupStore(state => state.signup),
  updateSignup: useSignupStore(state => state.updateSignup),
  getNextStepLink: useStepStore(state => state.getNextStepLink),
});

type InitialShippingAddressValues = {
  isInitialSameAsTradingAddress: boolean;
  initialShippingAddress: Address;
  initialShippingAddressFormValues: { [key: string]: any };
};

const getInitialAddressFormValues = (
  address: Address,
  isDeliverySameAsTradingAddress: boolean
): { [key: string]: any } => {
  return {
    is_same_as_trading_address: isDeliverySameAsTradingAddress,
    address_line1: address.line1,
    address_line2: address.line2,
    suburb: address.suburb,
    city: address.city,
    province: address.province,
    postal_code: address.postal_code,
    country: address.country,
    latitude: address.latitude,
    longitude: address.longitude,
  };
};

const getInitialShippingAddressValues = (
  signup: Signup | null,
  cart?: Cart | null
): InitialShippingAddressValues => {
  const tradingAddress = getTradingAddressFromSignup(signup);
  const shippingAddress = getShippingAddressFromCart(cart);
  const hasShippingAddress = isAddressPopulated(shippingAddress);

  // If the cart already has a shipping address value assigned, use that. Otherwise see if the trading address
  // must be used for the shipping address. Otherwise use blank as the initial values.
  const initialShippingAddress = hasShippingAddress
    ? shippingAddress
    : tradingAddress;
  const isSameAsTradingAddress = isMatch(
    initialShippingAddress,
    tradingAddress
  );
  const initialShippingAddressFormValues = getInitialAddressFormValues(
    initialShippingAddress,
    isSameAsTradingAddress
  );

  return {
    isInitialSameAsTradingAddress: isSameAsTradingAddress,
    initialShippingAddress,
    initialShippingAddressFormValues,
  };
};

const DeliveryPage: React.FC<any> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isOwnerDetailsWithIDNumberEnabled = useFeatureFlag(
    Features.OWNER_DETAILS_WITH_ID_NUMBER
  );

  const {
    setActiveStep,
    cart,
    updateCart,
    signup,
    updateSignup,
    getNextStepLink,
  } = useStore();

  useEffect(() => {
    setActiveStep(SignupStep.DeliveryPage);
  }, [setActiveStep]);

  const {
    isInitialSameAsTradingAddress,
    initialShippingAddress,
    initialShippingAddressFormValues,
  } = getInitialShippingAddressValues(signup, cart);

  const [isSameAsTradingAddress, setSameAsTradingAddress] = useState(
    isInitialSameAsTradingAddress
  );

  const [address, setAddress] = useState(initialShippingAddress);

  useEffect(() => {
    if (isSameAsTradingAddress) {
      setAddress(getTradingAddressFromSignup(signup));
    }
  }, [isSameAsTradingAddress, signup]);

  useEffect(() => {
    Segment.track("web_signup_delivery_start");
    Segment.track("web_signup_delivery_click_delivery_button");
  }, [location]);

  const initialValues = {
    ...getPromoCodeInitialValues(cart),
    ...initialShippingAddressFormValues,
    shipping_method: "courier",
    owner_first_name: signup?.owner_first_name || "",
    owner_last_name: signup?.owner_last_name || "",
    owner_document_type: signup?.owner_document_type || "sa_id_number",
    owner_id_passport_number: signup?.owner_id_passport_number || "",
  };

  const schema = yup.object({
    ...getPromoCodeValidationSchema(),
    ...getShippingAddressValidationSchema(),
    owner_first_name: yup.string().required().label("business owner name(s)"),
    owner_last_name: yup.string().required().label("business owner surname"),
    owner_document_type: yup.string().required().label("document type"),
    owner_id_passport_number: yup
      .string()
      .required()
      .label("ID number / passport number"),
    is_same_as_trading_address: yup.bool().label("Same as trading address"),
  });

  const onSubmit = async (values: FormikValues) => {
    const newValues = { ...values };

    newValues.stage = SignupStep.DeliveryPage;
    newValues.shipping_email = signup?.email || "";
    newValues.shipping_mobile_number = signup?.mobile_number || "";
    newValues.shipping_first_name = newValues.owner_first_name || "";
    newValues.shipping_last_name = newValues.owner_last_name || "";

    // Set the specific shipping address field values from the generic form address fields
    newValues.shipping_address_line1 = newValues.address_line1;
    newValues.shipping_address_line2 = newValues.address_line2;
    newValues.shipping_suburb = newValues.suburb;
    newValues.shipping_city = newValues.city;
    newValues.shipping_province = newValues.province;
    newValues.shipping_postal_code = newValues.postal_code;
    newValues.shipping_country = newValues.country;
    newValues.shipping_latitude = newValues.latitude;
    newValues.shipping_longitude = newValues.longitude;

    const isCartUpdated = await updateCart(newValues);

    if (isCartUpdated.success) {
      const isSignupUpdated = await updateSignup(newValues);

      if (isSignupUpdated) {
        Segment.trackWithSignup("web_signup_delivery_click_next_button");

        navigate(getNextStepLink());
      }
    }
  };

  const onChangeIsDeliveryAddressTheSameAsTradingAddress = e => {
    setSameAsTradingAddress(e.target.checked);
    setAddress(createBlankAddress());
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {formik => {
        const button = (
          <SubmitButton testID={makeTestID("delivery", "next")}>
            Next
          </SubmitButton>
        );

        return (
          <Form>
            <Layout>
              <CardMain>
                <StepProgressBar />
                <Header
                  title="Delivery"
                  description="Where can we deliver your card machine?"
                />
                <div className="grid gap-3">
                  <label className="block">
                    <Field
                      name="is_same_as_trading_address"
                      type="checkbox"
                      className="form-checkbox text-yoco-green mr-2"
                      checked={isSameAsTradingAddress}
                      onChange={
                        onChangeIsDeliveryAddressTheSameAsTradingAddress
                      }
                      data-testid={makeTestID(
                        "delivery",
                        "deliveryAddress",
                        "sameAsTrading"
                      )}
                    />
                    Deliver to the same as trading address
                  </label>
                  <Group label="Delivery address">
                    <AddressFormFields
                      address={address}
                      readonly={isSameAsTradingAddress}
                    />
                  </Group>

                  {!isOwnerDetailsWithIDNumberEnabled && (
                    <>
                      <Group label="Business owner details *">
                        <div className="flex flex-wrap">
                          <div className="w-full sm:w-1/2 mb-3 sm:mb-0 sm:pr-2 rounded">
                            <Field
                              name="owner_first_name"
                              placeholder="Name(s) *"
                              disabled
                              component={Input}
                              data-testid={makeTestID("delivery", "firstName")}
                            />
                          </div>
                          <div className="w-full sm:w-1/2 sm:pl-2 rounded">
                            <Field
                              name="owner_last_name"
                              placeholder="Surname *"
                              disabled
                              component={Input}
                              data-testid={makeTestID("delivery", "lastName")}
                            />
                          </div>
                        </div>
                      </Group>

                      <Group name="owner_id_passport_number">
                        <RadioGroup name="owner_document_type">
                          <div className="flex flex-col sm:flex-row">
                            <Field
                              name="owner_document_type"
                              type="radio"
                              value="sa_id_number"
                              label="South African ID"
                              component={Radio}
                              data-testid={makeTestID("delivery", "id")}
                            />
                            <Field
                              name="owner_document_type"
                              type="radio"
                              value="foreign_passport"
                              label="Foreign Passport"
                              component={Radio}
                              className="sm:ml-6"
                              data-testid={makeTestID("delivery", "passport")}
                            />
                          </div>
                        </RadioGroup>
                        <Field
                          name="owner_id_passport_number"
                          placeholder={
                            formik.values.owner_document_type ===
                            "foreign_passport"
                              ? "Passport number *"
                              : "ID number *"
                          }
                          component={Input}
                          data-testid={makeTestID(
                            "delivery",
                            "idPassport",
                            "number"
                          )}
                        />
                      </Group>
                    </>
                  )}

                  <div className="hidden md:block mt-4">{button}</div>
                </div>
                <CartSummaryMobileOffset />
              </CardMain>
              <CheckoutSidePanel />
              <CartSummaryMobile button={button} />
            </Layout>
          </Form>
        );
      }}
    </Formik>
  );
};

export default withFetchSignup(
  withRedirectToProductCatalogueIfCartEmpty(DeliveryPage)
);
