import React from "react";
import preloaderImage from "./preloader.gif";

type Props = {
  show?: boolean;
};

const Preloader: React.FC<Props> = ({ show }: Props) => {
  const styles: any = {
    popupOverlay: {
      position: "fixed",
      background: "white",
      display: `${show ? "flex" : "none"}`,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1000,
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    popupText: {
      color: "white",
    },
    imagePreload: {
      width: "200px",
    },
  };

  return (
    <div style={styles.popupOverlay} className="p-3">
      <div>
        <img
          style={styles.imagePreload}
          src={preloaderImage}
          alt="Loading..."
        />
      </div>
      <div className="pt-3" style={styles.popupText}>
        Loading...
      </div>
    </div>
  );
};

export default Preloader;
