export function getParentIndex(array: any[], attribute: string): number {
  for (let i = 0; i < array.length; i += 1) {
    const o = array[i];
    const tmp = JSON.stringify(o);
    if (tmp.indexOf(attribute) !== -1) {
      return i;
    }
  }

  return -1;
}

export function percentage(partialValue: number, totalValue: number): number {
  if (totalValue === 0) {
    return 0;
  }

  return Math.round((100 * partialValue) / totalValue);
}

export function executeScroll(element: HTMLDivElement): void {
  setTimeout(() => {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }, 500);
}
