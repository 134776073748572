import Text from "@/shared/components/Text";
import { percentage } from "@/shared/utils/helpers/general";
import { useStepStore } from "@/signup/store";
import { SignupStep } from "@yoco/sawubona-sdk";
import React from "react";
import { useNavigate } from "react-router-dom";
import arrowIcon from "./arrow-left.svg";

const useStore = () => ({
  steps: useStepStore(state => state.steps),
  activeStep: useStepStore(state => state.activeStep),
  getPreviousStep: useStepStore(state => state.getPreviousStep),
  getPreviousStepLink: useStepStore(state => state.getPreviousStepLink),
});

const StepProgressBar: React.FC<any> = () => {
  const { steps, activeStep, getPreviousStep, getPreviousStepLink } =
    useStore();
  const navigate = useNavigate();

  const currentStepIndex = steps.indexOf(activeStep);
  const currentStepNumber = currentStepIndex >= 0 ? currentStepIndex + 1 : 1;
  const totalSteps = steps.length;
  const calculatedPercentage = percentage(currentStepNumber, totalSteps);

  const previousStep = getPreviousStep();
  const isBackButtonVisible =
    previousStep &&
    previousStep !== SignupStep.HomePage &&
    activeStep !== SignupStep.CompletePage;

  const onClickBackToPreviousStep = () => {
    navigate(getPreviousStepLink());
  };

  const backButton = (
    <button
      type="button"
      className="w-full py-4 pr-8"
      onClick={onClickBackToPreviousStep}
    >
      <img src={arrowIcon} alt="Arrow" />
    </button>
  );

  if (currentStepNumber > 1 && currentStepNumber < totalSteps) {
    return (
      <div className="w-full mb-4 sm:mb-8 grid gap-6 pt-4">
        <div className="flex flex-wrap items-center">
          <div className="flex-1 flex-shrink hidden md:block">
            {isBackButtonVisible && backButton}
          </div>
          <Text className="hidden md:block">
            Step {currentStepNumber}/{totalSteps}
          </Text>
        </div>
        <div className="flex items-center">
          <div className="w-1/6 md:hidden">
            {isBackButtonVisible && backButton}
          </div>
          <div className="w-4/6 md:w-full">
            <div className="bg-yoco-gray rounded">
              <div
                className="bg-yoco-green rounded text-xs leading-none h-1 text-center text-white"
                style={{ width: `${calculatedPercentage}%` }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default StepProgressBar;
