import { Config } from "@/config";
import { CountryCode } from "@/libs/sawubona-sdk/types";
import { UserJourney } from "@/signup/types";
import { makeFeatureEnvConfig } from "./utils";

const CONFIG: Config = makeFeatureEnvConfig("zebra", {
  countryCode: CountryCode.Egypt,
  portalURL:
    "https://yoco.retool.com/apps/813f2190-5698-11ed-8350-4332883d80d7/Egypt%20Merchant%20Onboarding",
  defaultUserJourney: UserJourney.EGYPT,
});

export default CONFIG;
