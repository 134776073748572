import { PaymentMethod } from "@/shared/shop/api";
import React from "react";

export interface PaymentMethodNavItem {
  paymentMethod: PaymentMethod;
  secondaryPaymentMethods?: PaymentMethod[];
  label: string;
  description?: string | React.ReactElement;
  subLabel: string | React.ReactElement;
  icon?: string;
}

export interface PaymentMethodProps {
  onSuccess?: (paymentMethod: PaymentMethod, isPaid?: boolean) => void;
}

export enum ShopRoute {
  HomePage = "/shop/",
  ExistingBusinessHomePage = "/shop/business/:id/",
  ProductCataloguePage = "/shop/products/",
  CartPage = "/shop/:id/cart/",
  DeliveryPage = "/shop/:id/delivery/",
  PaymentPage = "/shop/:id/payment/",
  StitchInstantEFTResultPage = "/shop/stitch_instant_eft_result/",
  CompletePage = "/shop/:id/complete/",
}

// These should match the product IDs on production. Behaviour will differ on staging and dev.
export enum ProductID {
  GO = 1,
  KHUMO_SPECIAL = 9,
}
