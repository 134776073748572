import React, { useEffect, useMemo } from "react";
import CONFIG from "@/config";
import Header from "@/shared/components/Header";
import Loader from "@/shared/components/Loader";
import Segment from "@/shared/services/Segment";
import { useSignupStore } from "@/signup/store";
import { useWaitUntilMerchantAccountIsCreated } from "@/signup/utils/hooks";

const useStore = () => ({
  yocoAuthToken: useSignupStore(state => state.yocoAuthToken),
});

const AutoLoginToPortalContent: React.FC<any> = () => {
  const { yocoAuthToken } = useStore();
  const { isMerchantAccountCreated, merchantAccountError } =
    useWaitUntilMerchantAccountIsCreated();

  useEffect(() => {
    if (isMerchantAccountCreated && yocoAuthToken) {
      const portalUrl = `${CONFIG.portalURL}?yat=${yocoAuthToken}`;
      window.location.replace(portalUrl);
    }
  }, [isMerchantAccountCreated, yocoAuthToken]);

  const onClickContinueToPortalButton = () => {
    const portalUrl =
      yocoAuthToken && isMerchantAccountCreated
        ? `${CONFIG.portalURL}?yat=${yocoAuthToken}`
        : CONFIG.portalURL;

    Segment.track("web_signup_confirmation_complete_portal_button_clicked");
    window.location.replace(portalUrl);
  };

  const canClickContinue = useMemo(() => {
    if (merchantAccountError) {
      return true;
    }
    return isMerchantAccountCreated;
  }, [isMerchantAccountCreated, merchantAccountError]);

  return (
    <div className="text-base flex flex-1 flex-col">
      <Header
        title="Congrats!"
        description={
          <>
            <p className="font-semibold">Creating your Yoco Profile...</p>
          </>
        }
      />
      <div className="pb-6 pt-2 leading-8">
        <Loader size="lg" />
        <div className="items-center flex flex-col pt-8">
          <div className="text-center pb-6">
            You're about to enter the Business Portal, where you'll complete
            your profile setup and verification for payouts.
          </div>
          <button
            type="button"
            className="block mx-auto w-full sm:w-9/12 bg-yoco-green hover:bg-yoco-light-green text-white font-bold py-3 px-8 rounded focus:outline-none focus:shadow-outline"
            onClick={onClickContinueToPortalButton}
            disabled={!canClickContinue}
          >
            <span className="font-bold text-base">Complete your setup</span>
          </button>
          <span className="pt-4 text-center">
            Not getting redirected? Click the "Complete your setup" button to
            start your setup now.
          </span>
        </div>
      </div>
    </div>
  );
};

export default AutoLoginToPortalContent;
