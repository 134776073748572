import React from "react";

type Props = {
  children: React.ReactNode;
  medium?: boolean;
};

const Label: React.FC<Props> = ({ medium, children }: Props) => {
  return (
    <label className={`block ${medium ? "font-medium" : "font-bold"} text-sm`}>
      {children}
    </label>
  );
};

export default Label;
