import { Address } from "@/shared/types";
import { Signup } from "@yoco/sawubona-sdk";
import CONFIG from "@/config";

export const getTradingAddressFromSignup = (signup: Signup | null): Address => {
  return {
    line1: signup?.trading_address_line1 || "",
    line2: signup?.trading_address_line2 || "",
    suburb: signup?.trading_address_suburb || "",
    city: signup?.trading_address_city || "",
    province: signup?.trading_address_province || "",
    postal_code: signup?.trading_address_postal_code || "",
    country: signup?.trading_address_country || CONFIG.countryCode,
    latitude: signup?.trading_address_latitude || null,
    longitude: signup?.trading_address_longitude || null,
    city_code: signup?.trading_address_city_code || "",
    area: signup?.trading_address_area || "",
    notes: signup?.trading_address_notes || "",
  } as Address;
};
