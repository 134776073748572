import Header from "@/shared/components/Header";
import React from "react";
import ContinueToAppButton from "./ContinueToAppButton";
import portalIcon from "./images/portal-icon.svg";

const InAppContent: React.FC<any> = () => {
  return (
    <div className="text-base flex flex-col justify-between h-screen">
      <div className="flex-1">
        <Header title="Get ready for business!" />
        <img
          src={portalIcon}
          alt="Yoco POS on phone and tablet"
          className="mx-auto"
        />
        <div className="py-6 leading-8">
          <h3 className="font-bold">Next Steps</h3>
          <ol className="list-decimal list-outside ml-4">
            <li>Complete your setup!</li>
            <li>Start accepting payments and grow your business</li>
          </ol>
        </div>
      </div>
      <div className="pb-4">
        <ContinueToAppButton />
      </div>
    </div>
  );
};

export default InAppContent;
