import { connect, FieldInputProps } from "formik";
import React from "react";

export interface Option {
  value: string;
  label: string;
  description?: string;
  image?: any;
  noFlex?: boolean;
  display?: string;
  displayProp?: string;
  padding?: boolean;
  margin?: boolean;
}

type Props = {
  field: FieldInputProps<any>;
  type: "checkbox" | "radio";
  option: Option;
  className?: string;
  [x: string]: any;
};

const FormOption: React.FC<Props> = ({
  field,
  type,
  option,
  className,
  ...props
}: Props) => {
  return (
    <div
      className={`border-2 border-white rounded-lg ${className} ${
        field.checked ? "option-selected" : ""
      }`}
    >
      <label
        className={`flex flex-col rounded-lg p-4 ${
          !field.checked ? "shadow-md" : ""
        }`}
      >
        <div
          className={`${
            option?.noFlex
              ? "flex flex-row sm:flex-col sm:items-start items-center sm:text-center font-bold font text-lg"
              : "flex flex-row"
          }`}
        >
          <input
            type={type}
            className={`form-${type} text-yoco-green mr-4 w-5 h-5 ${
              option?.image ? option?.display : option?.displayProp
            }`}
            {...field}
            {...props}
          />
          {option?.image && (
            <img
              src={option.image}
              alt=""
              width="60"
              height="60"
              className={`${option.padding ? "sm:m-auto sm:pt-2 mr-2" : ""}`}
            />
          )}
          <div
            className={`${
              option.margin
                ? "flex flex-col sm:w-4/5 sm:mx-auto"
                : "flex flex-col"
            }`}
          >
            <span className={`block ${option?.description ? "font-bold" : ""}`}>
              {option.label}
            </span>
            {!option.margin && option?.description && (
              <span className="text-sm mt-1">{option?.description}</span>
            )}
          </div>
        </div>
      </label>
    </div>
  );
};

export default connect(FormOption);
