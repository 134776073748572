import BaseSubmitButton from "@/shared/components/form/SubmitButton";
import { useAppConfigStore } from "@/signup/store";
import { UserJourney } from "@/signup/types";
import React from "react";

type Props = {
  children: React.ReactNode;
  block?: boolean;
  disabled?: boolean;
  submitting?: boolean;
  onClick?: () => void;
  testID?: string;
  [x: string]: any;
};

const useStore = () => ({
  userJourney: useAppConfigStore(state => state.userJourney),
});

const SubmitButton: React.FC<Props> = ({ children, ...props }: Props) => {
  const { userJourney } = useStore();

  const button = <BaseSubmitButton {...props}>{children}</BaseSubmitButton>;

  const isFixed = userJourney === UserJourney.BANANA;
  const submitButtonClasses = `md:hidden left-0 w-full p-4 bg-white z-10 ${
    isFixed ? "fixed bottom-0" : ""
  }`;

  return userJourney === UserJourney.CINNAMON ||
    userJourney === UserJourney.BANANA ? (
    <>
      <div className={submitButtonClasses}>{button}</div>
      <div className="pt-6 md:pt-0" />
      <div className="hidden md:block">{button}</div>
    </>
  ) : (
    button
  );
};

export default SubmitButton;
