import React from "react";

/**
 * Adds height buffer on mobile to compensate for sticky mobile cart summary
 */
const CartSummaryMobileOffset: React.FC<any> = () => (
  <div className="block md:hidden" style={{ height: 160 }} />
);

export default CartSummaryMobileOffset;
